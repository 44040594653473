import axios from 'axios';
import { parseValueIsProd } from '../../utils/formatter';
import { BASE_URL, URL_SANDBOX } from '../../constants/paths';

const { prod, staging, sandbox } = BASE_URL;

const getBaseUrl = (isProd, path) => {
  if (
    process.env.REACT_APP_ENVIRONMENT === 'staging' ||
    process.env.NODE_ENV === 'development'
  ) {
    return staging;
  }

  if (!isProd && URL_SANDBOX.some((element) => element === path)) {
    return sandbox;
  }

  return prod;
};

const API = axios.create();

export function setupApi(store) {
  API.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken, isProduction, enabledProduction, securityCode },
      } = store.getState();

      // eslint-disable-next-line no-param-reassign
      config.baseURL = getBaseUrl(
        parseValueIsProd(enabledProduction, isProduction),
        config.url,
      );

      if (authToken) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      if (securityCode) {
        // eslint-disable-next-line no-param-reassign
        config.headers.SecurityCode = `${securityCode}`;
      }

      return config;
    },
    (err) => Promise.reject(err),
  );
  API.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error) {
        if (
          error.response.status === 401 &&
          error.response.config.url !== '/public/user/login'
        ) {
          store.dispatch({ type: '[Logout] Action' });
        }
      }
      return Promise.reject(error);
    },
  );
}

export default API;
