import i18n from '../config/i18next/index';

export const LANG_STORAGE_KEY = 'i18nextLng';

export const getLang = () => {
  if (window instanceof Window) {
    const localStorageLang = window.localStorage.getItem('i18nextLng');

    if (!localStorageLang) {
      const userAgentLang = navigator.language.split('-')[0];

      return userAgentLang;
    }

    return localStorageLang;
  }

  return 'es';
};

export const setLang = async (newLang) => {
  window?.localStorage.setItem(LANG_STORAGE_KEY, newLang);
  await i18n.changeLanguage(newLang);
  await i18n.reloadResources();
};
