import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ArrowIcon = (props) => (
  <SvgIcon {...props} viewBox="2 -4 5 17" width="10" height="11">
    <path d="M5.5,0,11,10H0Z" transform="translate(10) rotate(90)" />
  </SvgIcon>
);

export default ArrowIcon;
