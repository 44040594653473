import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import esTranslations from '../translations/es.json';
import enTranslations from '../translations/en.json';

const options = {
  order: ['localStorage', 'navigator'],
  lookupQuerystring: 'lng',
};

// i18next config
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    interpolation: { escapeValue: false },
    resources: {
      es: {
        common: esTranslations,
      },
      en: {
        common: enTranslations,
      },
    },
  });

export default i18n;
