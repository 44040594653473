export default (theme) => ({
  card: {
    boxShadow: 'none',
    minHeight: 303,

    [theme.breakpoints.up('sm')]: {
      minHeight: 408,
    },

    '& > .MuiCardContent-root': {
      padding: theme.spacing(0.5),
    },
  },
  gutterBottom: {
    marginBottom: theme.spacing(1),
  },
  infoTypography: {
    color: '#161615',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'center',
  },
});
