import { fontFamily } from '../../../config/theme';

export const barChart = {
  chart: {
    type: 'bar',
    id: 'sales-statistics-bar-chart',
    fontFamily,
    height: 350,
    toolbar: {
      show: true,
    },
  },
  colors: ['#6E49AE', '#8B54F7', '#A67DF8'],
  plotOptions: {
    bar: {
      columnWidth: '65%',
    },
  },
  legend: {
    show: true,
    markers: {
      radius: 8,
    },
    itemMargin: {
      horizontal: 25,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  xaxis: {
    categories: ['Ene', 'Feb', 'Mar'],
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      show: false,
    },
  },
  grid: {
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  stroke: {
    show: true,
    width: 5,
    colors: ['transparent'],
  },
  fill: {
    opacity: 1,
  },
  dataLabels: {
    enabled: false,
  },
};

export const pieChart = {
  chart: {
    id: 'sales-statistics-pie-chart',
    type: 'donut',
    height: 160,
    fontFamily,
    toolbar: {
      show: true,
    },
  },
  legend: {
    show: false,
  },
  colors: ['#6E49AE', '#F2AB53', '#F0901E', '#A67DF8'],
  labels: ['Label 1', 'Label 2', 'Label 3', 'Label 4'],
  dataLabels: {
    enabled: false,
  },
};
