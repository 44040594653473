import { useCallback, useState } from 'react';

import {
  insertRowSelected,
  removeRowSelected,
} from '../../utils/dataTableSelectableRow';

export const useDataTableSelectableRows = (
  defaultSelectedRows = [],
  defaultAllSelected = false,
) => {
  const [selectedRows, setSelectedRows] = useState(defaultSelectedRows);
  const [allSelected, setAllSelected] = useState(defaultAllSelected);

  const handleSelectedRows = useCallback(
    ({ rows, selectedRows: selections, mergeSelections = false }) => {
      setSelectedRows((prev) =>
        mergeSelections ? [...prev, ...selections] : selections,
      );
      setAllSelected(rows.length === selections.length);
    },
    [],
  );

  const handleSelectedRow = useCallback(
    ({ rows, selectedRow: selection, isSelected }) => {
      const newSelection = isSelected
        ? removeRowSelected(selectedRows, selection)
        : insertRowSelected(selectedRows, selection);

      const newAllSelected = isSelected
        ? false
        : rows.length === selectedRows.length + 1;

      setSelectedRows(newSelection);
      setAllSelected(newAllSelected);
    },
    [selectedRows],
  );

  const handleSelectAllRows = useCallback(
    ({ rows }) => {
      setSelectedRows(allSelected ? [] : rows);
      setAllSelected((prev) => !prev);
    },
    [allSelected],
  );

  return {
    selectedRows,
    allSelected,
    handleSelectedRows,
    handleSelectedRow,
    handleSelectAllRows,
  };
};
