export default (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
  },
  contentTypography: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightNormal,
  },
  contentTypographyExtraLarge: {
    wordBreak: 'break-all',
  },
});
