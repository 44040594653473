export default () => ({
  root: {
    minHeight: 285,

    '& .MuiCardContent-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
  },
});
