import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useMountedState } from './useMountedState';
import { getCurrentCoordinatesByIp } from '../../services/publicServices';
import { actions as authActions } from '../modules/Auth/_redux/authRedux';

export const useGeolocation = (options) => {
  const isMounted = useMountedState();

  const dispatch = useDispatch();

  const getGeolocationByIp = useCallback(async () => {
    const dataByIp = await getCurrentCoordinatesByIp();

    return dataByIp.data.data;
  }, []);

  const onEvent = useCallback(
    (event) => {
      dispatch(
        authActions.setCurrentLocationCoordUser({
          loading: false,
          accuracy: event.coords.accuracy,
          altitude: event.coords.altitude,
          altitudeAccuracy: event.coords.altitudeAccuracy,
          heading: event.coords.heading,
          latitude: event.coords.latitude,
          longitude: event.coords.longitude,
          speed: event.coords.speed,
          timestamp: event.timestamp,
        }),
      );
    },
    [dispatch],
  );

  const onEventError = useCallback(async () => {
    const coordByIp = await getGeolocationByIp();
    dispatch(
      authActions.setCurrentLocationCoordUser({
        loading: false,
        ...coordByIp,
      }),
    );
  }, [getGeolocationByIp, dispatch]);

  useEffect(() => {
    if (isMounted()) {
      navigator.geolocation.getCurrentPosition(onEvent, onEventError, options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);
};
