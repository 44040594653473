export default (theme) => ({
  contianerButton: {
    marginTop: theme.spacing(2),
  },
  chip: {
    fontSize: theme.typography.pxToRem(11),
    marginTop: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(1),
  },
});
