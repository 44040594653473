import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import { useRLoginContext } from '../../contexts/RLoginContext';
import { parseWallet, toAbsoluteUrl } from '../../../utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const RLoginButton = ({ className, color, onClick, onConnect }) => {
  const { t } = useTranslation('common');
  const classes = useStyles({ color });

  const {
    walletConnected,
    handleConnectWallet,
    isLoggedIn,
    handleDisconnectWallet,
  } = useRLoginContext();

  const handleConnect = useCallback(() => {
    handleConnectWallet(onClick, onConnect);
  }, [handleConnectWallet, onClick, onConnect]);

  const isPurple = useMemo(() => color === 'purple', [color]);

  if (!walletConnected) {
    return (
      <button
        type="button"
        className={classnames(className, classes.rloginContainer)}
        disabled={isLoggedIn}
        onClick={handleConnect}
      >
        <img
          src={toAbsoluteUrl(
            `/assets/icons/${isPurple ? 'rsk_logo-purple' : 'rsk_logo'}.svg`,
          )}
          alt="Rsk Logo"
          className={classes.rloginContentRskIcon}
        />

        <span className={classes.rloginContentText}>
          {t('header.connectWallet')}
        </span>
      </button>
    );
  }

  return (
    <button
      type="button"
      className={classnames(className, classes.rloginContainer)}
      onClick={handleDisconnectWallet}
    >
      <img
        src={toAbsoluteUrl(
          `/assets/icons/${isPurple ? 'rsk_logo-purple' : 'rsk_logo'}.svg`,
        )}
        alt="Rsk Logo"
        className={classes.rloginContentRskIcon}
      />

      <span className={classes.rloginContentText}>
        {parseWallet(walletConnected)}
      </span>

      <img
        src={toAbsoluteUrl(
          `/assets/icons/${isPurple ? 'logout-purple' : 'logout'}.svg`,
        )}
        alt="Logout"
        className={classes.rloginContentLogoutIcon}
      />
    </button>
  );
};

RLoginButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  onConnect: PropTypes.func,
  color: PropTypes.oneOf(['purple', 'default']),
};

RLoginButton.defaultProps = {
  className: null,
  onClick: null,
  onConnect: null,
  color: 'purple',
};

export default RLoginButton;
