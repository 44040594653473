import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';

import PDFPaginator from './paginator';
import styles from './styles';

const useStyles = makeStyles(styles);

// eslint-disable-next-line max-len
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PDFviewver = ({ url }) => {
  const classes = useStyles();
  const [numPagesDat, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const getNumber = (page) => setPageNumber(page);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={8} lg={8}>
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            className={classes.page}
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
          />
        </Document>
        <PDFPaginator
          pageNumber={pageNumber}
          totalPages={numPagesDat}
          getNumber={getNumber}
        />
      </Grid>
    </Grid>
  );
};

PDFviewver.propTypes = {
  url: PropTypes.string.isRequired,
};

export default PDFviewver;
