import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Button, CircularProgress } from '@material-ui/core';
import cn from 'classnames';

import styles from './styles';

const useStyles = makeStyles(styles);

const ButtonWithCirculaProgress = ({
  handleButtonClick,
  loading,
  disabled,
  label,
  type,
  color,
  variant,
  fullWidth,
  className,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box
      className={cn(classes.wrapper, className, {
        [classes.fullWidth]: fullWidth,
      })}
    >
      <Button
        type={type}
        color={color}
        variant={variant}
        disabled={loading || disabled}
        onClick={handleButtonClick}
        fullWidth={fullWidth}
        {...rest}
      >
        {label}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          className={classes.buttonProgress}
          color={color}
        />
      )}
    </Box>
  );
};

ButtonWithCirculaProgress.propTypes = {
  handleButtonClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};

ButtonWithCirculaProgress.defaultProps = {
  handleButtonClick: () => {},
  disabled: false,
  type: 'button',
  color: 'primary',
  variant: 'contained',
  fullWidth: false,
  className: null,
};

export default ButtonWithCirculaProgress;
