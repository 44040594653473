import { guttersApp, appHeightHeader } from '../../../utils/stylesUtils';

export default (theme) => ({
  root: {
    background: theme.palette.common.white,
    boxShadow: '0px 3px 6px #E5E5E5',
  },
  toolbar: {
    alignItems: 'center',
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: theme.spacing(1),
    gridTemplateColumns: 'max-content repeat(2, auto)',
    margin: '0 auto',
    minHeight: 'auto !important',
    height: appHeightHeader.desktop,
    width: '100%',

    ...guttersApp(theme.spacing, theme.breakpoints),

    [theme.breakpoints.down(1025)]: {
      gridTemplateColumns: 'max-content auto max-content',
      height: appHeightHeader.mobile,
      paddingLeft: theme.spacing(8.75),
      paddingRight: theme.spacing(8.75),
    },
  },
  linkLogo: {
    alignItems: 'center',
    display: 'flex',
  },
  logo: {
    height: 28,
    width: 25,
  },
  contentInfoContianer: {
    alignItems: 'center',
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: theme.spacing(1),
    gridTemplateColumns: 'repeat(2, max-content)',

    [theme.breakpoints.down(1025)]: {
      gridGap: 0,
      justifyContent: 'end',
    },
  },
  pagesContainer: {
    alignItems: 'center',
    display: 'grid',
    gridAutoFlow: 'column',
  },
  pageIcon: {
    height: 33,
    width: 33,
  },
  selectorsContainer: {
    alignItems: 'center',
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: theme.spacing(1.5),

    [theme.breakpoints.down(1025)]: {
      display: 'none',
    },
  },
  contentActions: {
    alignItems: 'center',
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: theme.spacing(1),
    justifyContent: 'end',
  },
  actionsAuthContainer: {
    alignItems: 'center',
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: theme.spacing(1),

    [theme.breakpoints.down(1025)]: {
      display: 'none',
    },
  },
  authButton: {
    textTransform: 'none',
    minWidth: 170,
    padding: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    width: '100%',
  },
  logoutIcon: {
    height: 19,
    marginRight: 6,
    width: 19,
  },
  dropdownMenuContainer: {
    display: 'none',

    [theme.breakpoints.down(1025)]: {
      alignItems: 'center',
      display: 'grid',
      gridAutoFlow: 'column',
    },
  },
});
