export default (theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 0),
  },
  title: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  img: {
    height: 103,
    marginBottom: theme.spacing(2),
    width: 103,
  },
  listContainer: {
    width: '100%',
  },
  itemContainer: {
    borderTop: '1px solid #707070',
    padding: theme.spacing(2),

    '&:first-child': {
      borderTop: 0,
    },
  },
  itemContainerInfo: {
    display: 'flex',
  },
  itemContainerInfoTypography: {
    fontSize: theme.typography.pxToRem(14),
    margin: theme.spacing(1, 0),

    '&:first-child': {
      flex: 1,
      marginRight: theme.spacing(1),
    },

    '&:last-child': {
      flex: 1,
      textAlign: 'center',
    },
  },
  itemContainerInfoTypographyRight: {
    '&:last-child': {
      textAlign: 'right',
    },
  },
  itemCell: {
    display: 'flex',
    margin: theme.spacing(0.5, 0),
  },
  itemCellRight: {
    justifyContent: 'flex-end',
  },
  itemCellCenter: {
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 3),
    width: '100%',
  },
  button: {
    width: 166,
  },
  selectedCheckboxContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  selectedAllCheckboxContainer: {
    display: 'flex',
    marginRight: theme.spacing(2),
  },
  selectedAllCheckboxTypography: {
    fontSize: theme.typography.pxToRem(14),
  },
  containerLoading: {
    margin: 'auto',
  },
});
