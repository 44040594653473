import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import DialogDetails from '../DialogDetails';
import DialogDetailsItem from '../DialogDetailsItem';

import { getStatePayment } from '../../../utils/merchants';

const DialogDetailsPayment = ({ open, onClose, data }) => {
  const { t } = useTranslation('common');
  const {
    id,
    description,
    currency,
    total_amount: totalAmount,
    state,
    metadata,
    gateway,
    created_at: createdAt,
    payed_at: payedAt,
  } = data;

  return (
    <DialogDetails
      id="dialog-details-payment"
      open={open}
      onClose={onClose}
      isDownloadable={false}
      maxWidth="xs"
    >
      {open && !isEmpty(data) && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <DialogDetailsItem title={t(`payments.id`)} content={id} />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t(`payments.referencialCode`)}
              content={description}
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t(`payments.currency`)}
              content={currency}
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t(`payments.amount`)}
              content={totalAmount}
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t(`payments.state`)}
              content={getStatePayment(state)}
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t(`payments.user`)}
              content={metadata.user_data}
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t(`payments.quotation`)}
              content={metadata.quotation}
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t(`payments.gateway`)}
              content={gateway}
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t(`payments.requestAt`)}
              content={moment(createdAt).format('DD/MM/YYYY HH:mm')}
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t(`payments.payedAt`)}
              content={
                payedAt ? moment(payedAt).format('DD/MM/YYYY HH:mm') : ''
              }
            />
          </Grid>
        </Grid>
      )}
    </DialogDetails>
  );
};

DialogDetailsPayment.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    currency: PropTypes.string,
    total_amount: PropTypes.number,
    state: PropTypes.string,
    metadata: PropTypes.shape({
      user_data: PropTypes.string,
      quotation: PropTypes.number,
    }),
    gateway: PropTypes.string,
    created_at: PropTypes.string,
    payed_at: PropTypes.string,
  }),
};

DialogDetailsPayment.defaultProps = {
  data: null,
};

export default DialogDetailsPayment;
