import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Input from '../Input';

import { getHelperText, hasError } from '../../../utils';
import { typesInputs } from './utils';

const CustomPlaceHolder = ({ startDate, endDate, nameDateRange }) => {
  const { t } = useTranslation('common');
  const { errors, touched, handleBlur } = useFormikContext();

  const getKeyField = useCallback(
    (inputName) => `${nameDateRange}.${inputName}`,
    [nameDateRange],
  );

  const startDateKey = useMemo(() => getKeyField(typesInputs.startDate), [
    getKeyField,
  ]);
  const endDateKey = useMemo(() => getKeyField(typesInputs.endDate), [
    getKeyField,
  ]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Input
          name={startDateKey}
          value={startDate ?? ''}
          onChange={() => {}}
          onBlur={handleBlur}
          label={t('tableColumns.from')}
          error={hasError(touched, errors, startDateKey)}
          helperText={getHelperText(touched, errors, startDateKey)}
          autoComplete="off"
        />
      </Grid>

      <Grid item xs={6}>
        <Input
          name={endDateKey}
          value={endDate ?? ''}
          onChange={() => {}}
          onBlur={handleBlur}
          label={t('tableColumns.to')}
          error={hasError(touched, errors, endDateKey)}
          helperText={getHelperText(touched, errors, endDateKey)}
          autoComplete="off"
        />
      </Grid>
    </Grid>
  );
};

CustomPlaceHolder.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  nameDateRange: PropTypes.string.isRequired,
};

CustomPlaceHolder.defaultProps = {
  startDate: null,
  endDate: null,
};

export default CustomPlaceHolder;
