export default (theme) => ({
  root: {
    alignItems: 'center',
    borderRadius: 13,
    boxShadow: '0px 5px 6px #00000029',
    display: 'flex',
    maxWidth: '100%',
    padding: '2px 4px',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      maxWidth: 376,
    },
  },
  input: {
    flex: 1,
    marginLeft: theme.spacing(1),
  },
  notVisible: {},
  iconButton: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(32),
    padding: 10,
    visibility: 'visible',

    '&$notVisible': {
      visibility: 'hidden',
    },
  },
});
