import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import * as Yup from 'yup';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { RLoginProvider } from './app/contexts/RLoginContext';
import {
  // mockAxios,
  setupApi,
  store,
  persistor,
  MaterialThemeProvider,
  CustomSnackbarProvider,
} from './config';
import App from './app/App';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import './index.css';

// mockAxios();
setupApi(store);

Yup.addMethod(Yup.date, 'formatDate', function format(formats, parseStrict) {
  return this.transform(function tranform(value, originalValue) {
    if (this.isType(value)) return value;

    // eslint-disable-next-line no-param-reassign
    value = moment(originalValue, formats, parseStrict);

    return value.isValid() ? value.toDate() : new Date('');
  });
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <RLoginProvider>
        <PersistGate persistor={persistor} loading={null}>
          <MaterialThemeProvider>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <CustomSnackbarProvider>
                <I18nextProvider i18n={i18next}>
                  <App />
                </I18nextProvider>
              </CustomSnackbarProvider>
            </MuiPickersUtilsProvider>
          </MaterialThemeProvider>
        </PersistGate>
      </RLoginProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
