import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause11 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        11. Política de materias reservadas en envíos
      </Typography>
      <Typography className={classes.text}>
        Lista de ejemplos no limitativa: <br />
        - Alcohol y Tabaco: Las entregas de alcohol y tabaco pueden estar
        limitadas o restringidas en determinados países y/o ciudades donde opera
        la Plataforma. <br />
        - Animales y Especies Reguladas: Las partes de animales, o fluidos;
        semillas prohibidas, plantas nocivas; plantas u otros organismos
        regulados (incluyendo sus derivados) en peligro de extinción o cuyo
        comercio es en cualquier caso según lo que prevea la ley. <br />
        - Pornografía infantil: Material pornográfico involucrando a menores o
        contenido que puede ser percibido como pedofilia erótica. <br />
        - Copyright sobre Software y Media: Falsificaciones. Copias no
        autorizadas de libros, música, películas y otros materiales protegidos o
        por licencia incluyendo copias sin atribución apropiada; y copias no
        autorizadas de software, videojuegos y otros materiales protegidos o con
        licencia, incluyendo OEM u otros productos. <br />
        - Productos Réplicas o imitaciones de diseñadores u otros bienes.
        Objetos de celebridades que normalmente requerirían autenticación;
        autógrafos falsos; divisas; sellos; tiquets; u otros bienes no
        autorizados. <br />
        - Dispositivos o herramientas para Módems, chips u otros dispositivos
        desbloquear medidas de seguridad para desmantelar medidas técnicas de
        protección así como en dispositivos digitales, incluyendo para
        desbloquear Smart Phones. <br />
        - Drogas Sustancias controladas, narcóticos, drogas ilegales, y drogas
        accesorias, incluyendo psicoactivas y drogas vegetales como setas
        alucinógenas así como material promoviendo su uso; o sustancias legales
        como plantas y hierbas, en una forma que sugiera su ingesta, inhalación,
        extracción, u otro uso que pueda provocar el mismo uso que un
        componente, droga o sustancia ilegal o que produzca beneficios no
        probados para la salud. <br />
        - Juego y Apuestas Billetes de lotería, apuestas,
        membresías/inscripciones en sitios online de apuestas, y contenido
        relativo. <br />
        - Promoción de casinos físicos. <br />
        - Las entregas de productos de Farmacia pueden estar limitadas o
        restringidas en determinados países y/o ciudades donde opera la
        Plataforma. <br />
        - No está permitido el encargo/envío de medicamentos sujetos a
        prescripción médica. <br />
        - Los medicamentos OTC/de venta libre, productos sanitarios
        complementarios, así como cualquier otro producto de higiene, nutrición
        o análogo, de venta en farmacia y de uso humano se encuentran sujetos al
        mandato que el Usuario confiera al Kriptonian y a lo que estime
        conveniente el farmacéutico. <br />
        - Materiales para hacking y cracking Manuales, guías, información o
        equipamiento que infrinja la ley dañando o facilitando fraudulentamente
        el acceso a software, servidores, sitios web u otra propiedad protegida.
        <br />
        - Partes humanas Órganos u otras partes del cuerpo; fluidos corporales;
        células madre; embriones. Bienes robados o ilegales. <br />
        - Materiales, productos o información que promueva bienes ilegales o
        facilite actos ilegales; bienes sobre los que no se tiene la propiedad o
        no se tiene el derecho a la venta; bienes producidos con violación de
        los derechos de terceros; bienes en violación de restricciones sobre
        importación, exportación o etiquetado; vehículos a motor sujetos a
        restricciones de transferencia; Usted (Usuario de KriptonMarket) es
        única y completamente responsable de verificar que todos los objetos son
        auténticos y legales. <br />
        - Equipamiento de telecomunicaciones ilegal: Dispositivos que pretenden
        obtener señal satélite gratis, productos ilegales para modificar
        teléfonos móviles y otros equipamientos. <br />
        - Bienes ofensivos: Bienes, literatura, productos, u otro material que:
        Difame alguna persona o grupo de personas basándose en su raza, etnia,
        origen nacional, religión, sexo, u otro factor. Difame a alguna persona
        o grupo de personas protegidas según la ley aplicable en supuestos de
        difamaciones (como por ejemplo la familia real en algunas
        jurisdicciones). Enaltezca, o incite a cometer, actos violentos.
        Promover intolerancia u odio. Promover o apoyar membresía en grupos
        terroristas u otras organizaciones prohibidas por ley. Contravenir la
        moralidad pública. <br />
        - Bienes ofensivos relacionados con un crimen: Fotos u objetos de la
        escena de un crimen, como pertenencias personales, asociadas con
        criminales o con actos criminales. <br />
        - Materiales preciosos: Ventas a granel de piedras, materiales raros,
        escasos o valiosos. <br />
        - Artefactos culturalmente protegidos Material cubierto por la
        Convención de la UNESCO de 1970 sobre Medidas que Deben Adoptarse para
        Prohibir e Impedir la Importación, la Exportación y la Transferencia de
        Propiedad Ilícitas de Bienes Culturales u otros bienes restringidos por
        ley a su venta, exportación o transferencia. <br />
        - Dispositivos pirotécnicos y sustancias: Artículos pirotécnicos y
        bienes peligrosas que requieran permiso relacionados en mercados en que
        su especial entrega está regulada así como sustancias como la gasolina o
        el propano. <br />
        - Dispositivos de tráfico Radares, cubiertas de matrícula, cambiadores
        ilegales de tráfico y productos relacionados. <br />
        - Armas: Armas de fuego, munición, y otros objetos incluyendo pero no
        limitando a armas de fuego, cuchillos camuflables, o indetectables,
        armas de artes marciales, silenciadores, munición o revistas de
        munición. <br />
        - Divisas y Dinero Cambio de divisas o divisas aseguradas con metales
        preciosos, así como billetes, monedas, o cualquier otro título de valor
        que exceda lo convenido en la Cláusula 4. <br />
      </Typography>
      <Typography className={classes.text}>
        Escuela Primaria y Secundaria: Se reserva el derecho a rechazar pedidos
        de todos aquellos usuarios menores de edad. Adicionalmente, se reserva
        el derecho a rechazar pedidos en cualquier localización a los
        alrededores de escuelas de primaria o secundaria, así como a solicitar
        acreditación suficiente.
      </Typography>
      <Typography className={classes.text}>
        Mal uso de la plataforma/Abuso: No toleraremos el uso de lenguaje
        abusivo así como de actitudes abusivas hacia nuestra compañía o hacia
        los profesionales independientes.
      </Typography>
    </Box>
  );
};

export default Clause11;
