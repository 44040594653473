import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Box,
  CircularProgress as CircularProgressMui,
} from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const CircularProgress = ({ disableShrink, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} {...rest}>
      <CircularProgressMui size={75} disableShrink={disableShrink} />
    </Box>
  );
};

CircularProgress.propTypes = {
  disableShrink: PropTypes.bool,
};

CircularProgress.defaultProps = {
  disableShrink: false,
};

export default CircularProgress;
