import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Typography, makeStyles, ClickAwayListener } from '@material-ui/core';

import { SUPPORTED_LANGUAGES } from '../../../constants/languaje';
import { toAbsoluteUrl } from '../../../utils';
import { setLang, getLang } from '../../../utils/languaje';
import styles from './styles';

const useStyles = makeStyles(styles);

const LanguageMenu = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const [openMenu, setOpenMenu] = useState(false);
  const handleToggleMenu = useCallback(() => setOpenMenu((prev) => !prev), []);

  const [currentLanguage, setCurrentLanguage] = useState(getLang());

  const handleChangeLanguage = useCallback(
    async (newLang) => {
      await setLang(newLang);
      setCurrentLanguage(newLang);
      handleToggleMenu();
    },
    [handleToggleMenu],
  );

  return (
    <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
      <div className={classnames(classes.menuContainer)}>
        <button
          type="button"
          className={classes.btnMenu}
          onClick={handleToggleMenu}
        >
          <img
            src={toAbsoluteUrl('/assets/icons/languaje-purple.svg')}
            alt="Languaje"
            className={classes.iconContainer}
          />

          <span className={classes.btnText}>{t('general.languaje')}</span>

          <span className={classes.btnArrow}>&#9656;</span>
        </button>

        {openMenu && (
          <nav className={classnames(classes.languageMenu)}>
            <div className={classes.languageMenuItems}>
              {SUPPORTED_LANGUAGES.map(({ id: language, urlFlagImg }) => (
                <div
                  className={classes.languageMenuElementContent}
                  key={`language-menu-element-${language}`}
                >
                  <button
                    type="button"
                    className={classnames(classes.languageMenuElementBtn, {
                      [classes.languageMenuElementBtnSelected]:
                        currentLanguage === language,
                    })}
                    onClick={() => handleChangeLanguage(language)}
                  >
                    <img
                      src={urlFlagImg}
                      alt={t(`general.${language}`)}
                      className={classes.languageMenuElementIcon}
                    />

                    <Typography
                      className={classes.languageMenuElementText}
                      color="inherit"
                    >
                      {t(`general.${language}`)}
                    </Typography>
                  </button>
                </div>
              ))}
            </div>
          </nav>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default LanguageMenu;
