import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Select from '../Select';

import { useRequest } from '../../hooks';
import { getListCurrencies } from '../../../services/publicServices';

const CryptoCurrencies = ({
  name,
  value,
  disabled,
  onChange,
  onBlur,
  error,
  helperText,
  fiat,
  fullWidth,
  ...rest
}) => {
  const { t } = useTranslation('common');
  const [currencies, loading] = useRequest(
    {
      initialState: [],
      request: getListCurrencies,
      transformResponse: (response) => {
        const filterCurrencies = response.data
          .filter((item) => item.fiat === fiat)
          .map((currency) => ({
            label: currency.name,
            value: currency.id,
          }));

        return filterCurrencies;
      },
    },
    [],
  );

  return (
    <Select
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      loading={loading}
      {...rest}
      label={t(`payments.currency`)}
      data={currencies}
      fullWidth={fullWidth}
    />
  );
};

CryptoCurrencies.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  fiat: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

CryptoCurrencies.defaultProps = {
  disabled: false,
  onBlur: () => {},
  error: false,
  helperText: null,
  fiat: false,
  fullWidth: true,
};

export default CryptoCurrencies;
