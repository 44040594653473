import { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useMountedState } from './useMountedState';

export const useCopyToClipboard = () => {
  const isMounted = useMountedState();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const [state, setState] = useState({
    value: undefined,
    error: undefined,
  });

  const copyToClipboard = useCallback(
    (value) => {
      if (!isMounted()) {
        return;
      }
      let normalizedValue;

      try {
        // only strings and numbers casted to strings can be copied to clipboard
        if (typeof value !== 'string' && typeof value !== 'number') {
          const error = new Error(
            `Cannot copy typeof ${typeof value} to clipboard, must be a string`,
          );

          if (process.env.NODE_ENV === 'development') console.error(error);

          setState({ value, error });

          return;
        }

        if (value === '') {
          const error = new Error(`Cannot copy empty string to clipboard.`);

          if (process.env.NODE_ENV === 'development') console.error(error);

          setState({ value, error });
          return;
        }

        normalizedValue = value.toString();

        navigator.clipboard.writeText(normalizedValue);

        setTimeout(() => {
          enqueueSnackbar(t('general.textCopySuccess'), {
            variant: 'success',
          });
        }, 500);

        setState({ value: normalizedValue, error: undefined });
      } catch (error) {
        setState({
          value: normalizedValue,
          error,
        });

        setTimeout(() => {
          enqueueSnackbar(t('general.textCopyError'), {
            variant: 'error',
          });
        }, 500);
      }
    },
    [enqueueSnackbar, isMounted, setState, t],
  );

  return [state, copyToClipboard];
};
