// eslint-disable-next-line no-unused-vars
export default (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
  qrTextContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    maxWidth: '100%',
  },
  qrTextTypography: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});
