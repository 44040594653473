import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { find, includes } from 'lodash';

import { MENU_OPTIONS } from '../../../constants/menuOptions';

import styles from './styles';

const useStyles = makeStyles(styles);

const CurrentLocationInfo = ({ noTitle, noImage, customTitle }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const currentLocationObjectMenu = useMemo(
    () => find(MENU_OPTIONS, (item) => includes(pathname, item.path)) || {},
    [pathname],
  );

  return (
    <Box className={classes.root}>
      {!noTitle && (
        <Typography component="h1" className={classes.title}>
          {customTitle || currentLocationObjectMenu.label}
        </Typography>
      )}
      {!noImage && (
        <Box
          component="img"
          src={currentLocationObjectMenu.image}
          alt={currentLocationObjectMenu.label}
          className={classes.img}
        />
      )}
    </Box>
  );
};

CurrentLocationInfo.propTypes = {
  noTitle: PropTypes.bool,
  noImage: PropTypes.bool,
  customTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

CurrentLocationInfo.defaultProps = {
  noTitle: false,
  noImage: false,
  customTitle: null,
};

export default CurrentLocationInfo;
