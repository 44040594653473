import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import cn from 'classnames';

import styles from './styles';

const useStyles = makeStyles(styles);

const DialogDetailsItem = ({
  title,
  content,
  wordBreakContent,
  disableTypographyContent,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      {disableTypographyContent ? (
        content
      ) : (
        <Typography
          className={cn(classes.contentTypography, {
            [classes.contentTypographyExtraLarge]: wordBreakContent,
          })}
        >
          {content}
        </Typography>
      )}
    </Box>
  );
};

DialogDetailsItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  wordBreakContent: PropTypes.bool,
  disableTypographyContent: PropTypes.bool,
};

DialogDetailsItem.defaultProps = {
  content: null,
  wordBreakContent: false,
  disableTypographyContent: false,
};

export default DialogDetailsItem;
