import { has, get } from 'lodash';

export const hasError = (touched, errors, pathField) =>
  has(errors, pathField) && has(touched, pathField);

export const getHelperText = (
  touched,
  errors,
  pathField,
  defaultHelper = ' ',
) =>
  hasError(errors, touched, pathField) ? get(errors, pathField) : defaultHelper;

export const getTypeShowPassword = (show) => (show ? 'text' : 'password');
