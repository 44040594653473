import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const Input = ({
  type,
  name,
  value,
  disabled,
  onChange,
  onBlur,
  label,
  placeholder,
  error,
  helperText,
  fullWidth,
  ...rest
}) => (
  <TextField
    type={type}
    name={name}
    value={value}
    disabled={disabled}
    onChange={onChange}
    onBlur={onBlur}
    label={label}
    placeholder={placeholder}
    error={error}
    helperText={helperText}
    variant="outlined"
    fullWidth={fullWidth}
    {...rest}
  />
);

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  disabled: false,
  onBlur: () => {},
  label: null,
  placeholder: null,
  error: false,
  helperText: null,
  fullWidth: true,
};

export default Input;
