export default (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  text: {
    textAlign: 'center',
    fontSize: 14,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  button: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  form: {
    padding: 24,
    maxWidth: 572,
    width: '100%',
  },
  iconContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(3),
  },
});
