export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1.5),

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  rowNameTypography: {
    flex: 1,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.regular,
  },
  rowValueTypography: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.regular,
    color: '#9F9F9F',
    wordBreak: 'break-all',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '30%',
      width: '100%',
    },
  },
});
