import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const ContactItem = ({ Icon, title, description }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Icon className={classes.icon} />
      <Box className={classes.infoContainer}>
        <Typography className={classes.infoText}>{title}</Typography>
        <Typography className={classes.infoText}>{description}</Typography>
      </Box>
    </Box>
  );
};

ContactItem.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ContactItem;
