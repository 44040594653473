export const STATES_DESCRIPTIONS = {
  pending: 'Pendiente',
  in_process: 'En Proceso',
  paid: 'Pagado',
  confirmed: 'Confirmado',
  rejected: 'Rechazado',
  expired: 'Vencido',
  cancel: 'Cancelado',
  paid_partial: 'Pagado Parcialmente',
  paid_partial_and_expired: 'Expirado (Pagado Parcialmente)',
};

/**
 * @typedef StatesFilter
 * @type {'pending' | 'in_process' | 'paid' | 'confirmed' | 'rejected' | 'expired' | 'cancel' | 'paid_partial'}
 */

/**
 * Obtiene la descripcion del estado del pago
 * @param {StatesFilter} state - Estado
 * @returns {String} Retorna la descripcion del estado
 */
export const getStatePayment = (state) => {
  const defaultDescription = '';

  return STATES_DESCRIPTIONS[state] || defaultDescription;
};

export const parseResponsePayments = (response) => {
  const data = response.data.map((item) => ({
    ...item,
    metadata: JSON.parse(item.metadata),
  }));

  return { ...response, data };
};
