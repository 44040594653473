/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';

import defaultStylesDataTable from './defaultStyles';

const ConfigSelection = ({ data, columns }) => (
  <DataTable
    persistTableHead
    noHeader
    columns={columns}
    data={data}
    pagination={false}
    customStyles={defaultStylesDataTable}
  />
);

ConfigSelection.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
};

ConfigSelection.defaultProps = {
  data: [],
  columns: [],
};

export default ConfigSelection;
