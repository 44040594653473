export default (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  page: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',

    '& > canvas': {
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, .2)',
      height: '1250px',
      width: '920px',
    },

    [theme.breakpoints.down('sm')]: {
      '& > canvas': {
        height: '600px !important',
        width: '360px !important',
      },

      '& > div[class="react-pdf__Page__textContent"]': {
        left: '0 !important',
        width: '100% !important',
      },
    },
  },
  paginateContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
    width: '100%',
  },
  paginateArrows: {
    alignItems: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    padding: '12px',
    zIndex: 1,

    '&:hover': {
      backgroundColor: '#DAD8D7',
    },
  },
  disabled: {
    backgroundColor: '#DAD8D7',
    pointerEvents: 'none',
  },
  paginateText: {
    fontSize: '14px',
    padding: '12px',
  },
});
