import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause19 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        19. Cláusula de "no garantía" en los encargos ni en los pagos
      </Typography>
      <Typography className={classes.text}>
        KriptonMarket no ofrece ninguna garantía con respecto a la autenticidad,
        la exactitud, la novedad, la fiabilidad, legalidad o no violación de
        derechos de terceros por parte de los Kriptonians. En este sentido, los
        Usuarios manifiestan que comprenden que KriptonMarket es una empresa
        independiente que pone en contacto Clientes y Kriptonians, y que no
        asume responsabilidad alguna derivada de la información proporcionada
        por los Kriptonians ni por los daños o pérdidas que eventualmente
        pudieran sufrir por un incumplimiento de las presentes Condiciones
        Generales de Uso. Por ello KriptonMarket no será nunca responsable ni de
        la disponibilidad de los Kriptonians ni del cumplimiento adecuado y
        satisfactorio de los encargos por parte de estos. <br />
        KriptonMarket realiza la confirmación y evaluación pertinente de los
        Kriptonians. KriptonMarket no se hace responsable de los actos y
        actitudes de los Kriptonians, tan solo de que estén capacitados para
        realizar la transacciones en que participan. Sin perjuicio de lo
        anterior, KriptonMarket puede eventualmente realizar comprobaciones
        adicionales y poner en marcha procedimientos destinados a ayudar a
        verificar o comprobar la mala fe de algún Kriptonianian. Por ello
        KriptonMarket les recomienda a los Clientes que empleen siempre el
        sentido común y toda su atención a la hora de delegar un encargo a un
        determinado Kriptonian.
        <br />
        Al ser miembros de la Plataforma, los Usuarios y Clientes aceptan que
        cualquier responsabilidad legal que pretendan ejercitar como
        consecuencia de acciones u omisiones de otros Usuarios de la Plataforma
        o terceros se limitará a dichos Usuarios, Clientes o terceros, no
        procediendo acción de responsabilidad alguna contra KriptonMarket.
      </Typography>
    </Box>
  );
};

export default Clause19;
