import { isEqual } from 'lodash';

export const STATUS = {
  REQUEST: 'request',
  WAIT: 'wait',
  RECEIVED: 'received',
  COMPLETE: 'complete',
  CANCEL: 'cancel',
  PENDING_BY_VALIDATE: 'pending_by_validate',
};

export const TYPES = {
  CASH_IN: 'cash-in',
  CASH_OUT: 'cash-out',
};

/**
 * @typedef StatusFilter
 * @type {'request' | 'wait' | 'received' | 'complete' | 'cancel' | 'pending_by_validate'}
 */

/**
 * Obtiene la descripcion del estado segun la operacion
 * @param {StatusFilter} status - Estado
 * @param {'cash-in' | 'cash-out'} typeOperation - Tipo de operacion
 * @returns {String} Retorna la descripcion del estado
 */
export const getStatusDescription = (status, typeOperation) => {
  let description = '';

  if (isEqual(status, STATUS.REQUEST)) description = 'Solicitada';
  if (isEqual(status, STATUS.WAIT) && isEqual(typeOperation, TYPES.CASH_IN))
    description = 'Espera de pago';
  if (isEqual(status, STATUS.WAIT) && isEqual(typeOperation, TYPES.CASH_OUT))
    description = 'Espera TX';
  if (isEqual(status, STATUS.RECEIVED) && isEqual(typeOperation, TYPES.CASH_IN))
    description = 'Pago recibido';
  if (
    isEqual(status, STATUS.RECEIVED) &&
    isEqual(typeOperation, TYPES.CASH_OUT)
  )
    description = 'TX recibido';
  if (isEqual(status, STATUS.COMPLETE)) description = 'Completada';
  if (isEqual(status, STATUS.CANCEL)) description = 'Cancelada';
  if (isEqual(status, STATUS.PENDING_BY_VALIDATE))
    description = 'Pendiente por validar';

  return description;
};
