export default (theme) => ({
  root: {
    display: 'flex',
    minHeight: 17 * 2,
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  gutterTop: {
    marginTop: theme.spacing(2),
  },
  containerTitle: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
  },
  typography: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  },
  divider: {
    background: theme.palette.background.gradient.mix2,
    flex: 1,
    height: 6,
    marginRight: 0,
  },
  dividerGutterLeft: {
    marginLeft: 16,
  },
  dividerGutterRight: {
    marginRight: 16,
  },
});
