import { appHeightHeader } from '../../../utils/stylesUtils';

export default (theme) => ({
  menuContainer: {
    position: 'relative',
    display: 'none',

    [theme.breakpoints.down(1025)]: {
      display: 'block',
    },

    [theme.breakpoints.down(601)]: {
      position: 'initial',
    },
  },
  menuButton: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 19,
    minWidth: 19,
    overflow: 'hidden',
    padding: 0,
    position: 'relative',
    textAlign: 'left',
    zIndex: 1,

    '&:focus, &:focus-visible': {
      outline: 'none',
    },
  },
  openMenu: {
    '&$menuButtonLines': {
      backgroundColor: 'transparent',
      transform: 'translateX(2rem)',

      '&::before': {
        backgroundColor: theme.palette.secondary.main,
        transform: 'rotate(45deg) translate(-1.5rem, 1.5rem)',
      },

      '&::after': {
        backgroundColor: theme.palette.secondary.main,
        transform: 'rotate(-45deg) translate(-1.5rem, -1.5rem)',
      },
    },
  },
  menuButtonLines: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 7,
    height: 4,
    transition: 'all .5s ease-in-out',
    width: 19,

    '&::before': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 7,
      height: 4,
      transition: 'all .5s ease-in-out',
      width: 19,
      content: "''",
      position: 'absolute',
      transform: 'translateY(-.4rem)',
    },

    '&::after': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 7,
      height: 4,
      transition: 'all .5s ease-in-out',
      width: 19,
      content: "''",
      position: 'absolute',
      transform: 'translateY(.4rem)',
    },
  },
  serviceMenu: {
    alignItems: 'center',
    // eslint-disable-next-line max-len
    background: `transparent linear-gradient(180deg, ${theme.palette.primary.main} 0%, #A67DF8 100%)`,
    boxShadow: '0px -3px 6px #00000029',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'space-between',
    opacity: 1,
    padding: '40px 30px',
    position: 'absolute',
    right: -10,
    top: 39,
    width: 375,
    zIndex: 97,

    '&::after': {
      borderBottom: `8px solid ${theme.palette.primary.main}`,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      content: "''",
      height: 0,
      margin: '0 0 0 -3px',
      position: 'absolute',
      right: 10,
      top: -8,
      width: 0,
      zIndex: 100,
    },

    [theme.breakpoints.down(1025)]: {
      height: 600,
      top: 34,
      width: 480,
    },

    [theme.breakpoints.down(601)]: {
      bottom: 0,
      height: `calc(100% - ${appHeightHeader.mobile}px)`,
      position: 'fixed',
      right: 0,
      top: appHeightHeader.mobile,
      width: '100%',

      '&:after': {
        right: 30,
      },
    },

    [theme.breakpoints.down(376)]: {
      '&:after': {
        right: 16,
      },
    },
  },
  serviceMenuItems: {
    alignItems: 'flex-start',
    height: '88%',
    width: '100%',
    overflow: 'hidden auto',
    scrollbarWidth: 'none',
    MsOverFlowStyle: 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  menuElementContent: {
    borderBottom: `1px solid ${theme.palette.common.white}`,
    display: 'flex',
    marginBottom: 12,
    paddingBottom: 12,
    paddingLeft: 8,
  },
  menuElementContentSubOptions: {
    padding: '6px 0',
  },
  menuElementIcon: {
    height: 20,
    marginRight: 20,
    width: 20,
  },
  menuElementText: {
    border: 0,
    color: theme.palette.common.white,
    cursor: 'pointer',
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'inherit',
    padding: 0,
  },
  rloginContainer: {
    gap: 11,
  },
  collpasesContainer: {
    display: 'block',
    width: '100%',
  },
  collapseContentContainer: {
    marginLeft: 32,

    '& > div': {
      paddingLeft: 10,
    },
  },
  countryCollapseContetContainer: {
    '& > div': {
      display: 'grid',
      gridGap: 14,
      gridTemplateColumns: 'repeat(3, 1fr)',
    },

    [theme.breakpoints.down(376)]: {
      '& > div': {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
    },
  },
  langCollapseContetContainer: {
    '& > div': {
      display: 'grid',
      gridGap: 14,
      gridTemplateColumns: '1fr',
    },
  },
  collapseItemContainer: {
    display: 'flex',
    position: 'relative',
  },
  collapseItemSelected: {
    '&::before': {
      backgroundColor: theme.palette.common.white,
      borderRadius: '50%',
      bottom: 0,
      content: "''",
      height: 5,
      left: -10,
      margin: 'auto',
      position: 'absolute',
      top: 0,
      width: 5,
    },
  },
  collapseItemIcon: {
    height: 19,
    marginRight: 6,
    width: 19,
  },
  collapseItemText: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(12),
  },
  authActionsContainer: {
    display: 'grid',
    gridGap: 10,
    gridTemplateColumns: 'auto auto',
    marginTop: 0,
    width: '100%',

    '& > button': {
      border: 0,
    },
  },
  authElementContent: {
    display: 'flex',
    paddingLeft: 8,
  },
  authElementIcon: {
    height: 20,
    marginRight: 20,
    width: 20,
  },
  authElementText: {
    border: 0,
    color: theme.palette.common.white,
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'inherit',
    padding: 0,
  },
  authButton: {
    color: theme.palette.common.white,
    textTransform: 'none',
    minWidth: 170,
    padding: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    width: '100%',
  },
});
