import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import cn from 'classnames';

import { ArrowIcon } from '../Icons';

import styles from './styles';

const useStyles = makeStyles(styles);

const Paginator = ({ page, data, onChange }) => {
  const [newpage, setNewPage] = useState(page || 1);
  const disabledBack = Boolean(page < 1);
  const disabledNext = Boolean(page >= data.length - 1);
  const classes = useStyles();
  const handleNext = () => {
    const newPage = page + 1;
    if (data[newPage]) {
      onChange(newPage);
      setNewPage(newPage + 1);
    }
  };

  const handleBack = () => {
    const newPage = page === 0 ? page : page - 1;
    if (data[newPage]) {
      onChange(newPage);
      setNewPage(newPage || 1);
    }
  };
  return (
    <Grid container>
      <Grid item md={12} xs={12} className={classes.container}>
        <Box
          onClick={handleBack}
          component="button"
          className={classes.buttons}
          disabled={disabledBack}
        >
          <ArrowIcon
            className={cn(classes.buttonRight, {
              [classes.iconDisabled]: disabledBack,
            })}
          />
        </Box>
        <Typography className={classes.pageItem}>{newpage}</Typography>
        <Box
          onClick={handleNext}
          component="button"
          className={classes.buttons}
          disabled={disabledNext}
        >
          <ArrowIcon
            className={cn(classes.buttonLeft, {
              [classes.iconDisabled]: disabledNext,
            })}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

Paginator.propTypes = {
  page: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())).isRequired,
};

export default Paginator;
