import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../../app/modules/Auth/_redux/authRedux';
import * as profile from '../../app/modules/Profile/_redux/profileRedux';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  profile: profile.reducer,
});

export function* rootSaga() {
  yield all([auth.saga(), profile.saga()]);
}
