import { toAbsoluteUrl } from '../utils';

export const SUPPORTED_LANGUAGES = [
  {
    id: 'es',
    urlFlagImg: toAbsoluteUrl('/assets/icons/flags/es.svg'),
  },
  {
    id: 'en',
    urlFlagImg: toAbsoluteUrl('/assets/icons/flags/en.svg'),
  },
];
