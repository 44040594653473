export default (theme) => ({
  menuContainer: {
    position: 'relative',
  },
  btnMenu: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 0,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    display: 'flex',
    fontSize: theme.typography.pxToRem(14),
    justifyContent: 'center',
    padding: 0,
    position: 'relative',
    textAlign: 'left',
    zIndex: 1,
    fontFamily: theme.typography.fontFamily,

    '&:focus, &:focus-visible': {
      outline: 'none',
    },
  },
  iconContainer: {
    height: 19,
    marginRight: theme.spacing(0.75),
    width: 19,
  },
  btnText: {
    marginRight: theme.spacing(0.5),
  },
  btnArrow: {
    fontSize: theme.typography.pxToRem(12),
  },
  countryMenu: {
    alignItems: 'center',
    // eslint-disable-next-line max-len
    background: `transparent linear-gradient(180deg, ${theme.palette.primary.main} 0%, #A67DF8 100%)`,
    boxShadow: '0px 5px 6px #00000029',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'center',
    opacity: 1,
    padding: 10,
    position: 'absolute',
    right: -15,
    top: 32,
    width: 150,
    zIndex: 1901,

    '&::after': {
      borderBottom: `8px solid ${theme.palette.primary.main}`,
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      content: "''",
      height: 0,
      margin: '0 0 0 -3px',
      position: 'absolute',
      right: 15,
      top: -8,
      width: 0,
      zIndex: 100,
    },
  },
  countryMenuItems: {
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
  },
  countryMenuElementContent: {
    display: 'flex',
    paddingLeft: 10,

    '&:not(:last-child)': {
      marginBottom: 12,
      paddingBottom: 12,
    },
  },
  countryMenuElementBtn: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 0,
    color: theme.palette.common.white,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    position: 'relative',
    textAlign: 'left',

    '&:focus, &:focus-visible': {
      outline: 'none',
    },
  },
  countryMenuElementBtnSelected: {
    '&::before': {
      backgroundColor: theme.palette.common.white,
      borderRadius: '50%',
      bottom: 0,
      content: "''",
      height: 5,
      left: -10,
      margin: 'auto',
      position: 'absolute',
      top: 0,
      width: 5,
    },
  },
  countryMenuElementIcon: {
    height: 21,
    width: 21,
  },
  countryMenuElementText: {
    border: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'inherit',
    marginLeft: 6,
  },
});
