export default (theme) => ({
  card: {
    maxWidth: 420,
    margin: '0 auto',
  },
  title: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    marginLeft: theme.spacing(2),
  },
  imgAdornment: {
    width: 25,
    height: 25,
  },
  cardActions: {
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
  },
  cardActionsButton: {
    maxWidth: 210,
  },
  mapContainer: {
    width: '100%',
    height: 300,
    maxHeight: '100%',

    '& .mapboxgl-map': {
      borderRadius: 10,
    },
  },
});
