import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause7 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        7. Tarifas de los servicios y facturación
      </Typography>
      <Typography className={classes.text}>
        El alta y uso de la Plataforma para los Usuarios es completamente
        gratuita, no así la intermediación de los Kriptonians. El uso de la
        Plataforma por parte de los Kriptonians y los Clientes puede tener un
        costo asociado en función del país destino desde el que deseen hacer uso
        de la Plataforma para proveer servicios. <br />
        El Cliente únicamente deberá pagar por cada producto o servicio
        adquirido a través de la Plataforma. Adicionalmente, en aquellos
        servicios que incluyan la compra de un producto, el Usuario deberá pagar
        el precio de dicho producto. El Usuario, al registrarse a través de la
        Plataforma y proporcionar la información de transferencia por Bitcoins,
        autoriza expresamente a KriptonMarket a realizar la transferencia
        mediante Bitex y a pagar en importe en Bitcoins, otra criptomoneda o
        Moneda de Curso Legal al Cliente oferente, de acuerdo al acuerdo que
        KriptonMarket tenga con este último. El precio total de cada servicio de
        mandado puede componerse de un porcentaje variable en función de los
        kilómetros y tiempo que deba recorrer y utilizar el Kriptonian, así como
        en su caso, del preciofijado por cada comercio para los casos en que el
        Usuario solicite la compra física de un producto o servicio.
        KriptonMarket se reserva el derecho de modificar el precio en función de
        la franja horaria y/o distancia en la que se realice el servicio. De
        acuerdo con estas condiciones, el Usuario tendrá derecho a saber la
        tarifa aproximada de su servicio antes de la contratación del mismo y
        formalización del pago. La tarifa del servicio de envío podrá variar
        cuando se dieran circunstancias de fuerza mayor que estuvieran fuera del
        alance de KriptonMarket y que conlleven un aumento en la dificultad del
        mismo. KriptonMarket se reserva el derecho de modificar sus precios en
        cualquier momento. Los cambios efectuados tendrán efecto inmediato tras
        su publicación. El Usuario autoriza expresamente a KriptonMarket para
        que le remita por medios telemáticos, a la dirección de correo
        electrónica facilitada por el mismo durante el proceso de registro, el
        recibo de los servicios contratados y/olas facturas generadas. En caso
        de requerir una factura, el Usuario deberá añadir los datos fiscales a
        la plataforma antes de hacer el pedido. <br />
      </Typography>
      <Typography className={classes.text}>
        La cancelación posterior del servicio por el usuario, en los casos en
        que ya hubiera sido asignado un Kriptonian, facultará a KriptonMarket a
        cargar al Usuario la tarifa por los servicios ya iniciados por el
        Kriptonian. Asimismo, en el caso que el Usuario hubiera solicitado al
        Kriptonian la compra de un producto en nombre suyo, si el mismo
        cancelara el pedido cuando la adquisición ya estuviera realizada,
        asumirá los costos de los servicios de envío realizados por el
        Kriptonian, así como del producto. Todo ello sin perjuicio de que el
        Usuario pueda solicitar un nuevo servicio a los efectos de devolver los
        productos adquiridos, o que se le entreguen en otra dirección. Para el
        caso de productos no perecederos, el Usuario podrá ejercitar su derecho
        de desistimiento ante el comercio que le ha vendido los productos.
      </Typography>
      <Typography className={classes.text}>
        Si desea ejercer el derecho a través de KriptonMarket, deberá contratar
        el nuevo servicio.
      </Typography>

      <Typography component="h4" className={classes.textBold}>
        7.1. Plataforma de pago
      </Typography>
      <Typography className={classes.text}>
        El pago de los servicios y/o productos que se venden presencialmente en
        las tiendas (Clientes), y se entregan a los Usuarios de forma diferida,
        se efectúa transitoriamente a KriptonMarket y este lo transmite a al
        Cliente oferente con los que mantiene un acuerdo comercial. Los
        establecimientos asociados autorizan a KriptonMarket a aceptar el pago
        en su nombre, por lo que el pago del precio de cualquier producto
        efectuado correctamente a favor de KriptonMarket librará al Usuario de
        las obligaciones de abonar dicho precio al establecimiento asociado.
      </Typography>
      <Typography className={classes.text}>
        Asimismo, el pago del Usuario le libera de cualquier obligación respecto
        al Kriptonian, teniendo el cobro total al Usuario efecto liberatorio
        sobre cualquier obligación que pudiera tener con Clientes y/o
        Kriptonians.
      </Typography>
      <Typography className={classes.text}>
        Al elegir emplear la plataforma Bitex, el Usuario consciente que el pago
        de los productos y/o servicios realizados se recibirán en las cuentas de
        KriptonMarket mediante Bitex. Bitex está autorizada para proporcionar
        servicios de pago regulados en todos los territorios en los que opera
        KriptonMarket y cumplen con la normativa vigente en materia de servicios
        de pago para Plataformas como KriptonMarket. En caso de tener cualquier
        duda en relación a la pasarela de pagos que utiliza KriptonMarket, se
        pone a vuestra disposición el siguiente Formulario de Contacto. Al
        elegir realizar la transacción mediante un Kriptonian, el Usuario
        consciente que se pautará particularmente el método y especie del pago,
        consintiendo la fiscalización de KriponMarket.
      </Typography>
    </Box>
  );
};

export default Clause7;
