import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause24 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        24. Resolución extrajudicial de conflictos
      </Typography>
      <Typography className={classes.text}>
        Los conflictos o controversias que tuvieran lugar fuera de Argentina
        quedarán sujetos a su resolución por parte de árbitros, mediadores
        privados, acuerdo entre particulares asesorados por profesionales
        legalmente habilitados para el ejercicio de la abogacía dentro del fuero
        aplicable u cualquier otro medio legalmente contemplado en el mismo.
      </Typography>
    </Box>
  );
};

export default Clause24;
