import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  CloudDownloadOutlined as DownloadIcon,
} from '@material-ui/icons';

import styles from './styles';

const useStyles = makeStyles(styles);

const DialogDetails = ({
  id,
  open,
  onClose,
  isDownloadable,
  onDownloadable,
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby={id}
      open={open}
      keepMounted
      PaperProps={{ elevation: 0 }}
      classes={{ paperWidthFalse: classes.dialogPaperContainer }}
      maxWidth={false}
      scroll="body"
      {...rest}
    >
      <DialogActions className={classes.dialogActionsContainer}>
        {isDownloadable && (
          <IconButton
            aria-label="download"
            onClick={onDownloadable}
            size="small"
          >
            <DownloadIcon color="secondary" fontSize="small" />
          </IconButton>
        )}

        <IconButton aria-label="close" onClick={onClose} size="small">
          <CloseIcon color="secondary" fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContentContainer}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

DialogDetails.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isDownloadable: PropTypes.bool,
  onDownloadable: PropTypes.func,
  children: PropTypes.node.isRequired,
};

DialogDetails.defaultProps = {
  isDownloadable: true,
  onDownloadable: () => {},
};

export default DialogDetails;
