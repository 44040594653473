import React from 'react';
import {
  makeStyles,
  Card,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { FiberManualRecord as CheckIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import CurrentLocationInfo from '../CurrentLocationInfo';
import { MENU_OPTIONS } from '../../../constants/menuOptions';
import { useRouteMatch } from '../../hooks';

import styles from './styles';

const useStyles = makeStyles(styles);

const MenuDashboard = () => {
  const classes = useStyles();
  const { matchPath } = useRouteMatch();
  const { t } = useTranslation('common');

  return (
    <Card className={classes.card}>
      <CurrentLocationInfo noTitle />

      <MenuList className={classes.menu}>
        {MENU_OPTIONS.map((tab) => (
          <MenuItem
            key={tab.path}
            component={Link}
            to={tab.path}
            className={classes.menuItem}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <CheckIcon
                className={cn(classes.icon, {
                  [classes.selected]: matchPath(tab.path),
                })}
              />
            </ListItemIcon>
            <ListItemText
              primary={t(`menu.${tab.label}`)}
              className={classes.menuItemText}
            />
          </MenuItem>
        ))}
      </MenuList>
    </Card>
  );
};

export default MenuDashboard;
