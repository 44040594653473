export default (theme) => ({
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
    display: 'inline-block',
    width: 'max-content',
  },
  fullWidth: {
    width: '100%',
  },
  disabledLoadingButton: {
    color: theme.palette.action.disabled,
    cursor: 'default',
    pointerEvents: 'none',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
