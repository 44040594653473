import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause23 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        23. Legislación Aplicable
      </Typography>
      <Typography className={classes.text}>
        La relación entre KriptonMarket se regirá e interpretará de conformidad
        con las Condiciones Generales que en materia de interpretación, validez
        y ejecución se regirán por la legislación de Argentina; y cualquier
        controversia se someterá al fuero Nacional radicado en la Ciudad
        Autónoma de Buenos Aires, salvo que el Usuario solicite los tribunales
        de su domicilio de residencia.
      </Typography>
    </Box>
  );
};

export default Clause23;
