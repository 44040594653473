import { capitalize, makeStyles } from '@material-ui/core';
import { spacing as spacingMaterial } from '@material-ui/system';
import { isEmpty } from 'lodash';

export const guttersApp = (spacing, breakpoints) => ({
  paddingLeft: spacing(18.75),
  paddingRight: spacing(18.75),
  maxWidth: 1440,

  [breakpoints.down(1025)]: {
    paddingLeft: spacing(8.75),
    paddingRight: spacing(8.75),
  },

  [breakpoints.down(769)]: {
    paddingLeft: spacing(7.5),
    paddingRight: spacing(7.5),
  },

  [breakpoints.down(481)]: {
    paddingLeft: spacing(3.75),
    paddingRight: spacing(3.75),
  },

  [breakpoints.down(376)]: {
    paddingLeft: spacing(1.875),
    paddingRight: spacing(1.875),
  },
});

export const maxWidthMenuDashboard = 180;

export const gridTableWithMenuDashboardClasses = makeStyles((theme) => ({
  maxWidthTableGrid: {
    maxWidth: '100%',

    [theme.breakpoints.up('md')]: {
      maxWidth: ({ spacingGridContainer }) =>
        `calc(100% - ${
          maxWidthMenuDashboard + theme.spacing(spacingGridContainer)
        }px)`,
    },
  },
}));

const directions = ['Top', 'Right', 'Bottom', 'Left', 'X', 'Y'];
const spacings = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const hasBreakpoint = (breakpoint) => !isEmpty(breakpoint);

const getKeyGutter = ({ direction, breakpoint, spacing }) => {
  const breakpoinValue = hasBreakpoint(breakpoint)
    ? capitalize(breakpoint)
    : '';

  return `gutter${direction}${breakpoinValue}${spacing}`;
};

const generateGutters = ({ direction, breakpoint, spacing }) => {
  const paddingKey = `padding${direction}`;

  const calculateSpacing = spacing === 0 ? 0 : spacing / 2;

  return spacingMaterial({
    theme: {},
    [paddingKey]: hasBreakpoint(breakpoint)
      ? { [breakpoint]: calculateSpacing }
      : calculateSpacing,
  });
};

export const guttersGrid = makeStyles((theme) => {
  const styles = {};
  const breakpointsKeys = [null, ...theme.breakpoints.keys];

  directions.forEach((direction) => {
    breakpointsKeys.forEach((breakpoint) => {
      spacings.forEach((spacing) => {
        const keyStyle = getKeyGutter({
          direction,
          breakpoint,
          spacing,
        });

        styles[keyStyle] = generateGutters({
          direction,
          breakpoint,
          spacing,
        });
      });
    });
  });

  return styles;
});

export const typographyInfoPage = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main,
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const appHeightHeader = {
  desktop: 60,
  mobile: 60,
};

export const appLateralMenuHeight = 260;
