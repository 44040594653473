export const ERRORS_MESSAGE = {
  notFoundRetryLater:
    'Lo sentimos lo datos no pudieron ser cargados, intententelo nuevamente mas tarde',
  actionRequestSuccess: (action) => `${action} satisfactoriamente`,
  actionRequestError: (action) =>
    `No se pudo ${action}, intententelo nuevamente mas tarde`,
  actionError: (message) => message,
  twoFANotValid: 'Código de 2FA erróneo',
  action: (message) => message,
};
