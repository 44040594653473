import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box } from '@material-ui/core';
import cn from 'classnames';

import Header from '../Header';
import Footer from '../Footer';
import { useHeightHeaderFooter } from '../../hooks';

import styles from './styles';

const useStyles = makeStyles(styles);

const Layout = ({ children, isAuthPage, isPrivatePage, noFooter }) => {
  const classes = useStyles();
  const { classes: classesMinHeight } = useHeightHeaderFooter();

  return (
    <>
      <Header isAuthPage={isAuthPage} isPrivatePage={isPrivatePage} />
      <Box
        component="main"
        className={cn(classes.root, classesMinHeight.minHeightWithHeader)}
      >
        {children}
      </Box>
      {!noFooter && <Footer />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isAuthPage: PropTypes.bool,
  isPrivatePage: PropTypes.bool,
  noFooter: PropTypes.bool,
};

Layout.defaultProps = {
  isAuthPage: false,
  isPrivatePage: false,
  noFooter: false,
};

export default Layout;
