import { useState, useCallback } from 'react';

export const useDialogToggle = (initialToggle = false) => {
  const [openDialog, setOpenDialog] = useState(initialToggle);

  const toggleOpenDialog = useCallback(() => setOpenDialog(!openDialog), [
    openDialog,
  ]);

  return [openDialog, toggleOpenDialog];
};
