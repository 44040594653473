export default (theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  tableContainer: {
    borderRadius: '0 !important',
    height: '100%',
  },
  extraButton: {
    alignSelf: 'flex-end',
    margin: theme.spacing(2),
  },
});
