import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles, Box } from '@material-ui/core';
import styles from './styles';

const useStyles = makeStyles(styles);

const PDFPaginator = ({ pageNumber, totalPages, getNumber }) => {
  const classes = useStyles();
  const onClickDown = () => {
    if (pageNumber > 1) {
      getNumber(pageNumber - 1);
    }
  };

  const onClickUp = () => {
    if (pageNumber < totalPages) {
      getNumber(pageNumber + 1);
    }
  };

  return (
    <Box className={classes.paginateContainer}>
      <Box
        onClick={onClickDown}
        className={classnames(classes.paginateArrows, {
          [classes.disabled]: pageNumber === 1,
        })}
      >{`<`}</Box>
      <Box className={classes.paginateText}>
        {pageNumber} of {totalPages}
      </Box>
      <Box
        onClick={onClickUp}
        className={classnames(classes.paginateArrows, {
          [classes.disabled]: pageNumber === totalPages,
        })}
      >
        {`>`}
      </Box>
    </Box>
  );
};

PDFPaginator.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  getNumber: PropTypes.func.isRequired,
};

export default PDFPaginator;
