import { darken } from '@material-ui/core';

export default (theme) => ({
  card: {
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(1),
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.short,
    }),
    width: `calc(100% - ${theme.spacing(2)}px)`,

    '&:hover': {
      backgroundColor: darken(
        theme.palette.common.white,
        theme.palette.action.activatedOpacity,
      ),
    },
  },
  cardContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },

    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  logo: {
    height: 30,
    objectFit: 'contain',
    width: 30,
  },
  pricesContainer: {
    flex: 1,
    marginLeft: 0,
    marginTop: theme.spacing(1),
    textAlign: 'center',

    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(6),
      marginTop: 0,
      textAlign: 'left',
    },
  },
  priceTypography: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,

    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
});
