import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause20 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        20. Actualización y Modificación de la Plataforma
      </Typography>
      <Typography className={classes.text}>
        KriptonMarket se reserva el derecho de modificar, en cualquier momento y
        sin previo aviso las presentes Condiciones Generales de Uso, la Política
        de Privacidad. <br />
        Los Usuarios y Clientes deberán leer atentamente estas Condiciones
        Generales al acceder a la Plataforma. En cualquier caso, la aceptación
        de las Condiciones Generales será un paso previo e indispensable al
        acceso de los servicios y contenidos disponibles a través de la
        Plataforma de KriptonMarket. <br />
        Asimismo, KriptonMarket se reserva la facultad de efectuar, en cualquier
        momento y sin necesidad de previo aviso, actualizaciones, modificaciones
        o eliminación de información contenida en su Plataforma en la
        configuración y presentación de esta y de las condiciones de acceso, sin
        asumir responsabilidad alguna por ello. KriptonMarket no garantiza la
        inexistencia de interrupciones o errores en el acceso de la Plataforma o
        a su contenido, ni que esta se encuentre siempre actualizada, no
        obstante, KriptonMarket llevará a cabo, siempre que no concurran causas
        que lo hagan imposible o de difícil ejecución, y tan pronto tenga
        noticia de los errores, desconexiones o falta de actualización en los
        contenidos, todas aquellas labores tendentes a subsanar los errores,
        restablecer la comunicación y actualizar los contenidos.
      </Typography>
    </Box>
  );
};

export default Clause20;
