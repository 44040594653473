export default (theme) => ({
  rloginContainer: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 0,
    color: ({ color }) =>
      color === 'purple'
        ? theme.palette.secondary.main
        : theme.palette.common.white,
    cursor: 'pointer',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    fontSize: 'inherit',
    gap: 6,
    outline: 0,
    padding: 0,
    width: '100%',
  },
  rloginContentRskIcon: {
    height: 20,
    width: 30,
  },
  rloginContentText: {
    textAlign: 'left',
    flex: 1,
  },
  rloginContentLogoutIcon: {
    height: 20,
    width: 20,
  },
});
