export const LOCAL_KINDS = [
  { key: 'foodDrinks', value: 'food_and_drinks' },
  { key: 'estate', value: 'estate' },
  { key: 'tourism', value: 'tourism' },
  { key: 'clothes', value: 'clothes' },
  { key: 'courses', value: 'courses' },
  { key: 'furniture', value: 'furniture' },
  { key: 'cellphones', value: 'cellphones' },
  { key: 'cosmetics', value: 'cosmetics' },
  { key: 'automotive', value: 'automotive' },
  { key: 'events', value: 'events' },
  { key: 'professionals', value: 'professionals' },
];
