import { useState, useMemo, useRef, useEffect } from 'react';
import { throttle } from 'lodash';

export const usePlacesAutocomplete = (initialValueRequest) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const autocompleteServicesRef = useRef(null);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteServicesRef.current?.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteServicesRef.current && window.google) {
      autocompleteServicesRef.current = new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteServicesRef.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
    if (initialValueRequest) {
      setInputValue(initialValueRequest);
      fetch({ input: initialValueRequest }, (results) => {
        let newOptions = [];

        if (results) {
          newOptions = [...newOptions, ...results];
          setValue(results[0]);
        }

        setOptions(newOptions);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValueRequest]);

  return {
    value,
    setValue,
    inputValue,
    setInputValue,
    options,
    setOptions,
  };
};
