import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import styles from './styles';

const useStyles = makeStyles(styles);

const NoDataItem = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <Typography className={classes.root}>{t('tableColumns.noData')}</Typography>
  );
};

export default NoDataItem;
