import { maxWidthMenuDashboard } from '../../../utils/stylesUtils';

export default (theme) => ({
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: maxWidthMenuDashboard,
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  img: {
    height: 103,
    marginBottom: theme.spacing(2),
    objectFit: 'contain',
  },
  menu: {
    width: '100%',
  },
  menuItem: {
    borderBottom: '1px solid #00000029',
    padding: theme.spacing(1, 4),

    '&:last-child': {
      borderBottom: 0,
    },
  },
  selected: {},
  menuItemIcon: {
    minWidth: 30,
  },
  icon: {
    color: '#E3E3E3',
    fontSize: theme.typography.pxToRem(14),

    '&$selected': {
      color: theme.palette.primary.main,
    },
  },
  menuItemText: {
    flex: 'inherit',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
  },
});
