export default (theme) => ({
  cardContent: {
    padding: theme.spacing(1, 2, 0, 0.5),
    display: 'flex',
    alignItems: 'center',
  },
  cardIcon: {
    width: 55,
    height: 55,
  },
  cardContentInfo: {
    flex: 1,
  },
  cardContentInfoTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },
  cardContentInfoText: {
    fontSize: theme.typography.pxToRem(10),
  },
  cardActions: {
    padding: theme.spacing(0, 1, 0.5, 0.5),
    justifyContent: 'flex-end',
  },
  cardActionsButton: {
    fontSize: theme.typography.pxToRem(12),
  },
});
