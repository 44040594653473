import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause8 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        8. Precio de los productos y/o servicios consignados en la Plataforma
      </Typography>
      <Typography className={classes.text}>
        Todos los precios que se indican en la Plataforma incluyen los impuestos
        que pudieran ser aplicables en función del territorio desde el que opere
        el Usuario y en todo caso se expresarán en la Moneda de Curso Legal
        vigente en función del territorio desde el que opere el Usuario. De
        acuerdo con la Cláusula 6 anterior, los precios aplicables en cada
        servicio serán los publicados en la Plataforma, sujetos a las
        particularidades expuestas y aplicados de forma automática en el proceso
        de contratación en la última fase del mismo. <br />
        No obstante, los precios de los productos en venta, o de los servicios
        ofrecidos por Clientes, mostrados en la Plataforma de KriptonMarket
        podrán ser orientativos. En todo caso, dichos precios corresponden a los
        productos de venta en tiendas, o a los servicios ofrecidos por Clientes,
        y son exclusivamente fijados por ellos. El Usuario podrá comunicarse con
        el Kriptonian a los efectos de confirmar el precio final de los
        productos, o servicios, solicitados. <br />
        El Usuario asume que en todo caso la valoración económica de algunos de
        los productos podrá variar en tiempo real en función del establecimiento
        que los vende y el “stock” disponible. Para cualquier información sobre
        el pedido solicitado, el Usuario contactará con KriptonMarket a través
        del Formulario de Contacto en donde deberá indicar en el asunto del
        mensaje el número asignado por la Plataforma de KriptonMarket. <br />
        De acuerdo con lo anterior, el Usuario, mediante la solicitud de compra
        y envío a través de la plataforma, confiere al Kriptonian un mandato
        para adquirir presencialmente los productos en su nombre por el precio
        fijado por los comercios. El Usuario podrá estar en contacto directo con
        el Kriptonian tanto durante la realización del pedido, como en la
        entrega del mismo para indicar al Kriptonian qué producto solicita.
      </Typography>
    </Box>
  );
};

export default Clause8;
