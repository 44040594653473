import React from 'react';
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Link as LinkMaterial,
} from '@material-ui/core';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import cn from 'classnames';

import { PATHS } from '../../../constants/paths';
import { useHeightHeaderFooter } from '../../hooks';
import { toAbsoluteUrl } from '../../../utils';
import { getLandingUrl } from '../../../utils/externalPages';

import styles from './styles';

const useStyles = makeStyles(styles);

const configSliderCovers = {
  infinite: true,
  autoplay: true,
  speed: 1000,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: false,
  pauseOnHover: false,
  swipe: false,
  fade: true,
};

const coversList = [
  {
    id: 'cover1',
    url: toAbsoluteUrl('/assets/images/bg-cover-home-1.png'),
    alt: 'Cover 1',
  },
  {
    id: 'cover2',
    url: toAbsoluteUrl('/assets/images/bg-cover-home-2.png'),
    alt: 'Cover 2',
  },
  {
    id: 'cover3',
    url: toAbsoluteUrl('/assets/images/bg-cover-home-3.png'),
    alt: 'Cover 3',
  },
];

const Hero = () => {
  const { t } = useTranslation('common');
  const { heightHeader } = useHeightHeaderFooter();
  const classes = useStyles({ heightHeader });

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <Typography
            variant="h1"
            component="h1"
            className={classes.titleTypography}
            color="primary"
          >
            {t('home.hero.title1')}
            <Typography variant="inherit" component="span">
              {t('home.hero.title2')}
            </Typography>{' '}
            {t('home.hero.title3')}
          </Typography>
        </Box>

        <Box className={classes.coverContainer}>
          <Slider {...configSliderCovers}>
            {coversList.map(({ id, url, alt }) => (
              <Box
                key={id}
                component="img"
                src={url}
                alt={alt}
                className={classes.coverSliderItemImg}
              />
            ))}
          </Slider>
        </Box>

        <Box className={classes.infoContainer}>
          <Typography component="p" className={classes.infoTypography}>
            {t('home.hero.description1')}
            <Typography variant="inherit" component="span">
              {t('home.hero.description2')}
            </Typography>{' '}
            {t('home.hero.description3')}
          </Typography>

          <Typography component="p" className={classes.infoTypography}>
            {t('home.hero.description4')}
          </Typography>
        </Box>

        <Box className={classes.actionsContainer}>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            className={classes.actionsButton}
            component={Link}
            to={PATHS.registration.path}
          >
            {t('home.hero.buttonStart')}
          </Button>

          <Typography component="p" className={classes.actionsTypography}>
            {t('home.hero.proposalText')}{' '}
            <LinkMaterial
              href={getLandingUrl()}
              target="_blank"
              underline="none"
              color="secondary"
              variant="inherit"
            >
              {t('home.hero.visitLink')}
            </LinkMaterial>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
