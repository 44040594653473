export default (theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightBold,

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  slickItemContainer: {
    '&:focus': {
      outline: 0,
    },
  },
  slickItemImg: {
    height: 146,
    margin: 'auto',
    objectFit: 'contain',
    width: 124,
  },
});
