import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import { toAbsoluteUrl } from '../../../utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const RegistrationModal = ({ open, status, onClose }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const handleClose = useCallback(() => {
    onClose(false);
    if (status === 'OK') {
      window.location.href = '/login';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const textToShow =
    status === 'OK'
      ? t('registration.registrationSuccess')
      : t('registration.registrationError');

  const titleTotShow =
    status === 'OK'
      ? t('registration.wellcome')
      : t('registration.errorHappen');
  const iconToShow =
    status === 'OK' ? '/assets/icons/check-ok.svg' : '/assets/icons/error.svg';

  return (
    <Dialog maxWidth={false} open={open}>
      <Box component="div" className={classes.form}>
        <Grid container>
          <Grid item md={12} sm={12}>
            <DialogTitle disableTypography className={classes.root}>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon color="secondary" />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container justify="center">
                <Grid item md={8} xs={10} className={classes.iconContent}>
                  <img
                    src={toAbsoluteUrl(iconToShow)}
                    alt="status"
                    className={classes.icon}
                  />
                </Grid>
              </Grid>
              <Typography variant="h6" className={classes.title}>
                {titleTotShow}
              </Typography>
              <Typography variant="h6" className={classes.text}>
                {textToShow}
              </Typography>
            </DialogContent>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

RegistrationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RegistrationModal;
