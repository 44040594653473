import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PATHS } from '../../../constants/paths';

import styles from './styles';

const useStyles = makeStyles(styles);

const LocalCard = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Box
          component="img"
          src={`https://public-kriptonmarket.s3.amazonaws.com/img/map/icons/${data.kind}.svg`}
          alt={data.kind}
          className={classes.cardIcon}
        />

        <Box className={classes.cardContentInfo}>
          <Typography className={classes.cardContentInfoTitle}>
            {data.name}
          </Typography>
          <Typography className={classes.cardContentInfoText}>
            {data.description}
          </Typography>
          <Typography className={classes.cardContentInfoText}>
            {data.address}
          </Typography>
        </Box>
      </CardContent>

      <CardActions className={classes.cardActions}>
        <Button
          variant="text"
          size="small"
          className={classes.cardActionsButton}
          component={Link}
          to={{
            pathname: PATHS.myStoreLocalEdit.path.replace(':id', data.id),
            state: data,
          }}
        >
          {t('store.businessUpdate')}
        </Button>
      </CardActions>
    </Card>
  );
};

LocalCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    latitude: PropTypes.string.isRequired,
    longitude: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    json_data: PropTypes.object,
    address: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
    payment_url: PropTypes.string.isRequired,
    focus_map: PropTypes.bool.isRequired,
  }).isRequired,
};

export default LocalCard;
