import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const CollapseMenuSelector = ({
  open,
  handleOpen,
  title,
  Icon,
  className,
  classNameContent,
  children,
}) => {
  const classes = useStyles();

  const contentRef = useRef(null);
  const [heightContent, setHeightContent] = useState(0);

  useEffect(() => {
    if (open)
      setHeightContent(contentRef.current?.getBoundingClientRect().height);
    else setHeightContent(0);
  }, [open]);

  return (
    <div className={classnames(classes.container, className)}>
      <button
        type="button"
        onClick={handleOpen}
        className={classnames(classes.headerContainer)}
      >
        <div className={classes.headerTitleContainer}>
          {Icon && <div className={classes.titleIconContainer}>{Icon}</div>}

          <h6 className={classes.titleText}>{title}</h6>
        </div>

        <div
          className={classnames(classes.headerArrowButton, {
            [classes.headerArrowButtonOpen]: open,
          })}
        >
          <span>&#9656;</span>
        </div>
      </button>
      <div
        className={classnames(
          classes.contentContainer,
          classes.collapse,
          classNameContent,
          {
            [classes.collapseOpen]: open,
          },
        )}
        style={{ height: heightContent }}
      >
        <div ref={contentRef}>{children}</div>
      </div>
    </div>
  );
};

CollapseMenuSelector.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  Icon: PropTypes.element,
  className: PropTypes.string,
  classNameContent: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CollapseMenuSelector.defaultProps = {
  Icon: null,
  className: null,
  classNameContent: null,
};

export default CollapseMenuSelector;
