import React, { useState, useMemo, useCallback } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { toUpper } from 'lodash';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import CardWithProgressLoading from '../CardWithLoadingProgress';
import ConfigSelection from '../ConfigSelection';
import ConfigSelectionSwitch from '../ConfigSelectionSwitch';
import ConfigSelectionSwitchLabel from '../ConfigSelectionSwitchLabel';

import { useRequest } from '../../hooks';
import { getCurrenciesNetworksConfig } from '../../../services/merchantsService';

import styles from './styles';

const useStyles = makeStyles(styles);

const getConfigAllowObject = (data) =>
  data.reduce((acc, item) => {
    acc[item.id] = item.allow;

    return acc;
  }, {});

const ConfigCurrencies = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const [configAllow, setConfigAllow] = useState({});
  const handleSetConfigAllowItem = useCallback(
    (keyConfig) => (id, allow) => {
      setConfigAllow((prevConfig) => ({
        ...prevConfig,
        [keyConfig]: {
          ...prevConfig[keyConfig],
          [id]: allow,
        },
      }));
    },
    [],
  );

  const [data, loading] = useRequest(
    {
      initialState: {},
      request: getCurrenciesNetworksConfig,
      transformResponse: (response) => {
        const currentConfigAllog = {
          cryptoNetworks: getConfigAllowObject(response.data.crypto_networks),
          currencies: getConfigAllowObject(response.data.currencies),
        };

        setConfigAllow(currentConfigAllog);

        return {
          cryptoNetworks: response.data.crypto_networks,
          currencies: response.data.currencies,
        };
      },
    },
    [],
  );

  const columnsNetworks = useMemo(
    () => [
      {
        name: t(`store.currency`),
        selector: 'currency_name',
        sortable: false,
        format: (row) => (
          <ConfigSelectionSwitch
            selectionId={row.id}
            type="crypto_network"
            name={row.currency_name}
            icon={row.currency_icon}
            allow={configAllow.cryptoNetworks?.[row.id] ?? false}
            truncateText
            handleChangeAllow={handleSetConfigAllowItem('cryptoNetworks')}
          />
        ),
      },
      {
        name: t(`store.network`),
        selector: 'name',
        sortable: false,
        format: (row) => (
          <ConfigSelectionSwitchLabel
            name={row.name}
            icon={row.icon}
            allow={configAllow.cryptoNetworks?.[row.id] ?? false}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configAllow.cryptoNetworks, handleSetConfigAllowItem],
  );

  const columnsCurrenciesNofiat = useMemo(
    () => [
      {
        name: t(`store.fiat`),
        selector: 'name',
        sortable: false,
        format: (row) => (
          <ConfigSelectionSwitch
            selectionId={row.id}
            type="currency"
            name={`${toUpper(row.id)} - ${row.name}`}
            allow={configAllow.currencies?.[row.id] ?? false}
            handleChangeAllow={handleSetConfigAllowItem('currencies')}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configAllow.currencies, handleSetConfigAllowItem],
  );

  return (
    <Box className={classes.container}>
      <CardWithProgressLoading
        loading={loading}
        className={cn(classes.card, classes.cardOne, {
          [classes.cardLoading]: loading,
        })}
      >
        <Typography className={classes.title}>
          {t(`store.currencyAndTokens`)}
        </Typography>

        <Typography className={classes.text}>
          {t('store.currencyAndTokensDescriptionOne')}
        </Typography>

        <Box className={classes.selectionsContainer}>
          <ConfigSelection
            data={data.cryptoNetworks}
            columns={columnsNetworks}
          />
        </Box>
      </CardWithProgressLoading>

      <CardWithProgressLoading
        loading={loading}
        className={cn(classes.card, classes.cardTwo, {
          [classes.cardLoading]: loading,
        })}
      >
        <Typography className={classes.title} style={{ visibility: 'hidden' }}>
          -
        </Typography>

        <Typography className={classes.text}>
          {t('store.currencyAndTokensDescriptionTwo')}
        </Typography>

        <Box className={classes.selectionsContainer}>
          <ConfigSelection
            data={data.currencies}
            columns={columnsCurrenciesNofiat}
          />
        </Box>
      </CardWithProgressLoading>
    </Box>
  );
};

export default ConfigCurrencies;
