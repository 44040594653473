export const TYPES_MOVEMENTS = {
  RETIREMENT: 'retirement',
  COMMISSION: 'commission',
  PAYMENT: 'payment',
};

export const TYPE_MOVEMENTS_DESCRIPTIONS = {
  [TYPES_MOVEMENTS.RETIREMENT]: 'Redimido',
  [TYPES_MOVEMENTS.COMMISSION]: 'Comisión',
  [TYPES_MOVEMENTS.PAYMENT]: 'Pago',
};

export const LIQUIDATION_TYPES = {
  NORMAL: 'normal',
  SWAP: 'swap',
};
