export default (theme) => ({
  dialogPaperContainer: {
    maxWidth: 480,
    margin: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(2 * 2)}px)`,
    maxHeight: `calc(100% - ${theme.spacing(4)}px)`,
  },
  dialogTitleContainer: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  dialogTitleTypography: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightBold,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: 0,
  },
  dialogContentContainer: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  containerImages: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  imgAppRecomend: {
    width: 150,
    height: 110,
    backgroundColor: 'orange', // @TODO: delete when you have the image
    marginBottom: theme.spacing(1),
  },
  storesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& :first-child': {
      marginBottom: theme.spacing(0.5),
    },
  },
  imgStore: {
    width: 125,
    height: 40,
  },
  imgQrApp: {
    marginBottom: theme.spacing(2),
  },
  dialogActionsContainer: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    justifyContent: 'center',
  },
  sendButton: {
    maxWidth: 376,
  },
});
