import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

export const CustomSnackbarProvider = ({ children }) => {
  const notistackRef = useRef();

  const onClickDismiss = useCallback(
    (key) => () => {
      notistackRef.current.closeSnackbar(key);
    },
    [],
  );

  const actionClose = useCallback(
    (key) => (
      <IconButton
        aria-label={`close-snack-${key}`}
        onClick={onClickDismiss(key)}
        color="inherit"
      >
        <CloseIcon />
      </IconButton>
    ),
    [onClickDismiss],
  );

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      action={actionClose}
    >
      {children}
    </SnackbarProvider>
  );
};

CustomSnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
