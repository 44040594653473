import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@material-ui/lab';
import cn from 'classnames';

import styles from './styles';

const useStyles = makeStyles(styles);

const TimeLine = ({ data, align, disableOppositeContent, className }) => {
  const classes = useStyles();

  return (
    <Timeline align={align} className={cn(classes.root, className)}>
      {data.map(({ id, content }, index) => (
        <TimelineItem key={id} classes={{ root: classes.itemContainer }}>
          <TimelineOppositeContent
            classes={{
              root: cn({
                [classes.disableOppositeContent]:
                  align === 'left' && disableOppositeContent,
              }),
            }}
          />
          <TimelineSeparator>
            <TimelineDot
              color="inherit"
              classes={{ root: classes.dotSeparator }}
            />
            {index < data.length - 1 && (
              <TimelineConnector
                classes={{ root: classes.connectorSeparator }}
              />
            )}
          </TimelineSeparator>
          <TimelineContent classes={{ root: classes.content }}>
            {content}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

TimeLine.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ).isRequired,
  align: PropTypes.oneOf(['left', 'right', 'alternate']),
  disableOppositeContent: PropTypes.bool,
  className: PropTypes.string,
};

TimeLine.defaultProps = {
  className: null,
  align: 'left',
  disableOppositeContent: false,
};

export default TimeLine;
