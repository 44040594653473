import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import RangePicker from 'react-range-picker';

import CustomPlaceHolder from './CustomPlaceHolder';

import { formatDate } from '../../../utils';
import { typesInputs } from './utils';

const DatepickerRange = ({ name, showTime }) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext();

  const evaluateDateValue = useCallback((startDate, endDate) => {
    const range = {};

    if (startDate)
      range[typesInputs.startDate] = formatDate(
        startDate,
        'DD/MM/YYYY hh:mm A',
      );

    if (endDate)
      range[typesInputs.endDate] = formatDate(endDate, 'DD/MM/YYYY hh:mm A');

    return range;
  }, []);

  const handleChange = useCallback(
    (startDate, endDate) => {
      const vals = evaluateDateValue(startDate, endDate);

      setFieldValue(name, vals);
      setFieldTouched(name, {
        [typesInputs.startDate]: true,
        [typesInputs.endDate]: true,
      });
    },
    [evaluateDateValue, name, setFieldTouched, setFieldValue],
  );

  return (
    <RangePicker
      defaultValue={get(values, name)}
      placeholder={({ startDate, endDate }) => (
        <CustomPlaceHolder
          startDate={
            startDate ? formatDate(startDate, 'DD/MM/YYYY hh:mm A') : ''
          }
          endDate={endDate ? formatDate(endDate, 'DD/MM/YYYY hh:mm A') : ''}
          nameDateRange={name}
        />
      )}
      selectTime={showTime}
      onDateSelected={handleChange}
    />
  );
};

DatepickerRange.propTypes = {
  name: PropTypes.string.isRequired,
  showTime: PropTypes.bool,
};

DatepickerRange.defaultProps = {
  showTime: true,
};

export default DatepickerRange;
