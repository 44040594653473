export default (theme) => ({
  root: {
    display: 'flex',
  },
  gutterTop: {
    marginTop: theme.spacing(4),
  },
  gutterBottom: {
    marginBottom: theme.spacing(4),
  },
  containerTitle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  typography: {
    fontSize: theme.typography.pxToRem(28),
    lineHeight: 1,
    textTransform: 'uppercase',
  },
  divider: {
    background: theme.palette.background.gradient.mix2,
    height: 6,
    marginRight: 0,
    marginTop: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      flex: 1,
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(0),
    },
  },
});
