export default (theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    // flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '100%',
  },
  img: {
    height: 103,
    marginBottom: theme.spacing(2),
    width: 103,
  },
});
