import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause22 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        22. Independencia de las Cláusulas
      </Typography>
      <Typography className={classes.text}>
        Si cualquiera de las cláusulas de las presentes Condiciones Generales
        fuera nula de pleno derecho o anulable, se tendrá por no puesta. Dicha
        declaración de nulidad no invalidará el resto del Contrato, que
        mantendrá su vigencia y eficacia entre las Partes.
      </Typography>
    </Box>
  );
};

export default Clause22;
