import MontserratRegularWoff from './Montserrat/Montserrat-Light.woff';
import HelveticaNeueWoff from './Helvetica/HelveticaNeueLTStd-Th.woff';
import RobotoWoff from './Roboto/Roboto.woff';

export const MontserratRegular = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('Montserrat'),
    local('Montserrat-Regular'),
    url(${MontserratRegularWoff}) format('woff2')
  `,
  unicodeRange:
    // eslint-disable-next-line max-len
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const HelveticaNeue = {
  fontFamily: 'Helvetica Neue',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('Helvetica Neue'),
    local('HelveticaNeueLTStd-Th'),
    url(${HelveticaNeueWoff}) format('woff2')
  `,
  unicodeRange:
    // eslint-disable-next-line max-len
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const Roboto = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('Roboto'),
    local('Roboto'),
    url(${RobotoWoff}) format('woff2')
  `,
  unicodeRange:
    // eslint-disable-next-line max-len
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
