import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import DialogDetails from '../DialogDetails';
import DialogDetailsItem from '../DialogDetailsItem';

import { getStatusDescription } from '../../../utils';

const DialogDetailsOperation = ({ open, onClose, data }) => {
  const { t } = useTranslation('common');
  const {
    amount_in: amountIn,
    client,
    comment,
    commission,
    created_at: createdAt,
    currency_in: currencyIn,
    currency_out: currencyOut,
    id,
    status,
    tx_hash: txHash,
    type,
    updated_at: updatedAt,
    url_voucher_image: urlVoucherImage,
  } = data;

  return (
    <DialogDetails
      id="dialog-details-operation"
      open={open}
      onClose={onClose}
      isDownloadable={false}
      maxWidth="xs"
    >
      {open && !isEmpty(data) && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DialogDetailsItem
              title={t('tableColumns.amount')}
              content={amountIn}
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem title={t('tableColumns.id')} content={id} />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t('tableColumns.user')}
              content={client.username}
              wordBreakContent
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t('tableColumns.currencyCollection')}
              content={currencyIn}
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t('tableColumns.currencyDelivery')}
              content={currencyOut}
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem title="%" content={commission.percentage} />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t('tableColumns.status')}
              content={getStatusDescription(status, type)}
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t('tableColumns.dateStart')}
              content={moment(createdAt).format('DD/MM/YYYY')}
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t('tableColumns.lastModification')}
              content={moment(updatedAt).format('DD/MM/YYYY')}
            />
          </Grid>

          <Grid item xs={6}>
            <DialogDetailsItem
              title={t('tableColumns.hash')}
              content={txHash}
            />
          </Grid>

          <Grid item xs={12}>
            <DialogDetailsItem
              title={t('tableColumns.note')}
              content={comment}
            />
          </Grid>

          <Grid item xs={12}>
            <DialogDetailsItem
              title={t('tableColumns.voucherDeposit')}
              content={
                urlVoucherImage ? (
                  <Box
                    component="img"
                    alt={t('tableColumns.voucherDeposit')}
                    src={urlVoucherImage}
                    height={313}
                  />
                ) : null
              }
              disableTypographyContent
            />
          </Grid>

          <Grid item xs={12}>
            <DialogDetailsItem
              title={t('tableColumns.userData')}
              content={client.name}
            />
          </Grid>
        </Grid>
      )}
    </DialogDetails>
  );
};

DialogDetailsOperation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    amount_in: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    amount_out: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    client: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      username: PropTypes.string,
    }),
    comment: PropTypes.string,
    commission: PropTypes.shape({
      percentage: PropTypes.number,
    }),
    created_at: PropTypes.string,
    currency_in: PropTypes.string,
    currency_out: PropTypes.string,
    id: PropTypes.number,
    merchant: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    status: PropTypes.string,
    tx_hash: PropTypes.string,
    type: PropTypes.string,
    updated_at: PropTypes.string,
    url_voucher_image: PropTypes.string,
  }),
};

DialogDetailsOperation.defaultProps = {
  data: null,
};

export default DialogDetailsOperation;
