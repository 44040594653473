import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getDomicilialInfo,
  getBankInfo,
  getWalletsAvailables,
  getUserWallets,
} from '../../../../services/merchantsService';
import { getConfigForms } from '../../../../services/publicServices';

import { buildFiscalData } from '../../../../utils/profileFormsUtils';

export const actionTypes = {
  getFiscalData: '[get Fiscal Data] Action',
  getDomicilialData: '[get Domicilial Data] Action',
  setDomicilialData: '[set Domicilial Data] Action',
  getBankData: '[get Bank Data] Action',
  setBankData: '[set Bank Data] Action',
  getBankConfigFormData: '[get Bank Config Form Data] Action',
  setBankConfigFormData: '[set Bank Config Form Data] Action',
  getWalletData: '[get Wallet Data] Action',
  setWalletData: '[set Wallet Data] Action',
  getCurrenciesData: '[get Currencies Data] Action',
  setCurrenciesData: '[set Currencies Data] Action',
  setLoading: '[set Loading] Action',
};

const initialAuthState = {
  fiscalData: {},
  currencies: [],
  information: {},
};

export const reducer = persistReducer(
  {
    storage,
    key: 'v1-km-profile',
    whitelist: ['information'],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.setDomicilialData: {
        return {
          ...state,
          information: {
            ...state.information,
            addressData: action.payload,
          },
        };
      }

      case actionTypes.setBankData: {
        return {
          ...state,
          information: {
            ...state.information,
            bankData: action.payload,
          },
        };
      }

      case actionTypes.setBankConfigFormData: {
        return {
          ...state,
          information: {
            ...state.information,
            bankData: {
              ...state.information.bankData,
              configFormData: action.payload,
            },
          },
        };
      }

      case actionTypes.setWalletData: {
        return {
          ...state,
          information: {
            ...state.information,
            walletsData: [...action.payload],
          },
        };
      }

      case actionTypes.setCurrenciesData: {
        return { ...state, currencies: action.payload };
      }

      default:
        return state;
    }
  },
);

export const actions = {
  requesDomicilial: (data) => ({
    type: actionTypes.getDomicilialData,
    payload: { data },
  }),
  requesBank: (data) => ({
    type: actionTypes.getBankData,
    payload: { data },
  }),
  requestBankConfigFormData: (data) => ({
    type: actionTypes.getBankConfigFormData,
    payload: { data },
  }),
  setDomicilialData: (data) => ({
    type: actionTypes.setDomicilialData,
    payload: data,
  }),
  setBankData: (data) => ({
    type: actionTypes.setBankData,
    payload: data,
  }),
  setBankConfigFormData: (data) => ({
    type: actionTypes.setBankConfigFormData,
    payload: data,
  }),
  requesCurrencies: (data) => ({
    type: actionTypes.getCurrenciesData,
    payload: { data },
  }),
  setCurrenciesData: (data) => ({
    type: actionTypes.setCurrenciesData,
    payload: data,
  }),
  requesWallets: (data) => ({
    type: actionTypes.getWalletData,
    payload: { data },
  }),
  setWalletsData: (data) => ({
    type: actionTypes.setWalletData,
    payload: data,
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.getDomicilialData,
    function* getDomicilialData() {
      const {
        data: { data },
      } = yield getDomicilialInfo();

      if (data) {
        const formatedData = buildFiscalData(data);
        yield put(actions.setDomicilialData(formatedData));
      }
    },
  );

  yield takeLatest(actionTypes.getBankData, function* getBankData() {
    const {
      data: { data },
    } = yield getBankInfo();

    if (data) {
      const formatedData = buildFiscalData(data);
      yield put(actions.setBankData(formatedData));

      if (!data.json_data || data.json_data?.length === 0) {
        yield put(actions.requestBankConfigFormData());
      } else {
        yield put(actions.setBankConfigFormData([]));
      }
    }
  });

  yield takeLatest(
    actionTypes.getBankConfigFormData,
    function* getBankConfigFormData() {
      const {
        data: { data },
      } = yield getConfigForms({
        resource: 'bank',
        country: 'common',
      });

      yield put(actions.setBankConfigFormData(data));
    },
  );

  yield takeLatest(actionTypes.getWalletData, function* getWalletData() {
    const {
      data: { data },
    } = yield getUserWallets();

    if (data) {
      const rowsData = data.map((item) => ({
        id: item.id,
        red: item.crypto_network,
        moneda: item.currency_id,
        direccion: item.address,
      }));
      yield put(actions.setWalletsData(rowsData));
    }
  });

  yield takeLatest(
    actionTypes.getCurrenciesData,
    function* getCurrenciesData() {
      const {
        data: { data },
      } = yield getWalletsAvailables();

      if (data) {
        yield put(actions.setCurrenciesData(data));
      }
    },
  );
}
