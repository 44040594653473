import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Box,
  TextField,
  DialogActions,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import CardWithLoadingProgress from '../CardWithLoadingProgress';
import QrCodeGenerator from '../QrCodeGenerator';

import {
  startAssociation,
  finishAssociation,
} from '../../../services/merchantsService';
import { useLazyRequest } from '../../hooks';
import { toAbsoluteUrl } from '../../../utils';
import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';
import { ERRORS_MESSAGE } from '../../../constants/errorMessage';
import * as auth from '../../modules/Auth/_redux/authRedux';

import styles from './styles';

const useStyles = makeStyles(styles);

const initialValues = {
  securityCode: '',
};

const validationSchema = object().shape({
  securityCode: string().min(6).required(),
});

const twoFAAppRecomended = {
  imageUrl: toAbsoluteUrl('/assets/images/google-autenticator.jpg'),
  alt: 'Google Autenticator',
  stores: {
    appStore: {
      imageUrl: toAbsoluteUrl('/assets/icons/ic_app_store.svg'),
      alt: 'App Store',
      link: 'https://apps.apple.com/ar/app/google-authenticator/id388497605',
    },
    playStore: {
      imageUrl: toAbsoluteUrl('/assets/icons/ic_google_play.svg'),
      alt: 'Play Store',
      link:
        // eslint-disable-next-line max-len
        'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=es_419&gl=US',
    },
  },
};

const DialogTwoFactorAutentication = ({ open, onClose, requestUser }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const [
    strQr,
    loadingStartAssociation,
    ,
    executeStartAssociation,
  ] = useLazyRequest({
    initialState: '',
    request: startAssociation,
    transformResponse: ({ data }) => data.otpauth,
    withPostFailure: () => {
      enqueueSnackbar(ERRORS_MESSAGE.notFoundRetryLater, {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    if (open) {
      executeStartAssociation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [, loadingFinsh, , executeFinshAsscociation] = useLazyRequest({
    request: finishAssociation,
    withPostSuccess: () => {
      enqueueSnackbar(ERRORS_MESSAGE.action(t('settings.tokenActiveSuccess')), {
        variant: 'success',
      });

      requestUser();
      onClose();
    },
    withPostFailure: (error) => {
      if (error.status === 401) {
        enqueueSnackbar(t('settings.invalidCode'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(ERRORS_MESSAGE.action(t('settings.tokenActiveError')), {
          variant: 'error',
        });
      }
    },
  });

  const { handleSubmit, getFieldProps, isValid, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => executeFinshAsscociation(values),
    validateOnMount: true,
  });

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="dialog-2fa-autentication-title"
      open={open}
      keepMounted
      PaperProps={{
        component: CardWithLoadingProgress,
        loading: loadingStartAssociation,
        fullHeight: loadingStartAssociation,
      }}
      classes={{ paperWidthFalse: classes.dialogPaperContainer }}
      maxWidth="false"
      scroll="body"
    >
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle
          id="dialog-2fa-autentication-title"
          disableTypography
          className={classes.dialogTitleContainer}
        >
          <Typography component="h6" className={classes.dialogTitleTypography}>
            {t(`settings.modal2faTitle`)}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
            size="small"
          >
            <CloseIcon color="secondary" fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContentContainer}>
          <Typography paragraph variant="body2">
            {t(`settings.modal2faDescription1`)}
          </Typography>

          <Box className={classes.containerImages}>
            <Box
              component="img"
              src={twoFAAppRecomended.imageUrl}
              alt={twoFAAppRecomended.alt}
              className={classes.imgAppRecomend}
            />

            <Box className={classes.storesContainer}>
              <a
                href={twoFAAppRecomended.stores.appStore.link}
                target="_blank"
                rel="noreferrer"
              >
                <Box
                  component="img"
                  src={twoFAAppRecomended.stores.appStore.imageUrl}
                  alt={twoFAAppRecomended.stores.appStore.alt}
                  className={classes.imgStore}
                />
              </a>

              <a
                href={twoFAAppRecomended.stores.playStore.link}
                target="_blank"
                rel="noreferrer"
              >
                <Box
                  component="img"
                  src={twoFAAppRecomended.stores.playStore.imageUrl}
                  alt={twoFAAppRecomended.stores.playStore.alt}
                  className={classes.imgStore}
                />
              </a>
            </Box>
          </Box>

          <Typography paragraph variant="body2">
            {t(`settings.modal2faDescription2`)}
          </Typography>

          <QrCodeGenerator
            value={strQr}
            size={130}
            className={classes.imgQrApp}
          />

          <Typography paragraph variant="body2">
            {t(`settings.modal2faDescription3`)}
          </Typography>

          <TextField
            variant="outlined"
            placeholder={t(`settings.modal2faSecurityCode`)}
            inputProps={{ maxLength: 6 }}
            autoComplete="off"
            {...getFieldProps('securityCode')}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActionsContainer}>
          <ButtonWithCirculaProgress
            type="submit"
            color="secondary"
            fullWidth
            disabled={!isValid}
            loading={loadingFinsh}
            label={t(`settings.modal2faSend`)}
            className={classes.sendButton}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

DialogTwoFactorAutentication.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  requestUser: PropTypes.func.isRequired,
};

export default connect(null, auth.actions)(DialogTwoFactorAutentication);
