import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Box,
  TextField,
  DialogActions,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import { actions as authActions } from '../../modules/Auth/_redux/authRedux';

import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';
import * as auth from '../../modules/Auth/_redux/authRedux';

import styles from './styles';

const useStyles = makeStyles(styles);

const initialValues = {
  securityCode: '',
};

const validationSchema = object().shape({
  securityCode: string().min(6).required(),
});

const TwoAuthModal = ({ open, onClose, loadingFinsh, exceuteSubmit }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSendData = ({ securityCode }) => {
    if (securityCode) {
      dispatch(authActions.SetSecurityCode(securityCode));
      exceuteSubmit();
    } else {
      dispatch(authActions.SetSecurityCode(null));
    }
  };

  const { handleSubmit, getFieldProps, isValid, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => onSendData(values),
    validateOnMount: true,
  });

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  useEffect(() => {
    if (!open) {
      dispatch(authActions.SetSecurityCode(null));
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="dialog-2fa-autentication-title"
      open={open}
      keepMounted
      classes={{ paperWidthFalse: classes.dialogPaperContainer }}
      maxWidth={false}
      scroll="body"
    >
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle
          id="dialog-2fa-autentication-title"
          disableTypography
          className={classes.dialogTitleContainer}
        >
          <Typography component="h6" className={classes.dialogTitleTypography}>
            {t(`2fa.title`)}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
            size="small"
          >
            <CloseIcon color="secondary" fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContentContainer}>
          <Typography paragraph variant="body2">
            {t(`2fa.note`)}
          </Typography>

          <Typography paragraph variant="body2">
            {t(`2fa.securityCode`)}
          </Typography>
          <TextField
            variant="outlined"
            placeholder={t(`2fa.securityCode`)}
            fullWidth
            inputProps={{ maxLength: 6 }}
            autoComplete="off"
            {...getFieldProps('securityCode')}
          />

          <Typography paragraph variant="body2" className={classes.marginTop}>
            {t(`2fa.learnTo`)}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActionsContainer}>
          <ButtonWithCirculaProgress
            type="submit"
            color="secondary"
            fullWidth
            disabled={!isValid}
            loading={loadingFinsh}
            label={t(`2fa.send`)}
            className={classes.sendButton}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

TwoAuthModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  loadingFinsh: PropTypes.bool.isRequired,
  exceuteSubmit: PropTypes.func.isRequired,
};

export default connect(null, auth.actions)(TwoAuthModal);
