export default (theme) => ({
  root: {
    background: theme.palette.background.gradient.primary,
    color: theme.palette.common.white,
    padding: theme.spacing(5, 18.75),
    maxWidth: 1440,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),

    [theme.breakpoints.down(1025)]: {
      padding: theme.spacing(2, 8.75),
    },

    [theme.breakpoints.down(769)]: {
      padding: theme.spacing(2, 7.5),
    },

    [theme.breakpoints.down(481)]: {
      gap: theme.spacing(2),
      padding: theme.spacing(2, 3.75),
    },

    [theme.breakpoints.down(376)]: {
      padding: theme.spacing(2, 1.875),
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),

    [theme.breakpoints.down(481)]: {
      gap: theme.spacing(2),
    },
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),

    [theme.breakpoints.down(481)]: {
      gap: theme.spacing(2),
    },
  },
  rowSmallContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  columnSmallContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  containerWithOutSpace: {
    display: 'flex',
    flexDirection: 'row',
  },
  columnContainerWithOutSpace: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerCenter: {
    alignItems: 'center',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  input: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: theme.spacing(1),

    '& input': {
      border: '1px solid #707070',
      borderRadius: 20,
      padding: 11,
      position: 'relative',
      width: '100%',
      backgroundColor: theme.palette.common.white,
    },
  },
  utilsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(4),

    [theme.breakpoints.down(1025)]: {
      flexDirection: 'column',
    },

    [theme.breakpoints.down(481)]: {
      gap: theme.spacing(2),
    },
  },
  utilsInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(4),

    [theme.breakpoints.down(1025)]: {
      '& > div': {
        width: '50%',
      },
    },

    [theme.breakpoints.down(481)]: {
      flexDirection: 'column',
      gap: theme.spacing(2),

      '& > div': {
        width: '100%',
        textAlign: 'center',
      },
    },
  },
  utilsContactRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),

    [theme.breakpoints.down(1025)]: {
      flexDirection: 'column-reverse',
    },

    [theme.breakpoints.down(481)]: {
      gap: theme.spacing(1),
    },
  },
  utilsRrrssContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',

    [theme.breakpoints.down(1025)]: {
      alignItems: 'flex-end',

      '& > div': {
        width: 'calc(50% - 16px)',
      },
    },

    [theme.breakpoints.down(481)]: {
      alignItems: 'center',
      textAlign: 'center',

      '& > div': {
        width: 'auto',
      },
    },
  },
  divider: {
    backgroundColor: '#C1C1C1',
  },
  legalContainer: {
    alignItems: 'center',
    position: 'relative',
  },
  afipLink: {
    bottom: 0,
    left: 0,
    position: 'absolute',
  },
  afipImg: {
    height: 75,
    width: 55,

    [theme.breakpoints.down(481)]: {
      height: 56,
      width: 41,
    },
  },
  copyright: {
    [theme.breakpoints.down(481)]: {
      textAlign: 'center',
      maxWidth: '80%',
    },
  },
  privacyContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),

    [theme.breakpoints.down(481)]: {
      gap: theme.spacing(1),
      flexDirection: 'column',
    },
  },
});
