import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import cn from 'classnames';

import { PATHS } from '../../../constants/paths';
import CircularProgress from '../CircularProgress';
import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';
import { useRequest, useLazyRequest } from '../../hooks';
import {
  getSettingsInfo,
  getQRURL,
  previewReq as previewRequest,
} from '../../../services/merchantsService';
import { ERRORS_MESSAGE } from '../../../constants/errorMessage';

import styles from './styles';

const useStyles = makeStyles(styles);

const ActionsWallet = ({ className }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [{ data: settingsInfo }, loadingSettingsInfo] = useRequest(
    {
      initialState: {},
      request: getSettingsInfo,
      transformResponse: ({ data, state }) => ({
        data,
        state,
      }),
      withPostFailure: () => {
        enqueueSnackbar(
          ERRORS_MESSAGE.actionRequestSuccess('no se pudo obtener'),
          {
            variant: 'error',
          },
        );
      },
    },
    [],
  );

  const [{ url: generatePaymentUrl }, loadingGeneratePaymentUrl] = useRequest(
    {
      initialState: {},
      request: getQRURL,
      transformResponse: ({ data }) => data,
      withPostFailure: () => {
        enqueueSnackbar(
          ERRORS_MESSAGE.actionRequestSuccess('No se pudo obtener la URL'),
          {
            variant: 'error',
          },
        );
      },
    },
    [],
  );

  const loadingDataRequest = useMemo(
    () => loadingSettingsInfo || loadingGeneratePaymentUrl,
    [loadingSettingsInfo, loadingGeneratePaymentUrl],
  );

  const hasInfoGeneratePayment = useMemo(
    () =>
      !isEmpty(settingsInfo?.email) &&
      !isEmpty(settingsInfo?.name) &&
      !isEmpty(settingsInfo?.reference),
    [settingsInfo],
  );

  const [, loadingPreviewReq, , previewReq] = useLazyRequest({
    request: previewRequest,
    withPostSuccess: () => window.open(generatePaymentUrl, '_blank'),
    withPostFailure: () =>
      enqueueSnackbar(
        ERRORS_MESSAGE.actionRequestError(
          'No es posible previsualizar su configuración',
        ),
        { variant: 'error' },
      ),
  });

  const executePreviewReq = useCallback(async () => previewReq(), [previewReq]);

  return (
    <Box className={cn(classes.container, className)}>
      {loadingDataRequest ? (
        <CircularProgress disableShrink={false} />
      ) : (
        <>
          <Button
            variant="contained"
            color="secondary"
            component={RouterLink}
            to={{
              pathname: PATHS.profile.path,
              search: 'openCreateModal=true',
            }}
          >
            {t(`wallet.createWallet`)}
          </Button>

          {hasInfoGeneratePayment && (
            <ButtonWithCirculaProgress
              handleButtonClick={executePreviewReq}
              variant="outlined"
              color="secondary"
              disabled={Boolean(!generatePaymentUrl)}
              loading={loadingPreviewReq}
              label={t(`wallet.generatePayment`)}
            />
          )}
        </>
      )}
    </Box>
  );
};

ActionsWallet.propTypes = {
  className: PropTypes.string,
};

ActionsWallet.defaultProps = {
  className: null,
};

export default ActionsWallet;
