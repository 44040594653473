export default (theme) => {
  const fontSizeIcon = theme.typography.pxToRem(28);
  const marginIcon = theme.spacing(2);

  return {
    iconTitleSection: {
      color: theme.palette.secondary.main,
      fontSize: fontSizeIcon,
      marginRight: marginIcon,
    },
    typographyTitleSection: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(14),
    },
    boxWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: 150,
      marginLeft: theme.spacing(6),

      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
      },
    },
    title: {
      marginBottom: 50,
    },
  };
};
