const defaultStyles = {
  headRow: {
    style: {
      minHeight: 'auto',
      border: '1px solid #F0901E',
      borderRadius: '20px',
    },
  },
  headCells: {
    style: {
      color: '#9F9F9F',
      fontSize: '14px',
      padding: '8px',
      justifyContent: 'center',

      '&:not(:first-child):after': {
        content: '""',
        width: '1px',
        height: '80%',
        position: 'absolute',
        left: '1px',
        background: '#F0901E',
      },
    },
  },
  cells: {
    style: {
      padding: '8px',
      justifyContent: 'center',

      '@media (max-width: 599.95px)': {
        '& > :first-child': {
          minWidth: '90%',
        },
      },
    },
  },
  rows: {
    style: {
      color: '#9F9F9F',
      minHeight: 'auto',
      fontSize: '14px',
      fontWeight: 400,

      '&:not(:last-of-type)': {
        border: 'none',
      },
    },
  },
};

export default defaultStyles;
