import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause21 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        21. Propiedad Intelectual
      </Typography>
      <Typography className={classes.text}>
        KriptonMarket es titular o licenciataria de todos los derechos de
        propiedad intelectual e industrial incluidos en la Plataforma así como
        sobre los contenidos accesibles a través de la misma. <br />
        Los derechos de propiedad intelectual de la Plataforma, así como:
        textos, imágenes, diseño gráfico, estructura de navegación, información
        y contenidos que se recogen en ella son titularidad de KriptonMarket, a
        quien corresponde el ejercicio exclusivo de los derechos de explotación
        de los mismos en cualquier forma y, en especial, los derechos de
        reproducción, distribución, comunicación pública y transformación, de
        conformidad con la legislación española de derechos de propiedad
        intelectual e industrial. <br />
        La autorización al Usuario, o Cliente, para el acceso a la Plataforma no
        supone renuncia, transmisión, licencia o cesión total ni parcial sobre
        derechos de propiedad intelectual o industrial por parte de
        KriptonMarket. No está permitido suprimir, eludir o manipular de ningún
        modo los contenidos de la Plataforma de KriptonMarket. Asimismo está
        prohibido modificar, copiar, reutilizar, explotar, reproducir, comunicar
        públicamente, hacer segundas o posteriores publicaciones, cargar
        archivos, enviar por correo, transmitir, usar, tratar o distribuir de
        cualquier forma la totalidad o parte de los contenidos incluidos en la
        Plataforma de KriptonMarket para propósitos públicos o comerciales, si
        no se cuenta con la autorización expresa y por escrito de KriptonMarket
        o, en su caso, del titular de los derechos a que corresponda. <br />
        El Usuario, o Cliente, que proceda a compartir cualquier tipo de
        contenido a través de la Plataforma, asegura que ostenta los derechos
        necesarios para hacerlo, quedando exento KriptonMarket de cualquier
        responsabilidad sobre el contenido y legalidad de la información
        ofrecida. La facilitación de contenidos por parte de los Clientes, a
        través de la Plataforma, supondrá la cesión a KriptonMarket, con
        carácter gratuito, y con la máxima amplitud permitida por la legislación
        vigente, de los derechos de explotación de propiedad intelectual o
        industrial derivados de tales contenidos.
      </Typography>
    </Box>
  );
};

export default Clause21;
