import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause10 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        10. Condiciones especiales del servicio de encargo de compra de
        productos a través de KriptonMarket
      </Typography>
      <Typography className={classes.text}>
        El Usuario tiene la opción de solicitar a través de la Plataforma la
        adquisición presencial por parte del Kriptonian de una serie de
        productos y/o servicios que son ofrecidos por comercios con los que
        KriptonMarket mantiene un acuerdo comercial. El Usuario puede
        seleccionar mediante un listado dentro de la Plataforma, una serie de
        opciones predeterminadas por los Comercios en donde se indica las
        características, el precio, así como en ocasiones, una fotografía del
        producto o servicio. <br />
        Las características, el precio, así como en ocasiones, una fotografía
        del producto o servicio. Una vez el Cliente seleccione una de las
        opciones, podrá completar además el pedido mediante un espacio de texto
        libre para incorporar información más detallada o instrucciones para el
        Kriptonian que se encargue de llevar a cabo dicho pedido. <br />
        A través del espacio libre, el Usuario manifiesta su voluntad de
        encargar la compra de determinados productos, resultando el Kriptonian
        un mero mandatario por cuenta de este. Como consecuencia de ello, sería
        el usuario el único responsable y quién, llegado el caso, asumiría
        cualquier incidencia derivada de la naturaleza de dichos productos (i.e.
        sanción, responsabilidad civil, responsabilidad patrimonial...). <br />
        El Usuario es consciente y acepta que las descripciones y, en su caso,
        los precios o las fotografías ilustrativas de los productos y/o
        servicios ofertados en la Plataforma se realizan, en base a la
        información y documentación proporcionada por los Comercios, por lo que
        KriptonMarket no puede ofrecer garantías frente a posibles inexactitudes
        en dichas descripciones, precios y/o fotografías. <br />
        Asimismo, el Usuario acepta que todos los productos reflejados en la
        plataforma de KriptonMarket están sujetos a la disponibilidad de los
        mismos y en este sentido asume la posibilidad de que durante la
        realización del pedido se encuentre con la no disponibilidad del
        producto y/o servicio en el Comercio. Adicionalmente, el precio del
        producto puede variar ligeramente a causa de modificaciones en el punto
        de venta. KriptonMarket se reserva el derecho de proceder con la compra
        ante variaciones en el precio de hasta un 30%, si la variación supera el
        citado 30% el Kriptonian se pondrá en contacto con el cliente para
        transmitirle la situación. KriptonMarket se reserva el derecho a retirar
        cualquier producto de su plataforma, así como de modificar el contenido
        de su ficha en cualquier momento, sin que le pueda ser atribuido ningún
        tipo de responsabilidad.
      </Typography>

      <Typography component="h4" className={classes.textBold}>
        10.1 Entregas en el mismo día
      </Typography>
      <Typography className={classes.text}>
        KriptonMarket proporciona al Usuario un servicio de entrega de los
        productos adquiridos presencialmente por el Kriptonian en determinadas
        ciudades. El pedido se podrá realizar siempre que se cumplan las
        siguientes condiciones en el momento de pago del pedido:
      </Typography>
      <Typography className={classes.text}>
        - El servicio se encuentre operativo para la franja horaria escogida.
        <br />
        - La mercancía a entregar debe encontrarse dentro de alguna de las
        ciudades y dentro del espacio habilitado en las que opere KriptonMarket.
        <br />
        - El lugar de destino debe estar situado en la misma ciudad en la que se
        encuentre el producto.
        <br />
        - El tamaño del pedido no puede superar las medidas: 40cm x 40cm x 30cm.
        <br />
        - El peso máximo a cargar en un pedido será, aproximadamente, de 9 Kg.
        <br />
        - En el caso de productos que no cumplan con dichos requisitos, el
        retiro del mismo será acordado entre Cliente oferente y Usuario, quienes
        serán puestos en contacto por KriptonMarket.
        <br />
      </Typography>

      <Typography component="h4" className={classes.textBold}>
        10.2 Precio y método de pago
      </Typography>
      <Typography className={classes.text}>
        El precio del producto y/o servicio será el indicado por el Comercio a
        través de la Plataforma. Sin embargo, el Usuario asume que en todo caso
        la valoración económica de algunos de los productos podrá variar en
        tiempo real, debido a las disponibilidades de stock de los
        establecimientos que se ofertan en la plataforma, y que en todo caso el
        costo final será siempre comunicado al Usuario de forma previa al
        momento de pago. <br />
        De igual forma, durante la pasarela de pago, el Usuario será informado
        del precio final del envío y la hora aproximada de entrega según las
        condiciones del servicio de recadería de KriptonMarket expresadas más
        arriba. <br />
        El Usuario realizará el pago de los servicios en BitCoins, a través de
        XXX. La información completa será almacenada en los servidores de XXX,
        prestador de servicios de pago que realiza los pagos en nombre de
        KriptonMarket. Todo ello de acuerdo con los términos de la plataforma de
        pago expuestos en la Cláusula 7.1 y los términos acordados en de la
        Cláusula 4.
      </Typography>

      <Typography component="h4" className={classes.textBold}>
        10.3. Envío de muestras gratuitas a domicilio y otras acciones
        comerciales
      </Typography>
      <Typography className={classes.text}>
        KriptonMarket se reserva el derecho a realizar acuerdos comerciales con
        sus Clientes a los efectos de realizar comunicaciones promocionales,
        incluyendo el envío de muestras gratuitas a domicilio junto al pedido
        solicitado por el Usuario. Dichas acciones comerciales deberán aceptarse
        explícitamente junto a las presentes condiciones de Uso por los
        Usuarios.
      </Typography>
    </Box>
  );
};

export default Clause10;
