import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Typography, makeStyles, ClickAwayListener } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { AVAILABLE_COUNTRIES } from '../../../constants/availablesCountries';
import { actions as authActions } from '../../modules/Auth/_redux/authRedux';
import { toAbsoluteUrl } from '../../../utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const CountryDropdown = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const dispatch = useDispatch();
  const { currentCountry } = useSelector((state) => state.auth);

  const [openMenu, setOpenMenu] = useState(false);
  const handleToggleMenu = useCallback(() => setOpenMenu((prev) => !prev), []);

  const handleChangeCountry = useCallback(
    (country) => {
      dispatch(authActions.setCurrentCountry(country));
      handleToggleMenu();
    },
    [dispatch, handleToggleMenu],
  );

  return (
    <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
      <div className={classnames(classes.menuContainer)}>
        <button
          type="button"
          className={classes.btnMenu}
          onClick={handleToggleMenu}
        >
          <img
            src={toAbsoluteUrl('/assets/icons/world-purple.svg')}
            alt="Country"
            className={classes.iconContainer}
          />

          <span className={classes.btnText}>{t('profile.country')}</span>

          <span className={classes.btnArrow}>&#9656;</span>
        </button>

        {openMenu && (
          <nav className={classnames(classes.countryMenu)}>
            <div className={classes.countryMenuItems}>
              {AVAILABLE_COUNTRIES.map(({ label, value: country, urlImg }) => (
                <div
                  className={classes.countryMenuElementContent}
                  key={`country-menu-element-${country}`}
                >
                  <button
                    type="button"
                    className={classnames(classes.countryMenuElementBtn, {
                      [classes.countryMenuElementBtnSelected]:
                        currentCountry === country,
                    })}
                    onClick={() => handleChangeCountry(country)}
                  >
                    <img
                      src={urlImg}
                      alt={label}
                      className={classes.countryMenuElementIcon}
                    />

                    <Typography
                      className={classes.countryMenuElementText}
                      color="inherit"
                    >
                      {label}
                    </Typography>
                  </button>
                </div>
              ))}
            </div>
          </nav>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default CountryDropdown;
