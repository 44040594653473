import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause2 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        2. Términos de Uso
      </Typography>
      <Typography className={classes.text}>
        El acceso a la Plataforma para la carga de productos o servicios con
        fines de venta y/o la adquisición de los mismos implica el conocimiento
        y la aceptación expresa de las presentes Condiciones Generales y la
        Política de Privacidad por parte de todos los clientes y usuarios.
      </Typography>
    </Box>
  );
};

export default Clause2;
