import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import cn from 'classnames';

import styles from './styles';

const useStyles = makeStyles(styles);

const TitleCards = ({ title, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Typography
      className={cn(classes.title, className)}
      component="h2"
      {...rest}
    >
      {title}
    </Typography>
  );
};

TitleCards.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TitleCards.defaultProps = {
  className: null,
};

export default TitleCards;
