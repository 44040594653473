/* eslint-disable max-len */
import { toAbsoluteUrl } from '../../../utils';

export const OUR_PROPOSAL = [
  {
    id: 'proposal-1',
    iconUrl: toAbsoluteUrl('/assets/icons/ic_nut.svg'),
    title: 'benefitsTitle1',
    description: 'benefitsDescription1',
  },
  {
    id: 'proposal-2',
    iconUrl: toAbsoluteUrl('/assets/icons/ic_clock.svg'),
    title: 'benefitsTitle2',
    description: 'benefitsDescription2',
  },
  {
    id: 'proposal-3',
    iconUrl: toAbsoluteUrl('/assets/icons/ic_cloud.svg'),
    title: 'benefitsTitle3',
    description: 'benefitsDescription3',
  },
  {
    id: 'proposal-4',
    iconUrl: toAbsoluteUrl('/assets/icons/ic_lock.svg'),
    title: 'benefitsTitle4',
    description: 'benefitsDescription4',
  },
  {
    id: 'proposal-5',
    iconUrl: toAbsoluteUrl('/assets/icons/ic_lens.svg'),
    title: 'benefitsTitle5',
    description: 'benefitsDescription5',
  },
  {
    id: 'proposal-6',
    iconUrl: toAbsoluteUrl('/assets/icons/ic_circle_check.svg'),
    title: 'benefitsTitle6',
    description: 'benefitsDescription6',
  },
];
