import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause1 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        1. OBJETO
      </Typography>
      <Typography className={classes.text}>
        KriptonMarket es una compañía tecnológica cuya actividad principal es
        facilitar la utilización de criptomonedas para la adquisición de ciertos
        bienes o servicios disponibles en una localidad. Además, como actividad
        paralela, KriptonMarket brinda un servicio de anuncios clasificados para
        la colocación de bienes registrables y servicios de profesionales y/o
        autónomos. Para lo antes dicho, KriptonMarket, se dedica al desarrollo,
        y gestión, de una plataforma tecnológica mediante la que, a través de
        una página web (en adelante, la “Plataforma”), permite las
        transacciones, en ciertas regiones, entre ciertos ofertantes locales de
        productos, o servicios, posibles contratantes de KriptonMarket, (en
        adelante, “Clientes”) y compradores/contratantes de dichos bienes (en
        adelante denominados “Usuarios”), a perfeccionar el intercambio de los
        mismos, en valor pesos al momento de la transacción, mediante el empleo
        de criptomoneda. Adicionalmente, KriptonMarket, proporciona el contacto,
        a los Usuarios y Clientes, con una red profesionales independientes,
        mensajeros y/o gestores, (en adelante denominados Kriptonianians) que,
        voluntariamente, realizan la tarea de conseguir y/o transportar el
        producto solicitado por el Usuario al destino que el mismo indique.
      </Typography>
      <Typography className={classes.text}>
        Los Kriptonianians, por medio del enlace proporcionado por KriptonMarket
        entre ellos y los Usuarios, también podrán realizar mandados, mediante
        una remuneración pautada entre los mismos, que, si bien se encuentren
        dentro del ámbito local, no se hallen ofertados por los Clientes. Así,
        KriptonMarket, cuenta con una Plataforma mediante la cual diferentes
        comercios y profesionales, con los que puede mantener un acuerdo
        comercial por el uso de la plataforma, ofrecen una serie de productos
        y/o servicios. El Usuario tiene la posibilidad de solicitar la
        adquisición de productos y/o servicios de estos comercios mediante el
        mandato que confiere a un tercero al solicitar un pedido a través de la
        Plataforma, en cuyos casos, KriptonMarket, actúa como mero intermediario
        y por lo tanto, no puede asumir ni asume responsabilidad alguna sobre la
        calidad de los productos o la correcta prestación de los servicios
        ofrecidos directamente por los comercios ni por dichos terceros. Además,
        KriptonMarket, ofrece a los Clientes asesoramiento en el uso de
        Criptodivisas.
      </Typography>
    </Box>
  );
};

export default Clause1;
