import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause4 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        4. Condiciones de la operación
      </Typography>
      <Typography className={classes.text}>
        El valor de los productos y servicios está expresado en moneda de curso
        legal del lugar en el que se realice la transacción.
      </Typography>
      <Typography className={classes.text}>
        Existen dos modos posibles de realizar una operación en la Plataforma.
        Mediante la API de Bitex o mediante la gestión directa de un Krptonian.
      </Typography>

      <Typography component="h4" className={classes.textBold}>
        4.1 Bitcoin dentro de la Plataforma a través del API de Bitex.
      </Typography>
      <Typography className={classes.text}>
        Para emplear este método, la transacción deberá superar el monto mínimo
        solicitado por Bitex. Al momento de indicar la compra en la Plataforma,
        el Usuario tendrá 3500 segundos para completar sus datos, durante los
        cuales la cotización de Bitcoins a Moneda de Curso Legal se mantendrá
        congelada, una vez pasado dicho tiempo los valores se actualizarán de
        acuerdo al mercado y el Cliente contará con un periodo de tiempo similar
        al anterior antes de la próxima actualización. Cada uno de dichos lapsos
        de tiempo transcurridos desde una actualización hasta la siguiente se
        denominan “ciclos”. Este proceso de actualización se detendrá con el
        valor que existiera en el ciclo correspondiente a la pulsación del botón
        que indica el pago de la transacción. En caso de desistir de la compra,
        el Usuario, podrá pulsar el botón de “Cancelar Compra”, en dicho caso
        todos los datos de la transacción desistida se eliminarán de la
        Plataforma.
      </Typography>
      <Typography className={classes.text}>
        El valor, expresado en Moneda de Curso Legal, de los Bitcoins empleados
        para la transacción será el correspondiente al ciclo en el que se
        perfeccionara la transacción. <br />
        El Usuario acepta que, al realizarse una conversión a moneda de curso
        legal del monto ingresado de Bitcoins, cualquier reclamo que realice se
        limita al total del valor expresado en Moneda de Curso Legal.
        Opcionalmente, el reclamante podrá solicitar un monto de Bitcoins
        similar a la conversión, al momento del reclamo, del valor en Moneda de
        Curso Legal. En ningún caso se podrá reclamar la devolución del monto
        preciso de Bitcoins empleados al momento de perfeccionar la transacción.
        <br />
        La operación de transferencia de Bitcoins se realiza por Bitex, servicio
        otorgado por terceros del que KriptonMarket no se hace responsable. Para
        leer las condiciones de servicio de Bitex dirigirse a:{' '}
        <a
          href="https://bitex.la/terms_and_conditions.pdf"
          target="_blank"
          rel="noreferrer"
        >
          https://bitex.la/terms_and_conditions.pdf
        </a>
      </Typography>

      <Typography component="h4" className={classes.textBold}>
        4.2 Transacción por intermedio de Kriptonians
      </Typography>
      <Typography className={classes.text}>
        No hay monto mínimo en las transacciones realizadas mediante este
        método. La misma se inicia al presionar el botón “Contactar un
        Kriptonian”, negociándose mediante chat la transacción y divisa, siendo
        posible el empleo de criptomonedas distintas al Bitcoin. La cotización y
        compra de la criptomoneda empleada será efectuada por un Kriptonian
        calificado de broker. En caso de devolución de montos, la misma se
        realizará en el valor de Moneda de Curso Legal del producto o servicio
        al momento de cerrada la transacción.
      </Typography>
    </Box>
  );
};

export default Clause4;
