import { useState, useEffect } from 'react';

export const useFetchDataMockDelay = ({
  mock,
  initialState = {},
  delay = 3000,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialState);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setData(mock);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay]);

  return [loading, data];
};
