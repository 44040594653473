export default () => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: 'white',
    border: '1px solid transparent',
    color: 'black',
    width: 37,
    height: 37,
    boxShadow: '0px 3px 6px #00000029',

    '&:diabled': {
      opacity: 0.5,
      borderColor: 'lightGray',
    },
  },
  pageItem: {
    marginLeft: 25,
    marginRight: 25,
    color: '#9F9F9F',
  },
  buttonRight: {
    transform: 'rotate(180deg)',
    color: '#000000',
  },
  buttonLeft: {
    color: '#000000',
  },
  iconDisabled: {
    color: '#00000029',
  },
});
