import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Hidden, useMediaQuery, Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import TableList from '../TableList';
import TableListMobile from '../TableListMobile';
import DialogDetailsOperation from '../DialogDetailsOperation';

import { useDataTableRequest, useDialogToggleWithData } from '../../hooks';
import { getOperations } from '../../../services/merchantsService';
import { getStatusDescription } from '../../../utils';

const OperationsTable = ({ type }) => {
  const isdDekstop = useMediaQuery((theme) => theme.breakpoints.up('xl'), {
    noSsr: true,
  });
  const { t } = useTranslation('common');

  const initialFilters = useMemo(
    () => ({
      filter: {},
      sortOrder: 'desc',
      sortField: 'created_at',
      pageNumber: 1,
      type,
      pageSize: !isdDekstop ? 4 : 10,
    }),
    [isdDekstop, type],
  );

  const [
    loading,
    data,
    count,
    ,
    { pageSize, pageNumber },
    { handlePageChange, handlePerRowsChange },
  ] = useDataTableRequest({
    request: getOperations,
    payload: initialFilters,
    isMobile: !isdDekstop,
  });

  const [
    openDialog,
    selectedRow,
    handleOpenDialog,
    handleCloseDialog,
  ] = useDialogToggleWithData();

  const columns = useMemo(
    () => [
      {
        name: t('tableColumns.id'),
        selector: 'id',
        sortable: false,
      },
      {
        name: t('tableColumns.user'),
        selector: 'client',
        format: (row) => row?.username ?? '',
        sortable: false,
      },
      {
        name: t('tableColumns.currencyCollection'),
        selector: 'currency_in',
        sortable: false,
      },
      {
        name: t('tableColumns.currencyDelivery'),
        selector: 'currency_out',
        sortable: false,
      },
      {
        name: t('tableColumns.%'),
        selector: 'commission.percentage',
        sortable: false,
      },
      {
        name: t('tableColumns.status'),
        selector: 'status',
        format: (row) => getStatusDescription(row.status, 'cash-in'),
        sortable: false,
      },
      {
        name: t('tableColumns.dateStart'),
        selector: 'created_at',
        format: (row) => moment(row.created_at).format('DD/MM/YYYY'),
        sortable: false,
      },
      {
        name: t('tableColumns.lastModification'),
        selector: 'updated_at',
        format: (row) => moment(row.updated_at).format('DD/MM/YYYY'),
        sortable: false,
      },
      {
        cell: (row) => (
          <Button
            variant="text"
            color="secondary"
            onClick={() => handleOpenDialog(row)}
          >
            {!isdDekstop ? (
              t('tableColumns.seeDetails')
            ) : (
              <AddIcon color="action" />
            )}
          </Button>
        ),
        button: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleOpenDialog, isdDekstop],
  );

  return (
    <>
      <Hidden smDown>
        <TableList
          data={data}
          columns={columns}
          perPage={pageSize}
          loading={loading}
          totalRows={count}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
        />
      </Hidden>
      <Hidden mdUp>
        <TableListMobile
          data={data}
          columns={columns}
          pageNumber={pageNumber}
          loading={loading}
          totalRows={count}
          handlePageChange={handlePerRowsChange}
          noImage
          noTitle
        />
      </Hidden>

      <DialogDetailsOperation
        open={openDialog}
        onClose={handleCloseDialog}
        data={selectedRow}
      />
    </>
  );
};

OperationsTable.propTypes = {
  type: PropTypes.oneOf(['cash-in', 'cash-out']).isRequired,
};

export default OperationsTable;
