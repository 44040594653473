import { useState, useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { isEqual, isFunction } from 'lodash';

import { useRequest } from './useRequest';
import { ERRORS_MESSAGE } from '../../constants/errorMessage';

export const useDataTableRequest = ({
  request,
  payload,
  transformResponse,
  isMobile,
}) => {
  const [queryParams, setQueryParamsBase] = useState(payload);

  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        // eslint-disable-next-line no-param-reassign
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const [, loading, error, sendRequest] = useRequest(
    {
      initialState: [],
      request,
      payload: queryParams,
      transformResponse,
      withPostSuccess: (response) => {
        const { data: dataResponse, total_elements: totalElements } = response;
        const newData = isMobile ? [...data, ...dataResponse] : dataResponse;

        setData(newData);
        setCount(totalElements);
      },
      withPostFailure: () => {
        enqueueSnackbar(ERRORS_MESSAGE.notFoundRetryLater, {
          variant: 'error',
        });
      },
    },
    [queryParams],
  );

  const handlePageChange = useCallback(
    (newPage) => {
      setQueryParams((prev) => ({ ...prev, pageNumber: newPage }));
    },
    [setQueryParams],
  );

  const handlePerRowsChange = useCallback(
    (newPerPage, newPage) => {
      setQueryParams((prev) => ({
        ...prev,
        pageNumber: newPage,
        pageSize: newPerPage,
      }));
    },
    [setQueryParams],
  );

  const handleSearch = useCallback(
    (searchText) => {
      setQueryParams((prev) => ({
        ...prev,
        pageNumber: 1,
        filter: {
          userName: searchText,
          collectionCurrency: searchText,
          deliveryCurrency: searchText,
        },
      }));
    },
    [setQueryParams],
  );

  const refetchData = useCallback(() => sendRequest(queryParams), [
    queryParams,
    sendRequest,
  ]);

  const actions = useMemo(
    () => ({
      handlePageChange,
      handlePerRowsChange,
      handleSearch,
      refetchData,
    }),
    [handlePageChange, handlePerRowsChange, handleSearch, refetchData],
  );

  return [loading, data, count, error, queryParams, actions];
};
