import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause17 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        17. Responsabilidad de KriptonMarket
      </Typography>
      <Typography className={classes.text}>
        Tanto el Usuario cómo el Cliente es responsable de contar con los
        servicios y equipos necesarios para la navegación por Internet y para
        acceder a la Plataforma. En caso de cualquier incidencia o dificultad
        para acceder a la Plataforma, el Usuario puede informarlo a
        KriptonMarket a través del Formulario de Contacto, que procederá a
        analizar la incidencia y dará indicaciones al Usuario acerca de cómo
        resolverla en el plazo más breve posible. <br />
        KriptonMarket no controla ni es responsable de los contenidos vertidos
        por los Usuarios a través de la Plataforma, siendo estos únicos
        responsables de la adecuación legal de dichos contenidos. <br />
        KriptonMarket no responderá en caso de interrupciones del servicio,
        errores de conexión, falta de disponibilidad o deficiencias en el
        servicio de acceso a Internet, ni por interrupciones de la red de
        Internet o por cualquier otra razón ajena a su control. <br />
        KriptonMarket no se hace responsable de los errores de seguridad que se
        puedan producir ni de los daños que puedan causarse al sistema
        informático del Usuario y/o Cliente (hardware y software), a los
        ficheros o documentos almacenados en el mismo, como consecuencia de:
      </Typography>
      <Typography className={classes.text}>
        - La presencia de un virus en el sistema informático o terminal móvil
        del Usuario que sea utilizado para la conexión a los servicios y
        contenidos de la Plataforma.
        <br />
        - Un mal funcionamiento del navegador.
        <br />
        - Todo ataque informático realizado, a la Plataforma, por terceros
        ajenos a KriptonMarket, ya sea en forma personal o por software
        malicioso, scripts dañinos o toda herramienta informática empleada para
        cometer actos delictivos.
        <br />
      </Typography>
    </Box>
  );
};

export default Clause17;
