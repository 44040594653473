import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles, useMediaQuery, Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import CardWithProgressLoading from '../CardWithLoadingProgress';
import CurrencyInfo from '../CurrencyInfo';

import { useRequest } from '../../hooks';
import { getSummaryByTokens } from '../../../services/merchantsService';
import { ERRORS_MESSAGE } from '../../../constants/errorMessage';
import { chunk } from '../../../utils';
import Paginator from '../Paginator';
import styles from './styles';

const useStyles = makeStyles(styles);

const YourWallet = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [arrData, setArrData] = useState([]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const perChunk = useMemo(() => (isMobile ? 2 : 6), [isMobile]);

  const [currencies, loading] = useRequest(
    {
      initialState: [],
      request: getSummaryByTokens,
      transformResponse: ({ data }) => chunk(data, perChunk),
      withPostFailure: () => {
        enqueueSnackbar(ERRORS_MESSAGE.notFoundRetryLater, {
          variant: 'error',
        });
      },
    },
    [],
  );

  useEffect(() => {
    if (currencies.length) {
      if (currencies[page]) {
        setArrData(currencies[page]);
      }
    }
  }, [currencies, page]);

  return (
    <CardWithProgressLoading
      loading={loading}
      fullHeight={false}
      className={classes.card}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} container>
          {arrData.map((item) => (
            <Grid item xs={6} key={item.currency}>
              <CurrencyInfo
                imageUrl={item.logo}
                name={item.currency}
                price={item.amount}
                usdPrice={item.amount_usd}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Paginator page={page} data={currencies} onChange={setPage} />

      <Typography className={classes.infoTypography}>
        {t(`wallet.summaryNote`)}
      </Typography>
    </CardWithProgressLoading>
  );
};

export default YourWallet;
