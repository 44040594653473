import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Grid,
  Button,
  Typography,
  Hidden,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import styles from './styles';

const useStyles = makeStyles(styles);

const AddProfileInfo = ({ callback, title }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const handleButtonClick = () => callback();

  return (
    <Grid container className={classes.boxWrapper}>
      <Grid item md={12} xs={12}>
        <Hidden smDown>
          <Typography component="h3" className={classes.title}>
            {t(`profile.note`)}
          </Typography>
        </Hidden>
        <Grid item md={4} xs={12}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleButtonClick}
            fullWidth
          >
            {title}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddProfileInfo.propTypes = {
  callback: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddProfileInfo;
