import { appLateralMenuHeight } from '../../../utils/stylesUtils';

export default (theme) => ({
  lateralMenuContainer: {
    alignItems: 'center',
    // eslint-disable-next-line max-len
    background: `transparent linear-gradient(180deg, ${theme.palette.primary.main} 0%, #A67DF8 100%)`,
    boxShadow: '0px -3px 6px #00000029',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3, 0),
    position: 'absolute',
    top: 0,
    width: appLateralMenuHeight,

    [theme.breakpoints.down(1025)]: {
      display: 'none',
    },
  },
  lateralMenuTitle: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(28),
    margin: 0,
    marginBottom: 80,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  lateralMenuItemsContainer: {
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
    overflow: 'hidden auto',
    scrollbarWidth: 'none',
    MsOverFlowStyle: 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  lateralMenuCollapseContentContainer: {
    marginLeft: 32,

    '& > div': {
      paddingLeft: 10,
    },
  },
  lateralMenuCollapseItemContainer: {
    display: 'flex',
    position: 'relative',
  },
  lateralMenuElementContentSubOptions: {
    padding: '6px 0',
  },
  lateralMenuCollapseItemSelected: {
    '&::before': {
      backgroundColor: theme.palette.common.white,
      borderRadius: '50%',
      bottom: 0,
      content: "''",
      height: 5,
      left: -10,
      margin: 'auto',
      position: 'absolute',
      top: 0,
      width: 5,
    },
  },
  lateralMenuMenuElementText: {
    border: 0,
    color: theme.palette.common.white,
    cursor: 'pointer',
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'inherit',
    padding: 0,
  },
  lateralMenuElementContent: {
    borderBottom: `1px solid ${theme.palette.common.white}`,
    display: 'flex',
    marginBottom: 12,
    paddingBottom: 12,
    paddingLeft: 50,
    paddingRight: 8,
  },
  lateralMenuElementIcon: {
    height: 20,
    marginRight: 20,
    width: 20,
  },
  lateralMenuElementText: {
    border: 0,
    color: theme.palette.common.white,
    cursor: 'pointer',
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'inherit',
    padding: 0,
  },
});
