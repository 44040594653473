import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  minHeightWithHeaderFooter: {
    marginTop: ({ heightHeader }) => heightHeader,
    minHeight: ({ heightHeader, heightFooter }) =>
      `calc(100vh - ${heightHeader + heightFooter}px)`,
  },
  minHeightWithHeader: {
    marginTop: ({ heightHeader }) => heightHeader,
    minHeight: ({ heightHeader }) => `calc(100vh - ${heightHeader}px)`,
  },
  minHeightWithFooter: {
    minHeight: ({ heightFooter }) => `calc(100vh - ${heightFooter}px)`,
  },
}));

export const useHeightHeaderFooter = () => {
  const [heightHeader, setHeightHeader] = useState(0);
  const [heightFooter, setHeightFooter] = useState(0);

  useEffect(() => {
    const headerElement = document.querySelector('#header-app');
    const footerElement = document.querySelector('#footer-app');

    if (headerElement) {
      setHeightHeader(headerElement.scrollHeight);
    }

    if (footerElement) {
      setHeightFooter(footerElement.scrollHeight);
    }
  }, []);

  const classes = useStyles({ heightHeader, heightFooter });

  return { heightHeader, heightFooter, classes };
};
