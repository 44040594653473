import { isEqual } from 'lodash';

export const isRowSelected = (row, selectedRows) =>
  selectedRows.some((r) => isEqual(r, row));

export const insertRowSelected = (selectedRows = [], item, index = 0) => [
  ...selectedRows.slice(0, index),
  item,
  ...selectedRows.slice(index),
];

export const removeRowSelected = (selectedRows = [], item) =>
  selectedRows.filter((a) => !isEqual(a, item));
