import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  ButtonBase,
  Card,
  CardContent,
  Box,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';

import { PATHS } from '../../../constants/paths';
import { formatCurrencyValue } from '../../../utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const CurrencyInfo = ({ imageUrl, name, price, usdPrice }) => {
  const history = useHistory();
  const classes = useStyles();

  const handleClick = useCallback(
    () =>
      history.push(PATHS.liquidations.path, {
        currentCurrency: { name, price, usdPrice, imageUrl },
      }),
    [history, name, price, usdPrice, imageUrl],
  );

  return (
    <Card
      component={ButtonBase}
      focusRipple
      onClick={handleClick}
      className={classes.card}
    >
      <CardContent className={classes.cardContent}>
        <Box>
          <Box
            component="img"
            src={imageUrl}
            alt={name}
            className={classes.logo}
          />
          <Typography
            className={classes.priceTypography}
            style={{ textTransform: 'uppercase' }}
          >
            {name}
          </Typography>
        </Box>
        <Box className={classes.pricesContainer}>
          <Typography className={classes.priceTypography}>
            {formatCurrencyValue(price, { style: 'decimal' })}
          </Typography>
          <Typography className={classes.priceTypography}>
            {formatCurrencyValue(usdPrice, {
              style: 'currency',
              currency: 'USD',
              currencyDisplay: 'code',
            })}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

CurrencyInfo.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  usdPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export default CurrencyInfo;
