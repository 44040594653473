import { appLateralMenuHeight, guttersApp } from '../../../utils/stylesUtils';

export default (theme) => ({
  layoutLoggedContentConntainer: {
    ...guttersApp(theme.spacing, theme.breakpoints),
    display: 'flex',
    paddingLeft: `calc(${appLateralMenuHeight}px + ${theme.spacing(3)}px)`,
    width: '100%',
    minHeight: 'inherit',
  },
});
