import React, { useCallback, useState } from 'react';
import {
  makeStyles,
  useTheme,
  Typography,
  MobileStepper,
  Button,
  Grid,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import Clause1 from './Clause1';
import Clause2 from './Clause2';
import Clause3 from './Clause3';
import Clause4 from './Clause4';
import Clause5 from './Clause5';
import Clause6 from './Clause6';
import Clause7 from './Clause7';
import Clause8 from './Clause8';
import Clause9 from './Clause9';
import Clause10 from './Clause10';
import Clause11 from './Clause11';
import Clause12 from './Clause12';
import Clause16 from './Clause16';
import Clause17 from './Clause17';
import Clause18 from './Clause18';
import Clause19 from './Clause19';
import Clause20 from './Clause20';
import Clause21 from './Clause21';
import Clause22 from './Clause22';
import Clause23 from './Clause23';
import Clause24 from './Clause24';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clauses = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const handleNext = useCallback(() => {
    scrollToTop();
    setTimeout(() => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, 500);
  }, [scrollToTop]);

  const handleBack = useCallback(() => {
    scrollToTop();
    setTimeout(() => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, 500);
  }, [scrollToTop]);

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item md={10}>
        {activeStep === 0 && (
          <>
            <Typography component="h2" className={classes.title}>
              Condiciones Generales de Uso y Contratación
            </Typography>

            <Typography className={classes.text}>
              Condiciones Generales de Uso y Contratación Las presentes
              condiciones generales de uso e información legal (en adelante,
              Condiciones Generales) se aplican al sitio web de KriptonMarket,
              S.A. (en adelante, KriptonMarket), cuyo dominio es
              www.KriptonMarket.com, así como a todos sus sitios relacionados o
              vinculados desde www.KriptonMarket.com por KriptonMarket, así como
              a sus filiales y a asociados, incluidos los sitios web de
              KriptonMarket, en todo el mundo (en adelante y de forma conjunta,
              el “sitio”). El sitio es propiedad de KriptonMarket. Al utilizar
              el sitio, muestras tu conformidad con las presentes condiciones de
              uso. Si no está de acuerdo, se le invita a la no utilización del
              mismo. A través de las presentes Condiciones Generales,
              KiptonMarket pone a disposición de los usuarios (en adelante
              Usuario o Usuarios) el sitio web presente (en adelante denominada
              la Plataforma). En cumplimiento de lo establecido en la normativa
              reguladora, se exponen los siguientes datos identificativos del
              titular del sitio:
            </Typography>
            <Typography className={classes.text}>
              Denominación social: KriptonMarket <br />
              Domicilio social: Lavalle 166 6d cp 1047 caba <br />
              CUIT:
            </Typography>

            <Clause1 />
            <Clause2 />
            <Clause3 />
            <Clause4 />
            <Clause5 />
            <Clause6 />
          </>
        )}

        {activeStep === 1 && (
          <>
            <Clause7 />
            <Clause8 />
            <Clause9 />
            <Clause10 />
          </>
        )}

        {activeStep === 2 && (
          <>
            <Clause11 />
            <Clause12 />
            <Clause16 />
            <Clause17 />
            <Clause18 />
            <Clause19 />
            <Clause20 />
            <Clause21 />
            <Clause22 />
            <Clause23 />
            <Clause24 />
          </>
        )}

        <MobileStepper
          variant="dots"
          steps={3}
          position="static"
          activeStep={activeStep}
          classes={{ root: classes.stepper, dotActive: classes.dotActive }}
          nextButton={
            <Button
              size="small"
              variant="text"
              role="presentation"
              color="secondary"
              onClick={handleNext}
              disabled={activeStep === 2}
            >
              Siguiente
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              variant="text"
              role="presentation"
              color="secondary"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Anterior
            </Button>
          }
        />
      </Grid>
    </Grid>
  );
};

export default Clauses;
