import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import cn from 'classnames';

import styles from './styles';

const useStyles = makeStyles(styles);

const InfoText = ({ text, className }) => {
  const classes = useStyles();

  return (
    <Typography component="h3" className={cn(classes.typography, className)}>
      {text}
    </Typography>
  );
};

InfoText.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

InfoText.defaultProps = {
  className: null,
};

export default InfoText;
