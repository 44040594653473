import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { providers } from 'ethers';
import { useSelector, useDispatch } from 'react-redux';

import { rLogin } from '../../../config/Rlogin';
import { actions as authActions } from '../../modules/Auth/_redux/authRedux';

const RLoginContext = createContext();

export const useRLoginContext = () => useContext(RLoginContext);

export const RLoginProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { walletConnected } = useSelector((state) => state.auth);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [disconnect, setDisconnect] = useState(null);

  const handleConnectWallet = useCallback(
    async (onInitial, onConnect) => {
      onInitial?.();

      const {
        provider,
        disconnect: currentDisconnect,
      } = await rLogin.connect();
      setDisconnect(currentDisconnect);

      const currentWallet = await new providers.Web3Provider(provider)
        .getSigner(0)
        .getAddress();

      dispatch(authActions.setWalletConnected(currentWallet));
      setIsLoggedIn(true);

      onConnect?.();
    },
    [dispatch],
  );

  const handleDisconnectWallet = useCallback(async () => {
    dispatch(authActions.setWalletConnected(null));
    await disconnect;
    setDisconnect(null);
    setIsLoggedIn(false);
  }, [disconnect, dispatch]);

  const valueBag = useMemo(
    () => ({
      walletConnected,
      handleConnectWallet,
      handleDisconnectWallet,
      isLoggedIn,
    }),
    [handleConnectWallet, handleDisconnectWallet, walletConnected, isLoggedIn],
  );

  return (
    <RLoginContext.Provider value={valueBag}>{children}</RLoginContext.Provider>
  );
};

RLoginProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
