import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause6 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        6. Devolución de productos
      </Typography>
      <Typography className={classes.text}>
        En caso de que el Usuario desee proceder a la devolución de un producto
        o desee realizar una reclamación acerca de la realización de un
        servicio, deberá dirigirse directamente al mismo comercio en el cual se
        realizó la compra con el comprobante correspondiente, que el Kriptonian
        le proporcionó en el momento de la entrega del pedido. En caso de
        reembolso del importe de la compra por parte del comercio al Usuario,
        será el comercio el que tendrá potestad para decidir el método de
        devolución (metálico, tarjeta de crédito, vale de compra...). Por lo
        tanto, la devolución de un producto adquirido por el Kriptonian en
        ejecución del mandato conferido por el Usuario, quedará sujeta en todo
        caso a la política de devoluciones del comercio. <br />
        En el caso que el Usuario quiera tramitar la devolución de algún
        producto por no adecuarse a lo que ha solicitado a través de la
        plataforma, el Usuario deberá aportar una fotografía del pedido total
        junto con un listado de los productos incorrectos o que no se hayan
        entregado, o los defectos en los mismos, así como otras evidencias que
        demuestren la no adecuación del producto solicitado. <br />
        El Usuario deberá comprobar los productos que el Kriptonian le entregue
        en la dirección de entrega antes de proceder a firmar y ratificar el
        mandato. Con la firma, el Usuario confirma y ratifica el mandato, la
        compra o servicio realizado en su nombre. Asimismo, el Usuario
        manifiesta que un tercero podrá ratificar el mandato en su nombre, por
        ejemplo en aquellos supuestos en que el Usuario no se encuentre en la
        dirección final de entrega o haya designado a un tercero para la
        recogida y firma. Así, recae sobre el Usuario y/o dicho tercero la
        responsabilidad de verificar la adecuación del servicio, así como de en
        su caso, recabar las evidencias suficientes para justificar lo
        contrario. En todo caso, será potestad del comercio determinar en cada
        caso la procedencia de la devolución por lo que en caso de disputa, el
        Usuario deberá ponerlo en conocimiento de KriptonMarket a través del
        Formulario de Contacto o en el servicio de mensajería de la Plataforma.
      </Typography>
    </Box>
  );
};

export default Clause6;
