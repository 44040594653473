import React, { useCallback, forwardRef, useImperativeHandle } from 'react';
import { TextField, Grid, Box, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { LocationOn as LocationIcon } from '@material-ui/icons';
import parse from 'autosuggest-highlight/parse';

import { usePlacesAutocomplete } from '../../hooks/usePlacesAutocomplete';

const defaultGetOptionLabel = (option) =>
  typeof option === 'string' ? option : option.description;

const defaultFilterOptions = (option) => option;

// eslint-disable-next-line prefer-arrow-callback
const PlacesAutocomplete = forwardRef(function PlacesAutocomplete(
  inProps,
  ref,
) {
  const {
    id = 'places-autocomplete',
    getOptionLabel = defaultGetOptionLabel,
    filterOptions = defaultFilterOptions,
    onChange: onChangeProps,
    onInputChange: onInputChangeProps,
    label = 'Direccíon',
    error = false,
    helperText,
    initialValueRequest,
    ...rest
  } = inProps;

  const {
    value,
    setValue,
    inputValue,
    setInputValue,
    options,
    setOptions,
  } = usePlacesAutocomplete(initialValueRequest);

  const onChange = useCallback(
    (event, newValue, ...restParams) => {
      setOptions(newValue ? [newValue, ...options] : options);
      setValue(newValue);

      onChangeProps?.(event, newValue, ...restParams);
    },
    [options, setOptions, setValue, onChangeProps],
  );

  const onInputChange = useCallback(
    (event, newInputValue, ...restParams) => {
      setInputValue(newInputValue);

      onInputChangeProps?.(event, newInputValue, ...restParams);
    },
    [setInputValue, onInputChangeProps],
  );

  const renderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        label={label}
        error={error}
        helperText={helperText}
        fullWidth
        variant="outlined"
        size="small"
      />
    ),
    [label, error, helperText],
  );

  const renderOption = useCallback((option) => {
    const matches = option.matched_substrings;
    const parts = parse(
      option.structured_formatting.main_text,
      matches.map((match) => [match.offset, match.offset + match.length]),
    );

    return (
      <>
        <Grid container alignItems="center">
          <Grid item>
            <Box
              component={LocationIcon}
              color="text.secondary"
              marginRight={2}
            />
          </Grid>
          <Grid item xs>
            {parts.map((part, index) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}

            <Typography variant="body2" color="textSecondary">
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }, []);

  const resetAutocomplete = useCallback(() => {
    setValue(null);
    setInputValue('');
  }, [setValue, setInputValue]);

  useImperativeHandle(
    ref,
    () => ({
      resetAutocomplete,
    }),
    [resetAutocomplete],
  );

  return (
    <Autocomplete
      id={id}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={onInputChange}
      renderInput={renderInput}
      renderOption={renderOption}
      loadingText="Cargando..."
      noOptionsText="Sin opciones"
      {...rest}
    />
  );
});

export default PlacesAutocomplete;
