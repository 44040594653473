export default (theme) => ({
  dialogPaperContainer: {
    margin: theme.spacing(2),
    maxWidth: 768,
    width: `calc(100% - ${theme.spacing(2 * 2)}px)`,
  },
  dialogActionsContainer: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  dialogContentContainer: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
});
