import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, IconButton } from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

const VisibilityPasswordAdornment = ({ show, toggleShow, size }) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      onClick={toggleShow}
      onMouseDown={(e) => e.preventDefault()}
      size={size}
    >
      {show ? <VisibilityOffIcon /> : <VisibilityIcon />}
    </IconButton>
  </InputAdornment>
);

VisibilityPasswordAdornment.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleShow: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
};

VisibilityPasswordAdornment.defaultProps = {
  size: 'medium',
};

export default VisibilityPasswordAdornment;
