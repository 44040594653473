export default (theme) => ({
  dialogPaperContainer: {
    maxWidth: 572,
    margin: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(2 * 2)}px)`,
  },
  dialogTitleContainer: {
    paddingTop: theme.spacing(6),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(6),
    },
  },
  dialogTitleTypography: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightBold,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: 0,
  },
  dialogContentContainer: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(6),
    },
  },
  containerImages: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up(321)]: {
      flexDirection: 'row',
    },
  },
  imgAppRecomend: {
    width: 150,
    height: 110,
    backgroundColor: 'orange', // @TODO: delete when you have the image
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up(321)]: {
      marginBottom: 0,
    },

    [theme.breakpoints.up('sm')]: {
      width: 180,
      height: 135,
    },
  },
  storesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.up(321)]: {
      marginLeft: theme.spacing(2),
    },

    '& :first-child': {
      marginBottom: theme.spacing(0.5),
    },
  },
  imgStore: {
    width: 125,
    height: 40,

    [theme.breakpoints.up('sm')]: {
      width: 160,
      height: 50,
    },
  },
  imgQrApp: {
    width: 180,
    height: 180,
    backgroundColor: 'black', // @TODO: delete when you have the image
  },
  dialogActionsContainer: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(6),
    },
  },
  sendButton: {
    maxWidth: 376,
  },
  marginTop: {
    marginTop: 20,
  },
});
