import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  AppBar,
  Toolbar,
  Button,
  Box,
  Link as LinkMaterial,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LinkRouter from '../LinkRouter';
import RLoginButton from '../RLoginButton';
import MenuMobile from '../MenuMobile';
import LanguageMenu from '../LanguageMenu';
import CountryMenu from '../CountryMenu';

import { toAbsoluteUrl } from '../../../utils';
import {
  getLandingUrl,
  getMapUrl,
  getServiceUrl,
} from '../../../utils/externalPages';
import { PATHS } from '../../../constants/paths';
import * as auth from '../../modules/Auth/_redux/authRedux';
import { useRLoginContext } from '../../contexts/RLoginContext';

import styles from './styles';

const useStyles = makeStyles(styles);

// eslint-disable-next-line no-unused-vars
const Header = ({ logout, isAuthPage, isPrivatePage }) => {
  const classes = useStyles();
  const { authToken } = useSelector((state) => state.auth);
  const { t } = useTranslation('common');

  const { walletConnected, handleDisconnectWallet } = useRLoginContext();

  const handleLogout = useCallback(() => {
    if (walletConnected) handleDisconnectWallet();
    logout();
  }, [handleDisconnectWallet, logout, walletConnected]);

  return (
    <AppBar position="fixed" className={classes.root} id="header-app">
      <Toolbar disableGutters classes={{ regular: classes.toolbar }}>
        <LinkRouter
          to={PATHS.home.path}
          color="inherit"
          underline="none"
          className={classes.linkLogo}
        >
          <Box
            component="img"
            src={toAbsoluteUrl('/assets/images/logo2.svg')}
            alt="Logo"
            className={classes.logo}
          />
        </LinkRouter>

        <Box className={classes.contentInfoContianer}>
          <Box className={classes.pagesContainer}>
            <LinkMaterial
              href={getMapUrl()}
              underline="none"
              color="inherit"
              className={classes.linkLogo}
            >
              <Box
                component="img"
                src={toAbsoluteUrl('/assets/icons/marker-multi-color.svg')}
                alt="Marker"
                className={classes.pageIcon}
              />
            </LinkMaterial>

            <LinkMaterial
              href={`${getLandingUrl()}/quotation`}
              underline="none"
              color="inherit"
              className={classes.linkLogo}
            >
              <Box
                component="img"
                src={toAbsoluteUrl('/assets/icons/money-multi-color.svg')}
                alt="Money"
                className={classes.pageIcon}
              />
            </LinkMaterial>

            <LinkMaterial
              href="https://marketplace.kriptonmarket.com/"
              underline="none"
              color="inherit"
              className={classes.linkLogo}
            >
              <Box
                component="img"
                src={toAbsoluteUrl(
                  '/assets/icons/shopping-cart-multi-color.svg',
                )}
                alt="Shopping Cart"
                className={classes.pageIcon}
              />
            </LinkMaterial>

            <LinkMaterial
              href={getServiceUrl()}
              underline="none"
              color="inherit"
              className={classes.linkLogo}
            >
              <Box
                component="img"
                src={toAbsoluteUrl('/assets/icons/world-multicolor.svg')}
                alt="World"
                className={classes.pageIcon}
              />
            </LinkMaterial>
          </Box>

          <Box className={classes.selectorsContainer}>
            <LanguageMenu />

            <CountryMenu />

            {authToken && <RLoginButton color="purple" />}
          </Box>
        </Box>

        <Box className={classes.contentActions}>
          <Box className={classes.actionsAuthContainer}>
            {!authToken && !isAuthPage && (
              <>
                <Button
                  color="secondary"
                  variant="outlined"
                  component={Link}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  to={PATHS.login.path}
                  className={classes.authButton}
                >
                  {t(`header.login`)}
                </Button>

                <Button
                  color="secondary"
                  variant="contained"
                  component={Link}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  to={PATHS.registration.path}
                  className={classes.authButton}
                >
                  {t(`header.createAccount`)}
                </Button>
              </>
            )}

            {authToken && (
              <Button
                color="secondary"
                variant="outlined"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                underline="none"
                onClick={handleLogout}
                className={classes.authButton}
              >
                <Box
                  component="img"
                  src={toAbsoluteUrl('/assets/icons/exit-purple.svg')}
                  alt="Logout"
                  className={classes.logoutIcon}
                />

                {t(`header.logOut`)}
              </Button>
            )}
          </Box>

          <MenuMobile />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  isAuthPage: PropTypes.bool,
  isPrivatePage: PropTypes.bool,
};

Header.defaultProps = {
  isAuthPage: false,
  isPrivatePage: false,
};

export default connect(null, auth.actions)(Header);
