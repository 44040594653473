import React from 'react';
import PropTypes from 'prop-types';

import {
  makeStyles,
  Grid,
  FormControl,
  InputLabel,
  InputBase,
  FormLabel,
  Button,
  FormHelperText,
  Chip,
} from '@material-ui/core';
import { AttachFile as AttachFileIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import styles from './styles';

const useStyles = makeStyles(styles);

const FileUpload = ({
  id,
  error,
  labelText,
  helperText,
  handleChange,
  accept,
  file,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <Grid container spacing={0} alignItems="flex-end" justify="space-between">
      <Grid item xs={12}>
        <FormControl fullWidth error={error}>
          <InputLabel
            className={classes.label}
            shrink
            component="legend"
            variant="outlined"
          >
            {labelText}
          </InputLabel>

          <InputBase
            id={id}
            type="file"
            inputProps={{ accept }}
            style={{ display: 'none' }}
            onChange={handleChange}
          />

          <FormLabel htmlFor={id} className={classes.contianerButton}>
            <Button
              variant="text"
              color="primary"
              component="span"
              startIcon={<AttachFileIcon color="secondary" />}
            >
              {t(`general.fileUpload`)}
            </Button>
          </FormLabel>
        </FormControl>
      </Grid>

      {file && (
        <Grid item xs={12}>
          <Chip variant="outlined" label={file.name} className={classes.chip} />
        </Grid>
      )}

      {error && (
        <Grid item xs={12}>
          <FormHelperText
            id={`${id}-helperText`}
            variant="outlined"
            error={error}
          >
            {helperText}
          </FormHelperText>
        </Grid>
      )}
    </Grid>
  );
};

FileUpload.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  accept: PropTypes.string,
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

FileUpload.defaultProps = {
  error: false,
  helperText: ' ',
  accept: 'image/*',
  file: null,
};

export default FileUpload;
