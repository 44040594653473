import React, { useCallback, useMemo } from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import CardWithProgressLoading from '../CardWithLoadingProgress';
import TitleCards from '../TitleCards';
import TableList from '../TableList';
import TableListMobile from '../TableListMobile';

import { useDataTableRequest, useFirstRender } from '../../hooks';
import { getRetirements } from '../../../services/merchantsService';
import { formatCurrencyValue } from '../../../utils';
// import { TYPE_MOVEMENTS_DESCRIPTIONS } from '../../../utils/movements';

import styles from './styles';
import { PATHS } from '../../../constants/paths';

const useStyles = makeStyles(styles);

const MovementHistory = () => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const classes = useStyles();
  const isFirstRender = useFirstRender();

  const isdDekstop = useMediaQuery((theme) => theme.breakpoints.up('xl'), {
    noSsr: true,
  });

  const initialFilters = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: isdDekstop ? 10 : 5,
      sortOrder: 'desc',
      sortField: 'created_at',
    }),
    [isdDekstop],
  );

  const [loading, data, count, , { pageNumber }] = useDataTableRequest({
    request: getRetirements,
    payload: initialFilters,
  });

  const columns = useMemo(
    () => [
      {
        name: t(`wallet.date`),
        selector: 'created_at',
        sortable: false,
        format: (row) => moment(row.created_at).format('DD/MM/YYYY'),
        center: true,
      },
      {
        name: t(`wallet.operation`),
        selector: 'movement_type',
        // format: (row) => TYPE_MOVEMENTS_DESCRIPTIONS[row.movement_type],
        format: () => 'Redimido',
        sortable: false,
        center: true,
      },
      {
        name: t(`wallet.amount`),
        selector: 'amount',
        format: (row) => formatCurrencyValue(row.amount, { style: 'decimal' }),
        sortable: false,
        center: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const redirecToMovments = useCallback(
    () => history.push(PATHS.movements.path),
    [history],
  );

  return (
    <CardWithProgressLoading
      loading={isFirstRender && loading}
      className={classes.root}
      header={<TitleCards title={t(`wallet.movementsTitle`)} paragraph />}
    >
      {isdDekstop && (
        <TableList
          data={data}
          columns={columns}
          loading={loading}
          noRowsPerPage
          pagination={false}
          extraAction
          labelExtraAction={t(`wallet.viewMore`)}
          handleExtraAction={redirecToMovments}
          classNameContainerCard={classes.table}
        />
      )}

      {!isdDekstop && (
        <TableListMobile
          data={data}
          columns={columns}
          pageNumber={pageNumber}
          loading={loading}
          totalRows={count + 1}
          handlePageChange={redirecToMovments}
          buttonPaginatorText={t(`wallet.viewMore`)}
          noTitle
          noImage
          className={classes.table}
        />
      )}
    </CardWithProgressLoading>
  );
};

export default MovementHistory;
