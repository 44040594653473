import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause9 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        9. Derecho de desistimiento y cancelación de pedidos
      </Typography>
      <Typography className={classes.text}>
        El Usuario podrá cancelar de forma gratuita un pedido en tanto el
        Kriptonian no haya aceptado el pedido. <br />
        De acuerdo con la propia naturaleza del servicio que ofrece
        KriptonMarket, el Usuario es consciente de que una vez un Kriptonian
        haya aceptado voluntariamente un pedido, se considera que la ejecución
        del mandato de compra ha comenzado y por tanto el Usuario no tendrá
        derecho a desistir en la solicitud del servicio de forma gratuita.
        <br />
        Por lo tanto, si el Usuario cancela un pedido que ya tiene asignado a un
        Kriptonian, la Plataforma generará el costo del 100% del servicio básico
        de entrega en concepto de costo de cancelación para el Usuario. <br />
        Si además, en el momento de cancelación del servicio, el Kriptonian ya
        hubiese realizado la compra encomendada del producto o la contratación
        de un servicio, el Usuario podrá encargar al Kriptonian que realice la
        devolución del mismo. A tal efecto, el Usuario deberá satisfacer el
        costo total de adquisición de los productos y los costos de entrega, así
        como el costo del servicio de devolución. En el supuesto que el
        Kriptonian haya podido realizar la devolución del producto, se le
        reintegrará al Usuario el valor del producto, debiendo este, como se ha
        dicho, pagar el costo de los dos servicios de recogida y entrega, así
        como de devolución. La devolución en todo caso estará sujeta a las
        políticas de devolución del comercio es posible que no se pueda proceder
        a la devolución y por lo tanto, KriptonMarket quedará facultado para
        cargar tanto el producto que el Kriptonian ya hubiera adquirido bajo su
        mandato, como el servicio de envío incurrido. <br />
        En el caso que el Usuario haya indicado de forma incorrecta la dirección
        de entrega de los productos, podrá consignar una nueva dirección en
        cualquier momento siempre que se encuentre dentro de la misma ciudad del
        encargo inicial y dentro del ámbito de aplicación del Kriptonian. En ese
        caso, el Cliente estará encargando la contratación de un nuevo servicio
        y acepta que se le carguen los importes correspondientes de la nueva
        entrega. En caso de encontrarse en otra ciudad de la inicialmente
        indicada, ésta no podrá modificarse para entregarse en una nueva ciudad
        y el encargo se cancelará, debiendo el Cliente asumir los costos
        generados según se establece en esta cláusula. <br />
        KriptonMarket se reserva el derecho a cancelar un pedido sin necesidad
        de alegar causa justa. En caso de cancelación instada por KriptonMarket,
        el Usuario tendrá derecho al reembolso de la cantidad abonada según las
        condiciones del punto 4 de este acuerdo de Condiciones de Servicio.
        <br />
        KriptonMarket dispone de hojas de reclamación oficiales a disposición
        del consumidor, por el objeto del servicio ofrecido por KriptonMarket,
        en los idiomas oficiales de los países dónde KriptonMarket opera. Las
        mencionadas hojas de reclamación podrán solicitarse por parte del
        consumidor a través delFormulario de Contacto y será remitida de forma
        automática la opción para acceder a las mismas. El consumidor deberá
        especificar en el correo electrónico la ubicación exacta desde la que
        realiza la solicitud que deberá coincidir con el lugar de realización
        del servicio, en caso de duda, será este último el lugar dónde deberá
        instarse la reclamación.
      </Typography>
    </Box>
  );
};

export default Clause9;
