import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Card,
  Box,
  Typography,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { uniqueId } from 'lodash';
import cn from 'classnames';

import CurrentLocationInfo from '../CurrentLocationInfo';
import NoDataItem from '../NoDataItem';
import { getProperty } from './utils';
import { isRowSelected } from '../../../utils/dataTableSelectableRow';

import styles from './styles';

const useStyles = makeStyles(styles);

const noop = () => null;

const TableListMobile = ({
  data,
  columns,
  pageNumber,
  totalRows,
  perPage,
  loading,
  handlePageChange,
  noTitle,
  noImage,
  customTitle,
  buttonPaginatorText,
  buttonPaginatorProps,
  selectableRows,
  selectedRows,
  selectableRowsNoSelectAll,
  allSelected,
  onSelectedRowChange,
  onSelectAllRowsChange,
  className,
}) => {
  const classes = useStyles();

  const onChangePage = useCallback(
    (newPage) => {
      handlePageChange(perPage, newPage);
    },
    [handlePageChange, perPage],
  );

  const dataLength = useMemo(() => data.length, [data]);
  const hasElements = useMemo(() => dataLength > 0, [dataLength]);
  const disabledGreater = useMemo(() => !(totalRows === dataLength), [
    dataLength,
    totalRows,
  ]);

  const indeterminateAllSelectedCheckbox = useMemo(
    () => selectedRows.length > 0 && !allSelected,
    [allSelected, selectedRows],
  );

  return (
    <Card className={cn(classes.root, className)}>
      <CurrentLocationInfo
        noTitle={noTitle}
        noImage={noImage}
        customTitle={customTitle}
      />

      <Box className={classes.listContainer}>
        {(!loading || hasElements) && (
          <>
            {selectableRows && !selectableRowsNoSelectAll && dataLength > 0 && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allSelected}
                    indeterminate={indeterminateAllSelectedCheckbox}
                    color="secondary"
                    name="select-all-rows"
                    onChange={noop} // prevent uncontrolled checkbox warnings -  we don't need onChange
                    onClick={() => onSelectAllRowsChange({ rows: data })}
                  />
                }
                label="Seleccionar Todas"
                labelPlacement="start"
                className={classes.selectedAllCheckboxContainer}
                classes={{ label: classes.selectedAllCheckboxTypography }}
              />
            )}

            {data.map((row, index) => {
              const isSelected = isRowSelected(row, selectedRows);

              return (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={index} className={classes.itemContainer}>
                  {selectableRows && (
                    <Box className={classes.selectedCheckboxContainer}>
                      <Checkbox
                        checked={isSelected}
                        color="secondary"
                        name={`select-row-${index}`}
                        onChange={noop} // prevent uncontrolled checkbox warnings -  we don't need onChange
                        onClick={() =>
                          onSelectedRowChange({
                            rows: data,
                            selectedRow: row,
                            isSelected,
                          })
                        }
                      />
                    </Box>
                  )}

                  {columns.map((column) => {
                    const key = `cell-${uniqueId()}-${row[column.selector]}`;

                    if (column.hide && column.hide(row)) {
                      return null;
                    }

                    return !column.cell && !column.button ? (
                      <Box key={key} className={classes.itemContainerInfo}>
                        <Typography
                          className={classes.itemContainerInfoTypography}
                        >
                          {column.name}
                        </Typography>

                        <Typography
                          component="span"
                          className={cn(classes.itemContainerInfoTypography, {
                            [classes.itemContainerInfoTypographyRight]:
                              column.right,
                          })}
                        >
                          {getProperty(
                            row,
                            column.selector,
                            column.format,
                            index,
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        key={key}
                        className={cn(classes.itemCell, {
                          [classes.itemCellRight]:
                            (column.right || column.button) && !column.center,
                          [classes.itemCellCenter]:
                            column.center && !column.right,
                        })}
                      >
                        {column.cell(row, index, column)}
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </>
        )}

        {!loading && !hasElements && <NoDataItem />}
      </Box>

      {loading && (
        <Box className={classes.containerLoading}>
          <CircularProgress size={75} />
        </Box>
      )}

      {!loading && disabledGreater && hasElements && (
        <Box className={classes.buttonContainer}>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            className={classes.button}
            onClick={() => onChangePage(pageNumber + 1)}
            {...buttonPaginatorProps}
          >
            {buttonPaginatorText}
          </Button>
        </Box>
      )}
    </Card>
  );
};

TableListMobile.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  columns: PropTypes.instanceOf(Array).isRequired,
  pageNumber: PropTypes.number.isRequired,
  perPage: PropTypes.number,
  totalRows: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  noTitle: PropTypes.bool,
  noImage: PropTypes.bool,
  customTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonPaginatorProps: PropTypes.instanceOf(Object),
  buttonPaginatorText: PropTypes.string,
  selectableRows: PropTypes.bool,
  selectedRows: PropTypes.instanceOf(Array),
  selectableRowsNoSelectAll: PropTypes.bool,
  allSelected: PropTypes.bool,
  onSelectedRowChange: PropTypes.func,
  onSelectAllRowsChange: PropTypes.func,
  className: PropTypes.string,
};

TableListMobile.defaultProps = {
  perPage: 4,
  noTitle: false,
  noImage: false,
  customTitle: null,
  buttonPaginatorText: 'Ver Más',
  buttonPaginatorProps: {},
  selectableRows: false,
  selectedRows: [],
  selectableRowsNoSelectAll: false,
  allSelected: false,
  onSelectedRowChange: noop,
  onSelectAllRowsChange: noop,
  className: null,
};

export default TableListMobile;
