import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Tooltip, Typography } from '@material-ui/core';
import cn from 'classnames';
import { split, head } from 'lodash';

import styles from './styles';

const useStyles = makeStyles(styles);

const ConfigSelectionSwitchLabel = ({ name, icon, allow, truncateText }) => {
  const classes = useStyles();

  const truncateName = useMemo(
    () => (truncateText ? head(split(name, ' ')) : name),
    [truncateText, name],
  );

  return (
    <Box className={classes.root}>
      {icon && (
        <Box component="img" src={icon} alt={name} className={classes.logo} />
      )}
      <Tooltip title={name}>
        <Typography
          className={cn(classes.text, {
            [classes.textAllow]: allow,
          })}
        >
          {truncateName}
        </Typography>
      </Tooltip>
    </Box>
  );
};

ConfigSelectionSwitchLabel.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  allow: PropTypes.bool,
  truncateText: PropTypes.bool,
};

ConfigSelectionSwitchLabel.defaultProps = {
  icon: null,
  allow: false,
  truncateText: false,
};

export default memo(ConfigSelectionSwitchLabel);
