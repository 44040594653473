import React from 'react';
import {
  makeStyles,
  Box,
  Typography,
  InputBase,
  Link,
  Divider,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import LinkRouter from '../LinkRouter';

import { toAbsoluteUrl } from '../../../utils';
import { PATHS } from '../../../constants/paths';

import styles from './styles';

const useStyles = makeStyles(styles);

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <Box component="footer" className={classes.root} id="footer-app">
      <Box className={classes.utilsContainer}>
        <Box className={classes.utilsInfoContainer}>
          <Box className={classes.columnContainer}>
            <Typography
              variant="body2"
              component="h6"
              className={classes.title}
            >
              CONTACTO
            </Typography>

            <Box className={classes.utilsContactRow}>
              <Box className={classes.columnContainerWithOutSpace}>
                <Typography variant="caption">Direccion</Typography>
                <Typography variant="caption">
                  Espacio Bitcoin. M.T. de Alvear 405 Ofic. 3, CABA
                </Typography>
              </Box>

              <Box className={classes.columnContainerWithOutSpace}>
                <Typography variant="caption">Email</Typography>
                <Typography variant="caption">
                  info@kriptonmarket.com
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className={classes.columnContainer}>
            <Typography
              variant="body2"
              component="h6"
              className={classes.title}
            >
              NEWSLETTER
            </Typography>

            <Box className={classes.columnContainerWithOutSpace}>
              <InputBase
                type="text"
                placeholder="Mail..."
                className={classes.input}
              />

              <Typography variant="caption">
                Súmate a nuestra lista de mailing para estar al tanto de las
                novedades
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.utilsRrrssContainer}>
          <Box className={classes.columnContainer}>
            <Typography
              variant="body2"
              component="h6"
              className={classes.title}
            >
              REDES SOCIALES
            </Typography>

            <Box className={classes.rowContainer}>
              <Link
                target="_blank"
                href="https://www.instagram.com/kriptonmarket/"
                rel="noopener noreferrer"
                className={styles.rrssLink}
              >
                <img
                  src={toAbsoluteUrl('/assets/icons/rrss/ic_instagram.png')}
                  alt="Instagram Logo"
                />
              </Link>

              <Link
                target="_blank"
                href="https://www.facebook.com/KriptonMarket"
                rel="noopener noreferrer"
                className={styles.rrssLink}
              >
                <img
                  src={toAbsoluteUrl('/assets/icons/rrss/ic_facebook.png')}
                  alt="Facebook Logo"
                />
              </Link>

              <Link
                target="_blank"
                href="https://twitter.com/Kriptonmarket"
                rel="noopener noreferrer"
                className={styles.rrssLink}
              >
                <img
                  src={toAbsoluteUrl('/assets/icons/rrss/ic_twitter.png')}
                  alt="Twitter Logo"
                />
              </Link>

              <Link
                href="https://www.youtube.com/channel/UCJuoXX2mlEs2ApdF1OM-JsQ"
                rel="noopener noreferrer"
                className={styles.rrssLink}
              >
                <img
                  src={toAbsoluteUrl('/assets/icons/rrss/ic_youtube.png')}
                  alt="YouTube Logo"
                />
              </Link>

              <Link
                target="_blank"
                href="https://www.linkedin.com/company/kripton-mkt"
                rel="noopener noreferrer"
                className={styles.rrssLink}
              >
                <img
                  src={toAbsoluteUrl('/assets/icons/rrss/ic_linkedin.png')}
                  alt="LinkedIn Logo"
                />
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider className={classes.divider} />

      <Box
        className={cn(
          classes.columnSmallContainer,
          classes.containerCenter,
          classes.legalContainer,
        )}
      >
        <Link
          href="http://qr.afip.gob.ar/?qr=sA8U0zUxa70OsmYHj0ihYQ,,"
          target="_F960AFIPInfo"
          className={classes.afipLink}
        >
          <img
            src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg"
            alt="Afip img"
            className={classes.afipImg}
          />
        </Link>

        <Box
          className={cn(classes.columnSmallContainer, classes.containerCenter)}
        >
          <img src={toAbsoluteUrl('/assets/images/logo.svg')} alt="Logo" />

          <Typography variant="caption" className={classes.copyright}>
            {t(`footer.copyright`)}
          </Typography>
        </Box>

        <Box className={classes.privacyContainer}>
          <LinkRouter
            to={PATHS.privacyPolicy.path}
            color="inherit"
            underline="none"
            variant="caption"
          >
            {t(`footer.privacyPolicies`)}
          </LinkRouter>

          <LinkRouter
            to={PATHS.termsAndConditions.path}
            color="inherit"
            underline="none"
            variant="caption"
          >
            {t(`footer.tyc`)}
          </LinkRouter>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
