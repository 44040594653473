import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { uniqueId } from 'lodash';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { getProperty } from '../TableListMobile/utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const ProfileSectionInfo = ({ data, rowsInfo, className, disabledI18 }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <Box className={cn(classes.root, className)}>
      {rowsInfo.map((row, index) => (
        <Box
          key={`section-profile-row-${uniqueId()}-${row.selector}`}
          className={classes.rowContainer}
        >
          <Typography className={classes.rowNameTypography}>
            {!disabledI18 ? t(`profile.${row.name}`) : row.name}
          </Typography>
          <Typography className={classes.rowValueTypography}>
            {getProperty(data, row.selector, row.format, index)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

ProfileSectionInfo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  rowsInfo: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]).isRequired,
      selector: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
      format: PropTypes.func,
    }),
  ).isRequired,
  className: PropTypes.string,
  disabledI18: PropTypes.bool,
};

ProfileSectionInfo.defaultProps = {
  className: null,
  disabledI18: false,
};

export default ProfileSectionInfo;
