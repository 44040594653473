import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography, IconButton } from '@material-ui/core';
import { FileCopy as ContentCopyIcon } from '@material-ui/icons';
import QRCode from 'react-qr-code';
import cn from 'classnames';

import { useCopyToClipboard } from '../../hooks';

import styles from './styles';

const useStyles = makeStyles(styles);

const QrCodeGenerator = ({ size, value, className }) => {
  const classes = useStyles();

  const [, copyToClipboard] = useCopyToClipboard();

  const handleCopyQrValue = useCallback(() => {
    copyToClipboard(value);
  }, [copyToClipboard, value]);

  return (
    <Box component="div" className={cn(classes.container, className)}>
      <QRCode value={value} size={size} />

      <Box className={classes.qrTextContainer}>
        <Typography
          variant="caption"
          color="secondary"
          className={classes.qrTextTypography}
        >
          {value}
        </Typography>

        <IconButton
          aria-label="Copy to clipboard"
          onClick={handleCopyQrValue}
          size="small"
          color="secondary"
        >
          <ContentCopyIcon color="secondary" fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

QrCodeGenerator.propTypes = {
  size: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
};

QrCodeGenerator.defaultProps = {
  className: null,
};

export default QrCodeGenerator;
