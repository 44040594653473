import API from '../config/api';

const ENDPOINTS = {
  GET_CURRENCIES: '/public/currencies',
  GET_CONFIG_FORMS: '/public/config_forms',
  GET_COORDINATES_BY_IP: '/public/current_coordinates',
};

export const getListCurrencies = () => API.get(ENDPOINTS.GET_CURRENCIES);

export const getConfigForms = ({ resource, country }) =>
  API.get(ENDPOINTS.GET_CONFIG_FORMS, { params: { resource, country } });

export const getCurrentCoordinatesByIp = () =>
  API.get(ENDPOINTS.GET_COORDINATES_BY_IP);
