import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { includes, isEqual } from 'lodash';

export const useRouteMatch = () => {
  const { pathname } = useLocation();

  const matchPath = useCallback(
    (path, isExact = false) => {
      const matchExact = isEqual(pathname, path);
      const matchInclude = includes(pathname, path);

      return isExact ? matchExact : matchExact || matchInclude;
    },
    [pathname],
  );

  return { pathname, matchPath };
};
