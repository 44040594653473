import React, { useCallback, useMemo } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { find, includes } from 'lodash';

import { actions as authActions } from '../../modules/Auth/_redux/authRedux';
import { useRouteMatch } from '../../hooks';
import { MENU_OPTIONS_2 } from '../../../constants/menuOptions';

import CollapseMenuSelector from '../CollapseMenuSelector';
import LinkRouter from '../LinkRouter';

import styles from './styles';

const useStyles = makeStyles(styles);

const LateralMenu = () => {
  const { t } = useTranslation('common');
  const { matchPath } = useRouteMatch();
  const classes = useStyles();
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const { currentSubOptionMenuSelected } = useSelector((state) => state.auth);

  const resetCurrentSubOptionMenuSelected = useCallback(() => {
    dispatch(authActions.resetCurrentSubOptionMenuSelected());
  }, [dispatch]);

  const toggleExpandedPanelSubOptions = useCallback(
    (panel) => {
      const subObtionSelected =
        currentSubOptionMenuSelected === panel ? null : panel;

      dispatch(authActions.setCurrentSubOptionMenuSelected(subObtionSelected));
    },
    [currentSubOptionMenuSelected, dispatch],
  );

  const currentLocationObjectMenu = useMemo(() => {
    const curr = find(MENU_OPTIONS_2, (item) =>
      item.subOptions
        ? find(item.subOptions, (subItem) => includes(pathname, subItem.path))
        : includes(pathname, item.path),
    );

    return curr || {};
  }, [pathname]);

  return (
    <Box component="nav" className={classes.lateralMenuContainer}>
      <Typography component="h5" className={classes.lateralMenuTitle}>
        {t(`menu.${currentLocationObjectMenu.key}`)}
      </Typography>

      <Box className={classes.lateralMenuItemsContainer}>
        {MENU_OPTIONS_2.map(({ key, image, path, subOptions }) => {
          if (subOptions && subOptions.length > 0) {
            return (
              <CollapseMenuSelector
                open={currentSubOptionMenuSelected === key}
                handleOpen={() => toggleExpandedPanelSubOptions(key)}
                title={t(`menu.${key}`)}
                Icon={<img src={image} alt={t(`menu.${key}`)} />}
                key={`menu-link-element-${key}`}
                className={classes.lateralMenuElementContent}
                classNameContent={classes.lateralMenuCollapseContentContainer}
              >
                {subOptions.map((option) => (
                  <LinkRouter
                    key={`${key}-collapse-element-${option.key}`}
                    to={option.path}
                    underline="none"
                    className={cn(
                      classes.lateralMenuCollapseItemContainer,
                      classes.lateralMenuElementContentSubOptions,
                      {
                        [classes.lateralMenuCollapseItemSelected]: matchPath(
                          option.path,
                          option.isExact ?? false,
                        ),
                      },
                    )}
                  >
                    <Box className={classes.lateralMenuMenuElementText}>
                      {t(`menu.${option.key}`)}
                    </Box>
                  </LinkRouter>
                ))}
              </CollapseMenuSelector>
            );
          }

          return (
            <LinkRouter
              key={`menu-link-element-${key}`}
              to={path}
              className={classes.lateralMenuElementContent}
              onClick={resetCurrentSubOptionMenuSelected}
              underline="none"
            >
              <Box
                component="img"
                src={image}
                alt={t(`menu.${key}`)}
                className={classes.lateralMenuElementIcon}
              />

              <Box className={classes.lateralMenuElementText}>
                {t(`menu.${key}`)}
              </Box>
            </LinkRouter>
          );
        })}
      </Box>
    </Box>
  );
};

export default LateralMenu;
