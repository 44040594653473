export default (theme) => ({
  root: {
    margin: 0,
  },
  itemContainer: {
    minHeight: 42,
  },
  disableOppositeContent: {
    display: 'none',
  },
  dotSeparator: {
    backgroundColor: theme.palette.primary.main,
    borderColor: 'transparent',
    marginTop: 12,
    padding: 1,
  },
  connectorSeparator: {
    backgroundColor: theme.palette.secondary.light,
    width: 1,
  },
  content: {
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'left',
  },
});
