import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Marker = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 32.691 47.557"
    width="32.691"
    height="47.557"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#f89737" />
        <stop offset="1" stopColor="#ef6f11" />
      </linearGradient>
    </defs>
    <path
      id="Unión_25"
      data-name="Unión 25"
      // eslint-disable-next-line max-len
      d="M0,16.644A16.483,16.483,0,0,1,16.344,0,16.485,16.485,0,0,1,32.691,16.644c0,12.483-16.346,30.913-16.346,30.913S0,29.127,0,16.644Z"
      fill="url(#linear-gradient)"
    />
    <g id="Grupo_4369" data-name="Grupo 4369" transform="translate(9.819 11)">
      <path
        id="Trazado_5714"
        data-name="Trazado 5714"
        d="M126.876,115.839l.476,1.06v-1.984Z"
        transform="translate(-119.766 -108.622)"
        fill="#fff"
      />
      <path
        id="Trazado_5715"
        data-name="Trazado 5715"
        // eslint-disable-next-line max-len
        d="M83.175,77.967l.476-.923v-2.82h1.454L86.9,70.75H82.8l-2.852,6.338H79.91V70.75H76.066V87.265H79.91V82.894l.805-1.388,2.253,5.759h.683V79.027Z"
        transform="translate(-76.066 -70.75)"
        fill="#fff"
      />
      <path
        id="Trazado_5716"
        data-name="Trazado 5716"
        // eslint-disable-next-line max-len
        d="M136.888,109.4h.494v-6.823c0-1.643.765-2.221,2.088-2.221a4.362,4.362,0,0,1,.93.092V96.563h-.6a2.736,2.736,0,0,0-2.5,2.313h-.041v-1.99h-2.888l-.708,1.375v3.954Z"
        transform="translate(-125.604 -92.885)"
        fill="#fff"
      />
      <path
        id="Trazado_5717"
        data-name="Trazado 5717"
        d="M133.663,98.831v1.375l.708-1.375Z"
        transform="translate(-125.603 -94.829)"
        fill="#fff"
      />
      <path
        id="Trazado_5718"
        data-name="Trazado 5718"
        d="M133.663,143.411h3.225l-3.225-7.185Z"
        transform="translate(-125.603 -126.897)"
        fill="#fff"
      />
    </g>
  </SvgIcon>
);

export default Marker;
