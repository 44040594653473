import { repeat } from 'lodash';
import moment from 'moment';

export const formatCurrencyValue = (
  value,
  options = { style: 'currency', currency: 'ARS' },
) =>
  new Intl.NumberFormat('es-AR', {
    ...options,
    maximumFractionDigits: 8,
  }).format(Number(value));

export const formatDate = (date, format = 'DD/MM/YYYY') =>
  moment(date).format(format);

export const toCamel = (s) =>
  s.replace(/([-_][a-z])/gi, ($1) =>
    $1.toUpperCase().replace('-', '').replace('_', ''),
  );

export const parseValueIsProd = (enable, isProd) =>
  typeof enable === 'boolean' ? enable : isProd;

export const chunk = (array, size) =>
  array.reduce((acc, _, i) => {
    if (i % size === 0) acc.push(array.slice(i, i + size));
    return acc;
  }, []);

export const parseWallet = (wallet, segmenteQuantity = 5) => {
  const firstPart = wallet.slice(0, segmenteQuantity);
  const secondPart = wallet.slice(-segmenteQuantity);

  return `${firstPart}${repeat('.', 3)}${secondPart}`;
};
