import { toAbsoluteUrl } from '../../../utils';

export const PARTNERS_LIST = [
  {
    id: 'partner-1',
    imageUrl: toAbsoluteUrl('/assets/images/partners/xcapit.png'),
    alt: 'Defiant Logo',
  },
  {
    id: 'partner-2',
    imageUrl: toAbsoluteUrl('/assets/images/partners/caritas.svg'),
    alt: 'Defiant Logo',
  },
  {
    id: 'partner-3',
    imageUrl: toAbsoluteUrl('/assets/images/partners/beexo.svg'),
    alt: 'Defiant Logo',
  },
  {
    id: 'partner-1',
    imageUrl: toAbsoluteUrl('/assets/images/partners/xcapit.png'),
    alt: 'Defiant Logo',
  },
  {
    id: 'partner-2',
    imageUrl: toAbsoluteUrl('/assets/images/partners/caritas.svg'),
    alt: 'Defiant Logo',
  },
  {
    id: 'partner-3',
    imageUrl: toAbsoluteUrl('/assets/images/partners/beexo.svg'),
    alt: 'Defiant Logo',
  },
];
