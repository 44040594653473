export default () => ({
  fullHeight: {
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 115,
    height: '100%',
  },
});
