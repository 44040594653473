export const ivaCondition = {
  enrolled: 'Inscripto',
  final_consumer: 'Consumidor Final',
  independent: 'Independiente',
  exempt: 'Exento',
};

export const personalDataForm = {
  personalData: [
    { name: 'comercialName', selector: 'commercialName' },
    { name: 'legalName', selector: 'legalName' },
    { name: 'businessDescription', selector: 'description' },
    {
      name: 'ivaInfo',
      selector: 'ivaCondition',
      format: (row) => ivaCondition[row.ivaCondition] ?? '',
    },
  ],
  addressData: [
    { name: 'city', selector: 'city' },
    { name: 'street', selector: 'streetAddress' },
    { name: 'streetNumber', selector: 'streetNumber' },
    { name: 'phoneNumber', selector: 'telephoneNumber' },
    { name: 'cpCode', selector: 'postalCode' },
    { name: 'floor', selector: 'floor' },
  ],
  bankData: [
    { name: 'accountHolder', selector: 'accountHolder' },
    { name: 'identification', selector: 'identification' },
    { name: 'asociatedMail', selector: 'mail' },
    { name: 'country', selector: 'country' },
    { name: 'alias', selector: 'alias' },
    { name: 'bankName', selector: 'name' },
    { name: 'accountNumber', selector: 'accountNumber' },
    { name: 'cbu', selector: 'cbu' },
    { name: 'currency', selector: 'currencyId' },
  ],
  walletData: [
    { name: 'network', selector: 'crypto_network' },
    { name: 'currency', selector: 'currency_id' },
    { name: 'walletAddress', selector: 'address' },
  ],
};

export const documentPairs = {
  businessDocument: 'Documento de Negocio',
  sourceOfFounds: 'Fuente de fondos',
  service: 'Servicio',
  billing: 'Factura',
  frontDocument: 'Frente del documento',
  backDocument: 'Reverso del documento',
  dniSelfie: 'Fot del documento',
};
