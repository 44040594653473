import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause5 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        5. Funcionamiento del servicio de gestores Condiciones del Mandato.
        (Kriptonians)
      </Typography>
      <Typography className={classes.text}>
        Cuando un Usuario registrado como Cliente necesite ayuda con una
        gestión, deberá dirigirse a la Plataforma de KriptonMarket y solicitar
        el servicio mediante dichos medios telemáticos. El servicio de
        mensajería básico consiste en la recogida de un producto y su posterior
        entrega, ambas direcciones establecidas por el Usuario, siempre que se
        encuentren exclusivamente dentro del territorio de actuación de
        KriptonMarket. Asimismo, el Usuario puede solicitar al Kriptonian que
        compre presencialmente productos en su nombre, los recoja y los entregue
        en las direcciones consignadas, a través del mandato, el Kriptonian, se
        compromete a adquirir los productos encomendados por el Usuario en su
        nombre, según las indicaciones y especificaciones brindadas por este
        último. <br />
        El Usuario es el único responsable en la correcta consignación de las
        direcciones de entrega y recogida en la Plataforma, por lo que exime a
        KriptonMarket y al Kriptonian de cualquier negligencia o error en la
        recogida o entrega del pedido derivada de la consignación errónea de
        cualquiera de dichas direcciones. Como consecuencia de ello, será el
        Usuario quién deba asumir el costo derivado de la incorrecta
        consignación de las direcciones de entrega y recogida en la Plataforma.
        <br />
        El Usuario deberá proporcionar toda la información, del modo más
        detallado posible, respecto al servicio objeto del encargo, y en su
        caso, en relación con el producto que solicite comprar al Kriptonian en
        su nombre en las tiendas físicas.
      </Typography>
      <Typography className={classes.text}>
        El Usuario exime a KriptonMarket y al Kriptonian de cualquier
        negligencia o error en las indicaciones que consigne para la compra del
        producto que solicite comprar en su nombre a las tiendas físicas. Como
        consecuencia de ello, será el Usuario quién deba asumir el costo
        derivado de la incorrecta consignación de las indicaciones de los
        productos en la Plataforma (i.e. dirección incorrecta, producto
        incorrecto)
      </Typography>
      <Typography className={classes.text}>
        En la medida que en la Plataforma no conste el precio del producto/y o
        servicio, el Usuario podrá fijar un precio aproximado. En este caso, el
        servicio o producto podrá ser adquirido presencialmente por el
        Kriptonian de acuerdo con lo que el Usuario haya estimado y nunca por
        una cantidad superior al 30% de dicha estimación. En el caso que el
        precio sea más alto, el Kriptonian se comunicará con el Usuario para
        informarle de esta situación y será este último quién tome la decisión
        final de proceder o no con la compra presencial en tienda
      </Typography>
      <Typography className={classes.text}>
        Si el producto y/o servicio no se encuentra disponible, el Kriptonian
        deberá dar aviso al Cliente para exponerle las opciones. En caso de que
        el Cliente no se encuentre de acuerdo con ninguna de las opciones
        expuestas por el Kriptonian y, consecuentemente, no esté interesado en
        las opciones alternativas, deberá asumir la política de cancelación
        expuesta en las presentes Condiciones Generales de Uso (en el apartado
        nueve). Si el Cliente no responde al aviso, el Kriptonian esperará 10
        minutos antes de marcharse. En los supuestos en los que el Cliente no se
        encontrase en el lugar consignado para la entrega, el Kriptonian
        conservará el producto durante 24 horas, o 10 minutos en el caso de
        productos perecederos.Asimismo, el Cliente deberá asumir el 100% del
        costo del servicio de mensajería básico así como del precio del producto
        en el caso de que se haya adquirido o contratado alguno a través del
        Kriptonian por encargo del Cliente, y deberá volver a pagar otro
        servicio para recibir los productos no entregados. El Kriptonian, en
        ningún caso, será responsable del deterioro o de la caducidad del
        producto objeto del encargo. <br />
        Una vez finalizado el encargo, en el caso de que se haya solicitado la
        adquisición presencial de un producto, el Kriptonian entregará al
        Cliente el recibo físico correspondiente a dicho producto. Si el recado
        tiene por objeto la mera entrega de un producto, el Kriptonian lo
        entregará al Cliente en el lugar y momento exacto en que este último le
        indique. Todo ello sin perjuicio del recibo electrónico por el servicio
        que recibirá el Cliente en la dirección de correo asociado a su cuenta.
      </Typography>
    </Box>
  );
};

export default Clause5;
