export default (theme) => ({
  container: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  icon: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(40),
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(4),
  },
  infoText: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
  },
});
