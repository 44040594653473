export default (theme) => ({
  root: {
    display: 'flex',
  },
  logo: {
    height: 20,
    marginRight: theme.spacing(1),
    objectFit: 'contain',
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: '#9F9F9F',
  },
  textAllow: {
    color: '#000000',
  },
});
