export default (theme) => ({
  card: {
    borderRadius: 24,
    boxShadow: '0px 5px 6px #00000029',
    height: '100%',
    margin: 0,
    maxWidth: 376,
    pointerEvents: 'all',
    textAlign: 'center',
    width: '100%',

    '&::before': {
      display: 'none',
    },

    [theme.breakpoints.up('md')]: {
      pointerEvents: 'none',
    },
  },
  cardExpanded: {
    margin: '0 !important',
  },
  cardTitleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    margin: theme.spacing(2.5, 0),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  cardTitleIcon: {
    height: 40,
    marginBottom: 0,
    marginRight: theme.spacing(1),
    width: 32,

    [theme.breakpoints.up('md')]: {
      height: 65,
      marginBottom: theme.spacing(3),
      marginRight: 0,
      width: 52,
    },
  },
  cardTitleText: {
    textTransform: 'uppercase',
  },
  cardDescriptionText: {
    fontSize: theme.typography.pxToRem(14),
  },
});
