import React from 'react';
import PropTypes from 'prop-types';

import { getComponent } from './utils';

const RenderFields = ({ component, properties }) => {
  const Node = getComponent(component);

  if (!Node) return null;

  return <Node {...properties} />;
};

RenderFields.propTypes = {
  component: PropTypes.string.isRequired,
  properties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
    label: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
  }).isRequired,
};

export default RenderFields;
