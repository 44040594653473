import React, { useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Box,
  ListItemText,
} from '@material-ui/core';
import Map, {
  NavigationControl,
  FullscreenControl,
  Marker,
} from 'react-map-gl';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { throttle } from 'lodash';
import maplibreglWorker from 'maplibre-gl/dist/maplibre-gl-csp-worker';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/extensions, import/no-unresolved
import maplibregl from '!maplibre-gl';

import PlacesAutocomplete from '../PlacesAutocomplete';
import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';
import MarkerIcon from '../Icons/Marker';

import { PATHS } from '../../../constants/paths';
import { toAbsoluteUrl, getHelperText, hasError } from '../../../utils';
import { LOCAL_KINDS } from '../../../constants/localeKinds';
import styles from './styles';

const useStyles = makeStyles(styles);

maplibregl.workerClass = maplibreglWorker;

const getInitialValues = (data) => ({
  name: data?.name ?? '',
  description: data?.description ?? '',
  kind: data?.kind ?? '',
  address: data?.address ?? '',
  addressCoords:
    data?.latitude && data.longitude
      ? {
          lat: Number(data.latitude),
          lng: Number(data?.longitude),
        }
      : null,
  facebook: data?.json_data?.social_networks?.facebook ?? '',
  instagram: data?.json_data?.social_networks?.instagram ?? '',
});

const LocalForm = ({
  data,
  onSubmit,
  loadingSubmit,
  redirectToList,
  title,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const history = useHistory();

  const refAutocomplete = useRef(null);

  const getCoordAddres = useMemo(
    () =>
      throttle((request, callback) => {
        new window.google.maps.Geocoder().geocode(request, callback);
      }, 200),
    [],
  );

  const executeSubmit = useCallback(
    async (values, { resetForm, setSubmitting }) => {
      await onSubmit(values);

      setSubmitting(false);

      if (redirectToList) {
        resetForm();
        refAutocomplete.current?.resetAutocomplete();

        history.push(PATHS.myStoreLocales.path);
      }
    },
    [onSubmit, redirectToList, history],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(t('validations.required')),
        description: Yup.string()
          .max(150, t('validations.max'))
          .required(t('validations.required')),
        kind: Yup.string().required(t('validations.required')),
        address: Yup.string().required(t('validations.required')),
        addressCoords: Yup.mixed().required(),
        facebook: Yup.string().notRequired(),
        instagram: Yup.string().notRequired(),
      }),
    [t],
  );

  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    submitForm,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues: getInitialValues(data),
    validationSchema,
    onSubmit: executeSubmit,
  });

  const handleChangeAddress = useCallback(
    (newValue) => {
      setFieldValue('address', newValue?.description ?? '');
      setFieldValue('addressCoords', null);

      getCoordAddres({ placeId: newValue?.place_id }, (results) => {
        if (results) {
          setFieldValue('addressCoords', results[0].geometry.location.toJSON());
        }
      });
    },
    [getCoordAddres, setFieldValue],
  );

  const handleBlurAddress = useCallback(() => {
    setFieldTouched('address', true);
  }, [setFieldTouched]);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title}>{title}</Typography>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label={t('store.comercialName')}
              autoComplete="off"
              helperText={getHelperText(
                touched,
                errors,
                'name',
                t('store.comercialNameHelperText'),
              )}
              error={hasError(touched, errors, 'name')}
              {...getFieldProps('name')}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label={t('store.description')}
              autoComplete="off"
              helperText={getHelperText(
                touched,
                errors,
                'description',
                t('store.descriptionHelperText'),
              )}
              error={hasError(touched, errors, 'description')}
              {...getFieldProps('description')}
              multiline
              rows={3}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label={t('store.kind')}
              autoComplete="off"
              helperText={getHelperText(touched, errors, 'kind')}
              error={hasError(touched, errors, 'kind')}
              {...getFieldProps('kind')}
              select
            >
              {LOCAL_KINDS.map((kind) => (
                <MenuItem key={`local-kind-${kind.key}`} value={kind.value}>
                  <Box
                    style={{ display: 'flex', gap: 8, alignItems: 'center' }}
                  >
                    <img
                      // eslint-disable-next-line max-len
                      src={`https://public-kriptonmarket.s3.amazonaws.com/img/map/icons/${kind.value}.svg`}
                      alt={t(`storeKinds.${kind.key}`)}
                      width={18}
                      height={18}
                    />
                    <ListItemText
                      primary={t(`storeKinds.${kind.key}`)}
                      style={{ margin: 0 }}
                      primaryTypographyProps={{ variant: 'inherit' }}
                    />
                  </Box>
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <PlacesAutocomplete
              ref={refAutocomplete}
              id="local-places-autocomplete"
              onChange={(_e, newVal) => handleChangeAddress(newVal)}
              onBlur={handleBlurAddress}
              error={hasError(touched, errors, 'address')}
              helperText={getHelperText(touched, errors, 'address')}
              initialValueRequest={data?.address}
              label={t('store.address')}
            />
          </Grid>

          {values.addressCoords && (
            <Grid item xs={12}>
              <Box className={classes.mapContainer}>
                <Map
                  mapLib={maplibregl}
                  // eslint-disable-next-line max-len
                  mapStyle="https://api.maptiler.com/maps/streets/style.json?key=xa2P0Rgu5bPQz8kHhtbe"
                  initialViewState={{
                    longitude: values.addressCoords?.lng,
                    latitude: values.addressCoords?.lat,
                    zoom: 14,
                  }}
                  attributionControl={false}
                >
                  <FullscreenControl position="bottom-left" />
                  <NavigationControl
                    position="bottom-left"
                    showCompass={false}
                  />

                  <Marker
                    longitude={values.addressCoords?.lng}
                    latitude={values.addressCoords?.lat}
                  >
                    <MarkerIcon fontSize="large" />
                  </Marker>
                </Map>
              </Box>
            </Grid>
          )}

          <Typography className={classes.text}>{t('store.rrss')}</Typography>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              autoComplete="off"
              helperText={getHelperText(touched, errors, 'facebook')}
              error={hasError(touched, errors, 'facebook')}
              {...getFieldProps('facebook')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={toAbsoluteUrl('/assets/icons/rrss/ic_facebook.png')}
                      alt="Facebook Logo"
                      className={classes.imgAdornment}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              autoComplete="off"
              helperText={getHelperText(touched, errors, 'instagram')}
              error={hasError(touched, errors, 'instagram')}
              {...getFieldProps('instagram')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={toAbsoluteUrl('/assets/icons/rrss/ic_instagram.png')}
                      alt="Instagram Logo"
                      className={classes.imgAdornment}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions className={classes.cardActions}>
        <ButtonWithCirculaProgress
          color="secondary"
          fullWidth
          handleButtonClick={submitForm}
          loading={loadingSubmit || isSubmitting}
          disabled={!dirty}
          label={t('store.save')}
          className={classes.cardActionsButton}
        />
      </CardActions>
    </Card>
  );
};

LocalForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    latitude: PropTypes.string.isRequired,
    longitude: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    json_data: PropTypes.object,
    address: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
    payment_url: PropTypes.string.isRequired,
    focus_map: PropTypes.bool.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
  loadingSubmit: PropTypes.bool,
  redirectToList: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

LocalForm.defaultProps = {
  data: undefined,
  loadingSubmit: false,
  redirectToList: true,
};

export default LocalForm;
