import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  useMediaQuery,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import cn from 'classnames';

import styles from './styles';

const useStyles = makeStyles(styles);

const CardCollapsable = ({ id, iconUrl, title, description }) => {
  const classes = useStyles();
  const isDekstop = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const [expanded, setExpanded] = useState(isDekstop);

  useEffect(() => {
    setExpanded(isDekstop);
  }, [isDekstop]);

  const toggleExpanded = useCallback(
    (_e, isExpanded) => setExpanded(isExpanded),
    [],
  );

  return (
    <Accordion
      key={id}
      classes={{
        root: classes.card,
        expanded: classes.cardExpanded,
      }}
      square
      expanded={expanded}
      onChange={toggleExpanded}
    >
      <AccordionSummary
        aria-controls={`panel-${id}content`}
        id={`panel-${id}-header`}
        expandIcon={isDekstop ? null : <ArrowDropDownIcon color="primary" />}
        classes={{ content: classes.cardTitleContainer }}
      >
        <Box
          component="img"
          src={iconUrl}
          alt={title}
          className={classes.cardTitleIcon}
        />

        <Typography
          component="h3"
          className={cn(classes.cardTitleText, classes.cardDescriptionText)}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="p" className={classes.cardDescriptionText}>
          {description}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

CardCollapsable.propTypes = {
  id: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default CardCollapsable;
