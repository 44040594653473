export default () => ({
  root: {
    minHeight: 285,

    '& .MuiCardContent-root': {
      paddingRight: 0,
      paddingLeft: 0,
      paddingBottom: 0,
    },
  },
  table: {
    height: '100%',
  },
});
