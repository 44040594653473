import React, { useCallback, useMemo } from 'react';
import { makeStyles, Button, Box, Typography, Grid } from '@material-ui/core';
import Chart from 'react-apexcharts';
import { times, random } from 'lodash';
import { useTranslation } from 'react-i18next';

import CardWithProgressLoading from '../CardWithLoadingProgress';
import TitleCards from '../TitleCards';

import { useFetchDataMockDelay } from '../../hooks';
import {
  barChart as barChartOptions,
  pieChart as pieChartOptions,
} from './chartsOptions';

import styles from './styles';

const useStyles = makeStyles(styles);

const getDataRandom = (secuency = 4) => times(secuency, () => random(30, 500));

const SalesStatistics = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  // @TODO: Change for correct
  const handleClick = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('Click cuenta');
  }, []);

  const [loading, data] = useFetchDataMockDelay({
    mock: {
      barChart: [
        { name: 'Doc', data: getDataRandom(3) },
        { name: 'Rbtc', data: getDataRandom(3) },
        { name: 'Dollar on chain', data: getDataRandom(3) },
      ],
      pieChart: getDataRandom(),
      nameCompany: 'Nombre de la Empresa',
    },
    initialState: {},
  });

  const dataBarChart = useMemo(() => data.barChart ?? [], [data]);

  const dataPieChart = useMemo(() => data.pieChart ?? [], [data]);

  return (
    <CardWithProgressLoading
      loading={loading}
      className={classes.root}
      header={<TitleCards title={t(`wallet.salesStatistics`)} paragraph />}
    >
      <Box mb={4}>
        <Chart
          options={barChartOptions}
          series={dataBarChart}
          type="bar"
          height="200"
        />
      </Box>

      <Box mb={4}>
        <Chart
          options={pieChartOptions}
          series={dataPieChart}
          type="donut"
          height="200"
        />
        <TitleCards title="cash in/cash out" />
      </Box>

      <Box>
        <Typography color="primary" variant="body2" align="center" paragraph>
          {data.nameCompany}
        </Typography>
        <Grid container justify="center">
          <Grid item md={7} xs={12}>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              fullWidth
              onClick={handleClick}
            >
              {t(`wallet.account`)}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </CardWithProgressLoading>
  );
};

export default SalesStatistics;
