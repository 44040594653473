import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause18 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        18. Responsabilidad por contenidos.
      </Typography>
      <Typography className={classes.text}>
        KriptonMarket no tiene obligación de controlar y no controla la
        utilización que los Usuarios y/o Clientes hacen de la Plataforma y, por
        consiguiente, no garantiza que los Usuarios y/o Clientes utilicen la
        Plataforma de conformidad con lo establecido en las presentes
        Condiciones Generales de Uso, ni que hagan un uso diligente y/o prudente
        de la misma. KriptonMarket no tiene la obligación de verificar y no
        verifica la identidad de los Usuarios, ni la veracidad, vigencia,
        exhaustividad y/o autenticidad de los datos que los mismos proporcionan.
        KriptonMarket excluye cualquier responsabilidad por los daños y
        perjuicios de toda naturaleza que pudieran deberse a la utilización
        ilícita de la Plataforma por parte de los Usuarios o que puedan deberse
        a la falta de veracidad, vigencia, exhaustividad y/o autenticidad de la
        información que los Usuarios proporcionan a otros Usuarios acerca de sí
        mismos y, en particular, aunque no de forma exclusiva, por los daños y
        perjuicios de toda naturaleza que puedan deberse a la suplantación de la
        personalidad de un tercero efectuada por un Usuario en cualquier clase
        de comunicación realizada a través de la Plataforma. En particular,
        KriptonMarket no se hace responsable del uso de la plataforma así como
        de los pedidos que pudiera realizar un Usuario con los datos de un
        tercero. <br />
        Sin perjuicio de lo anterior, KriptonMarket se reserva la facultad de
        limitar, total o parcialmente, el acceso a la Plataforma a determinados
        Usuarios y Clientes, así como de cancelar, suspender, bloquear o
        eliminar determinado tipo de contenidos, mediante la utilización de
        instrumentos tecnológicos aptos al efecto, si tuviese conocimiento
        efectivo de que la actividad o la información almacenada es ilícita o de
        que lesiona bienes o derechos de un tercero. En este sentido,
        KriptonMarket podrá establecer los filtros necesarios a fin de evitar
        que a través del servicio puedan verterse en la red contenidos ilícitos
        o nocivos. La puesta a disposición por parte de los Usuarios y Clientes
        de contenidos a través de la Plataforma supondrá la cesión a favor de
        KriptonMarket de todos los derechos de explotación derivados de los
        contenidos suministrados en la Plataforma.
      </Typography>
    </Box>
  );
};

export default Clause18;
