import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { PATHS } from '../../../constants/paths';

const AuthRoute = ({ component: CurrentComponent, ...rest }) => {
  const { authToken } = useSelector((state) => state.auth);

  const isAuthorized = useMemo(() => !isEmpty(authToken), [authToken]);

  return (
    <Route
      {...rest}
      render={() =>
        isAuthorized ? (
          <Redirect to={PATHS.wallet.path} />
        ) : (
          <CurrentComponent />
        )
      }
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default AuthRoute;
