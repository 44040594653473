import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { object, string, ref } from 'yup';
import { useTranslation } from 'react-i18next';
import PasswordChecklist from 'react-password-checklist';

import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';
import VisibilityPasswordAdornment from '../VisibilityPasswordAdornment';
import { useLazyRequest, useShowPassword } from '../../hooks';
import { changePassword as changePasswordRequest } from '../../../services/merchantsService';
import { getHelperText, hasError, getTypeShowPassword } from '../../../utils';
import TwoAuthModal from '../2fa';

import styles from './styles';
import { ERRORS_MESSAGE } from '../../../constants/errorMessage';

const useStyles = makeStyles(styles);

const initialValues = {
  oldPassword: '',
  newPassword: '',
  repeatPassword: '',
};

const DialogChangePassword = ({ open, onClose }) => {
  const { t } = useTranslation('common');
  const [open2fa, setOpen2fa] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [disabled, setDisabled] = useState(false);

  const validationSchema = object().shape({
    oldPassword: string().required(t('settings.enterCurrentPassword')),
    newPassword: string().required(t('settings.enterNewPassword')),
    repeatPassword: string()
      .required(t('settings.repeatNewPassword'))
      .oneOf([ref('newPassword')], t('settings.passwordsNotMatch')),
  });

  const [, loading, , changePassword] = useLazyRequest({
    request: changePasswordRequest,
    withPostSuccess: () => {
      enqueueSnackbar(ERRORS_MESSAGE.action(t('settings.successMessage')), {
        variant: 'success',
      });
      setOpen2fa(!open2fa);
      onClose();
    },
    withPostFailure: () =>
      enqueueSnackbar(ERRORS_MESSAGE.action(t('settings.errorMessage')), {
        variant: 'error',
      }),
  });

  const executeChangePassword = useCallback(
    async ({ oldPassword, newPassword }, { setSubmitting, resetForm }) => {
      await changePassword({ currentPassword: oldPassword, newPassword });
      setSubmitting(false);
      setTimeout(() => {
        resetForm();
      }, 500);
    },
    [changePassword],
  );

  const {
    values,
    touched,
    errors,
    handleSubmit,
    getFieldProps,
    resetForm,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: executeChangePassword,
  });

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
    setOpen2fa(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, resetForm]);

  const close2faModal = useCallback(() => {
    setOpen2fa(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOpen2fa]);

  const [showOldPassword, toggleOldPassword] = useShowPassword();
  const [showNewPassword, toggleNewPassword] = useShowPassword();
  const [showRepeatPassword, toggleRepeatPassword] = useShowPassword();

  const isValidPassword = () => setDisabled(!disabled);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="dialog-change-password"
      open={open}
      keepMounted
      maxWidth="sm"
      scroll="body"
    >
      <TwoAuthModal
        open={open2fa}
        onClose={close2faModal}
        loadingFinsh={loading}
        exceuteSubmit={handleSubmit}
      />
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle
          id="dialog-change-password"
          className={classes.dialogTitleTypography}
          component="h6"
        >
          {t(`settings.changePassword`)}
        </DialogTitle>

        <DialogContent className={classes.dialogContentContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label={t(`settings.currentPassword`)}
                type={getTypeShowPassword(showOldPassword)}
                autoComplete="new-password"
                helperText={getHelperText(touched, errors, 'oldPassword')}
                error={hasError(touched, errors, 'oldPassword')}
                InputProps={{
                  endAdornment: (
                    <VisibilityPasswordAdornment
                      show={showOldPassword}
                      toggleShow={toggleOldPassword}
                      size="small"
                    />
                  ),
                }}
                {...getFieldProps('oldPassword')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label={t(`settings.newPassword`)}
                type={getTypeShowPassword(showNewPassword)}
                autoComplete="new-password"
                helperText={getHelperText(touched, errors, 'newPassword')}
                error={hasError(touched, errors, 'newPassword')}
                InputProps={{
                  endAdornment: (
                    <VisibilityPasswordAdornment
                      show={showNewPassword}
                      toggleShow={toggleNewPassword}
                      size="small"
                    />
                  ),
                }}
                {...getFieldProps('newPassword')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label={t(`settings.repeatNewPass`)}
                type={getTypeShowPassword(showRepeatPassword)}
                autoComplete="new-password"
                helperText={getHelperText(touched, errors, 'repeatPassword')}
                error={hasError(touched, errors, 'repeatPassword')}
                InputProps={{
                  endAdornment: (
                    <VisibilityPasswordAdornment
                      show={showRepeatPassword}
                      toggleShow={toggleRepeatPassword}
                      size="small"
                    />
                  ),
                }}
                {...getFieldProps('repeatPassword')}
              />
            </Grid>

            <Grid item xs={12}>
              <PasswordChecklist
                rules={['length', 'specialChar', 'number', 'capital', 'match']}
                minLength={8}
                onChange={isValidPassword}
                value={values.newPassword}
                valueAgain={values.repeatPassword}
                messages={{
                  length: t('registration.lenght'),
                  specialChar: t('registration.especialChair'),
                  number: t('registration.number'),
                  capital: t('registration.capital'),
                  match: t('registration.match'),
                }}
                iconSize={12}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className={classes.dialogActionsContainer}>
          <Button onClick={handleClose} color="default">
            {t(`settings.cancel`)}
          </Button>

          <ButtonWithCirculaProgress
            handleButtonClick={() => setOpen2fa(true)}
            color="secondary"
            disabled={!isValid || disabled}
            loading={loading}
            label={t(`settings.change`)}
            className={classes.sendButton}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

DialogChangePassword.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DialogChangePassword;
