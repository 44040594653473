import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause3 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        3. Partes. Acceso e Identidad
      </Typography>
      <Typography className={classes.text}>
        Se puede operar dentro de KriptonMarket de tres modos: Como “Usuario”,
        “Cliente” o “Kriptonian”. <br />
        Para poder ser Cliente de la Plataforma es indispensable que se cumplan
        los siguientes requisitos:
      </Typography>
      <Typography className={classes.text}>
        -Tener la capacidad legal suficiente para realizar operaciones de
        compra/venta. <br />
        -Aceptar las presentes Condiciones de Uso. <br />
        -Aceptar la Política de privacidad y protección de datos.
      </Typography>
      <Typography className={classes.text}>
        La identidad de los Usuarios de la Plataforma se halla respaldada por
        los siguientes medios, dependiendo del sistema de compra empleado:
      </Typography>
      <Typography className={classes.text}>
        1- En caso de realizar la compra directa mediante la API de Bitex, por
        la autenticación de la transacción mediante Bitcoin, denominada “hash”,
        y la misma es prueba suficiente para validar la transacción y la
        identidad de las partes de la misma. Además se contará con el respaldo
        identificativo de los datos personales aportados al momento previo a la
        facturación.
      </Typography>
      <Typography className={classes.text}>
        2 – En caso de realizar la compra mediante la asistencia de un
        Kriptonian, el Usuario aportará, en el chat, los datos de identificación
        personales (Nombre completo y número de documento de identidad), los
        cuales serán empleados para la identificación al momento de la entrega
        y, de ser necesario para la facturación, serán brindados al Cliente.
      </Typography>
      <Typography className={classes.text}>
        La identidad de los Clientes se registra con la carga de cada producto o
        servicio ofrecido.
      </Typography>
      <Typography className={classes.text}>
        En virtud de mantener la privacidad de las partes, no existe registro de
        Usuarios y Clientes, más allá del vinculado a cada transacción y a cada
        ofrecimiento de bienes o servicios. Como excepción KriptonMarket
        proporcionará los datos de la transacción a las partes contratantes para
        permitir el contacto directo entre ellas.
      </Typography>
      <Typography className={classes.text}>
        Con cada transacción realizada en KriptonMarket se está aceptando tanto
        estas Condiciones de Uso, como la Política de privacidad y protección de
        datos.
      </Typography>

      <Typography component="h4" className={classes.textBold}>
        3.1 Usuario
      </Typography>
      <Typography className={classes.text}>
        Se denomina usuario a aquel que adquiere cualquiera de los productos o
        servicios ofertados en la Plataforma.
      </Typography>

      <Typography component="h4" className={classes.textBold}>
        3.1.1 Registro
      </Typography>
      <Typography className={classes.text}>
        Para operar en carácter de Usuario no es necesario el registro en la
        Plataforma, los datos de identificación y domicilio de recepción del
        envío se realizan con cada transacción y permanecen en el sistema hasta
        que la misma se ha completado satisfactoriamente.
      </Typography>
      <Typography className={classes.text}>
        Una vez verificado el éxito de la transacción se eliminarán los datos
        personales identificativos, del Usuario, vinculados a la misma.
      </Typography>

      <Typography component="h4" className={classes.textBold}>
        3.2 Cliente
      </Typography>
      <Typography className={classes.text}>
        Se denomina Cliente a quien oferta productos o servicios dentro de la
        plataforma. Un cliente puede ser, indistintamente, una persona física o
        de existencia ideal.
      </Typography>

      <Typography component="h4" className={classes.textBold}>
        3.2.1 Registro
      </Typography>
      <Typography className={classes.text}>
        El registro, en la Plataforma, de los Clientes, es obligatorio. Se
        requerirán datos personales y fiscales necesarios para operar con
        transparencia dentro de la plataforma.
      </Typography>
      <Typography className={classes.text}>
        Para poder completar el registro en la Plataforma el Cliente deberá
        proporcionar algunos datos como: nombre del Cliente, dirección de correo
        electrónico, teléfono, CBU, etc. Una vez completado el registro, todo
        Cliente podrá acceder a su perfil y completarlo, y editarlo y/o darse de
        baja según estime conveniente.
      </Typography>
      <Typography className={classes.text}>
        El Cliente garantiza que todos los datos sobre su identidad y
        legitimidad facilitados a KriptonMarket en sus formularios de registro
        de la Plataforma son veraces, exactos y completos. Asimismo, se
        compromete a mantener actualizados sus datos. <br />
        En el supuesto en que el Cliente facilite cualquier dato falso, inexacto
        o incompleto o si KriptonMarket considera que existen motivos fundados
        para dudar sobre la veracidad, exactitud e integridad de los mismos,
        KriptonMarket podrá denegar el acceso y uso presente o futuro de la
        Plataforma o de cualquiera de sus contenidos y/o servicios. Al darse de
        alta en la Plataforma, el Cliente seleccionará un nombre de usuario
        (username) y una clave de acceso (password). Tanto el username como el
        password son estrictamente confidenciales, personales e intransferibles.
        <br />
        KriptonMarket no puede garantizar la identidad de los Clientes
        registrados, por tanto, no será responsable del uso de la identidad de
        un Cliente registrado por terceros no registrados. Los Clientes se
        obligan a poner inmediatamente en conocimiento de KriptonMarket la
        sustracción, divulgación o pérdida de su username o password,
        comunicándolo a través del Formulario de Contacto o las otras opciones
        brindadas por la Plataforma. <br />
        En caso de olvido, o sospecha de sustracción, de la contraseña la misma
        se modificara previo contacto por los medios indicados en el párrafo
        anterior.
      </Typography>

      <Typography component="h4" className={classes.textBold}>
        3.2.2 Tarifa
      </Typography>
      <Typography className={classes.text}>
        El Cliente entiende y consciente que operar, de dicho modo, en la
        Plataforma no es gratuito y se atiene a los modos y proporciones del
        anexo “A – Tarifas”.
      </Typography>

      <Typography component="h4" className={classes.textBold}>
        3.3 Kriptonian
      </Typography>
      <Typography className={classes.text}>
        Se denomina Kriptonian a toda persona física independiente que ofrece
        servicio de mandado y/o mensajería por la Plataforma.
      </Typography>

      <Typography component="h4" className={classes.textBold}>
        3.3.1 Registro y capacitación
      </Typography>
      <Typography className={classes.text}>
        Se requerirá el registro, en la base de datos de la Plataforma, de
        aquellos que quieran ofrecer su servicio de Kriptonian. Los mismos
        incluirán los datos de inscripción como aportantes
        autónomos/monotributistas.
      </Typography>
      <Typography className={classes.text}>
        Será condición indispensable para calificar como Kriptonian: <br />
        1- Ser monotributista. <br />
        2- Tener conocimientos verificables sobre criptomonedas. KriponMarket
        realizará las capacitaciones y evaluaciones necesarias para asegurar
        dichos conocimientos.
      </Typography>
      <Typography className={classes.text}>
        Ambas condiciones serán verificadas por KriptonMarket antes de conceder
        la calificación de Kriptonian, para seguridad de los Clientes y Usuarios
      </Typography>
      <Typography className={classes.text}>
        KriptonMarket garantiza que el Kriponian afectado a la transacción posee
        los conocimientos necesarios, sobre el producto o servicio solicitado,
        para asumir la responsabilidad de la misma. En caso contrario se
        derivara a otro Kriptonian en condiciones de asumirla
      </Typography>

      <Typography component="h4" className={classes.textBold}>
        3.3.2 Remuneración
      </Typography>
      <Typography className={classes.text}>
        El Kriptonian obtiene retribución, del Cliente, por cada transacción en
        la que participe por el monto fijado según el “Anexo B” (Tarifas de
        Kriptonians).
      </Typography>
    </Box>
  );
};

export default Clause3;
