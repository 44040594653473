export default (theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(1),
    height: '100%',
    flexDirection: 'row',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  card: {
    width: '100%',
    height: '100%',

    '& .MuiCardContent-root': {
      padding: theme.spacing(3),
    },
  },
  cardLoading: {
    '& .MuiCardContent-root': {
      height: '100%',
    },
  },
  cardOne: {
    maxWidth: '65%',
    flexBasis: '65%',

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  cardTwo: {
    maxWidth: '35%',
    flexBasis: '35%',

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  storeCard: {
    padding: '16px 30px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 17,
    fontFamily: 'Montserrat',
  },
  text: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    marginBottom: 14,
  },
  selectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});
