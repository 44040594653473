import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography, Divider } from '@material-ui/core';
import cn from 'classnames';

import styles from './styles';

const useStyles = makeStyles(styles);

const TitleInfoPage = ({ title, gutterTop, gutterBottom }) => {
  const classes = useStyles();

  return (
    <Box
      className={cn(classes.root, {
        [classes.gutterTop]: gutterTop,
        [classes.gutterBottom]: gutterBottom,
      })}
    >
      <Box className={classes.containerTitle}>
        <Typography component="h1" className={classes.typography}>
          {title}
        </Typography>
        <Divider className={cn(classes.divider)} />
      </Box>
    </Box>
  );
};

TitleInfoPage.propTypes = {
  title: PropTypes.string.isRequired,
  gutterTop: PropTypes.bool,
  gutterBottom: PropTypes.bool,
};

TitleInfoPage.defaultProps = {
  gutterTop: true,
  gutterBottom: true,
};

export default TitleInfoPage;
