export default (theme) => ({
  container: {
    marginBottom: theme.spacing(2),

    '& :last-child': {
      marginBottom: 0,
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(24),
    marginBottom: theme.spacing(2),
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(20),
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(2),
    textAlign: 'justify',
  },
  textBold: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
  },
  stepper: {
    backgroundColor: 'transparent',
    marginLeft: 'auto',
    marginTop: theme.spacing(4),
    marginRight: 'auto',
    maxWidth: 400,
  },
  dotActive: {
    backgroundColor: theme.palette.secondary.main,
  },
});
