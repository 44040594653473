import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Section from '../Section';
import Timeline from '../TimeLine';

import { toAbsoluteUrl } from '../../../utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const OurServicesSection = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const timeLineData = [
    {
      id: 'tmitem1',
      content: t('home.ourServices.timeline1'),
    },
    {
      id: 'tmitem2',
      content: t('home.ourServices.timeline2'),
    },
    {
      id: 'tmitem3',
      content: t('home.ourServices.timeline3'),
    },
    {
      id: 'tmitem4',
      content: t('home.ourServices.timeline4'),
    },
  ];

  return (
    <Section>
      <Box className={classes.root}>
        <Box className={classes.containerItem}>
          <Box className={classes.coverContainer}>
            <Box
              component="img"
              src={toAbsoluteUrl('/assets/images/accept-crypto-cover.png')}
              alt={t('home.ourServices.title')}
              className={classes.coverImg}
            />
          </Box>

          <Box className={classes.descriptionContainer}>
            <Typography className={classes.descriptionTitle} component="h5">
              {t('home.ourServices.title')}
            </Typography>

            <Timeline
              data={timeLineData}
              align="left"
              disableOppositeContent
              className={classes.descriptionInfoText}
            />
          </Box>
        </Box>

        <Box
          className={cn(
            classes.containerItem,
            classes.containerItemOrderInverted,
          )}
        >
          <Box className={classes.coverContainer}>
            <Box
              component="img"
              src={toAbsoluteUrl('/assets/images/cash-out-cover.png')}
              alt="Cash-Out"
              className={classes.coverImg}
            />
          </Box>

          <Box className={classes.descriptionContainer}>
            <Typography className={classes.descriptionTitle} component="h5">
              {t('home.withdrawals.title')}
            </Typography>

            <Typography className={classes.descriptionInfoText}>
              {t('home.withdrawals.description')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Section>
  );
};

export default OurServicesSection;
