import React from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
    stroke: 'currentColor',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: 2.5,
  },
}));

const TelegramIcon = (props) => {
  const classes = useStyles();

  return (
    <SvgIcon {...props} viewBox="0 0 39.787 33.11">
      <g transform="translate(-104.513 -306.628)">
        <line
          className={classes.root}
          x2="3.838"
          y2="10.364"
          transform="translate(115.561 326.455)"
        />
        <path
          className={classes.root}
          d="M116.29,323.069l-.384,8.554,5.5-4.668Z"
          transform="translate(3.547 5.305)"
        />
        <path
          className={classes.root}
          // eslint-disable-next-line max-len
          d="M141.508,307.975,106.49,321.28a1.128,1.128,0,0,0,.039,2.123l9.031,3.052L136.123,314.1l-16.286,14.271L132.516,338a2.308,2.308,0,0,0,3.633-1.278L143,309.3A1.127,1.127,0,0,0,141.508,307.975Z"
          transform="translate(0 0)"
        />
      </g>
    </SvgIcon>
  );
};

export default TelegramIcon;
