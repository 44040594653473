import { useState, useCallback } from 'react';

import { useDialogToggle } from './useDialogToggle';

export const useDialogToggleWithData = (
  initialToggle = false,
  initialData = {},
) => {
  const [openDialog, toggleDialog] = useDialogToggle(initialToggle);
  const [data, setData] = useState(initialData);

  const handleOpenDialog = useCallback(
    (newData) => {
      setData(newData);
      toggleDialog();
    },
    [toggleDialog],
  );

  const handleCloseDialog = useCallback(() => {
    setTimeout(() => {
      setData(initialData);
    }, 500);

    toggleDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleDialog]);

  return [openDialog, data, handleOpenDialog, handleCloseDialog];
};
