import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause12 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        12. Obligaciones del Cliente y del Usuario
      </Typography>
      <Typography className={classes.text}>
        Los Clientes son completamente responsables del acceso y correcto uso de
        su perfil y demás contenidos de la Plataforma con sujeción a la
        legalidad vigente, sea nacional o internacional del País desde el que
        hace uso de la Plataforma, así como a los principios de buena fe, a la
        moral, buenas costumbres y orden público. Y específicamente, adquiere el
        compromiso de observar diligentemente las presentes Condiciones
        Generales de Uso.
      </Typography>
      <Typography className={classes.text}>
        Los Clientes se abstendrán de usar su perfil y el resto de contenidos de
        la Plataforma con fines o efectos ilícitos y que sean lesivos de los
        derechos e intereses de terceros, o que de cualquier forma puedan dañar,
        inutilizar, afectar o deteriorar la Plataforma, sus contenidos y sus
        servicios. Asimismo, queda prohibido impedir la normal utilización o
        disfrute de la Plataforma a otros Usuarios.
      </Typography>
      <Typography className={classes.text}>
        KriptonMarket no podrá ser considerada responsable editorial, y declara
        expresamente que no se identifica con ninguna de las opiniones que
        puedan emitir los Usuarios y Clientes de la Plataforma, de cuyas
        consecuencias se hace enteramente responsable el emisor de las mismas.
      </Typography>
      <Typography className={classes.text}>
        Quienes incumplan tales obligaciones responderán de cualquier perjuicio
        o daño que ocasionen KriptonMarket no responderá de ninguna
        consecuencia, daño o perjuicio que pudiera derivarse de dicho acceso o
        uso ilícito por parte de terceros. <br />
        En general, los Clientes y Usuarios se comprometen, a título enunciativo
        y no taxativo, a:
      </Typography>
      <Typography className={classes.text}>
        - No alterar o modificar, total o parcialmente la Plataforma, eludiendo,
        desactivando o manipulando de cualquier otra las funciones o servicios
        de la misma.
        <br />
        - No infringir los derechos de propiedad industrial e intelectual o las
        normas reguladoras de la protección de datos de carácter personal.
        <br />
        - No usar la Plataforma para injuriar, difamar, intimidar, violar la
        propia imagen o acosar a otros Usuarios.
        <br />
        - No acceder a las cuentas de correo electrónico de otros Usuarios y/o
        Clientes.
        <br />
        - No introducir virus informáticos, archivos defectuosos, o cualquier
        otro programa informático que pueda provocar daños o alteraciones en los
        contenidos o sistemas de KriptonMarket o terceras personas.
        <br />
        - No remitir correos electrónicos con carácter masivo y/o repetitivo a
        una pluralidad de personas, ni enviar direcciones de correo electrónico
        de terceros sin su consentimiento.
        <br />
        - No realizar acciones publicitarias de bienes o servicios sin el
        consentimiento de KriptonMarket.
        <br />
      </Typography>
      <Typography className={classes.text}>
        Cualquier Usuario y/o Cliente podrá reportar a otro Usuario y/o Cliente
        cuando considere que está incumpliendo las presentes Condiciones
        Generales de Uso, asimismo todos los Usuarios y/o Clientes pueden
        informar a KriptonMarket de cualquier abuso o vulneración de las
        presentes condiciones, a través del Formulario de Contacto.
        KriptonMarket verificará este reporte, a la mayor brevedad posible, y
        adoptará las medidas que considere oportunas, reservándose el derecho a
        retirar y/o suspender a cualquier Usuario y/o Cliente de la Plataforma
        por el incumplimiento de las presentes Condiciones Generales de Uso.
        Asimismo KriptonMarket se reserva el derecho a retirar y/o suspender
        cualquier mensaje con contenido ilegal u ofensivo, sin necesidad de
        previo aviso o posterior notificación.
      </Typography>
    </Box>
  );
};

export default Clause12;
