import React from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Section from '../Section';
import CardCollapsable from './CardCollapsable';

import { OUR_PROPOSAL } from './data';
import { toAbsoluteUrl } from '../../../utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const ProposalList = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <Box className={classes.root}>
      <Box
        component="img"
        src={toAbsoluteUrl('/assets/images/tag-dev-mobile-cover.png')}
        alt="Cover Dev mobile"
        className={classes.coverMobile}
      />

      <Box
        component="img"
        src={toAbsoluteUrl('/assets/images/tag-dev-desktop-cover.png')}
        alt="Cover Dev desktop"
        className={classes.coverDesktop}
      />

      <Section>
        <Box className={classes.container}>
          <Box className={classes.infoContainer}>
            <Typography component="h2" className={classes.infoTitle}>
              {t('home.integrations.title')}
            </Typography>

            <Typography className={classes.infoDescription}>
              {t('home.integrations.firstDescription')}
            </Typography>

            <Typography className={classes.infoDescription}>
              {t('home.integrations.secondDescription')}
            </Typography>
          </Box>

          <Box className={classes.cardListContainer}>
            {OUR_PROPOSAL.map(({ id, iconUrl, title, description }) => (
              <CardCollapsable
                key={id}
                id={id}
                iconUrl={iconUrl}
                title={t(`home.integrations.${title}`)}
                description={t(`home.integrations.${description}`)}
              />
            ))}
          </Box>
        </Box>
      </Section>
    </Box>
  );
};

export default ProposalList;
