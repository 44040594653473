import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clause16 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h3" className={classes.subTitle}>
        16. Baja del Cliente
      </Typography>
      <Typography className={classes.text}>
        El Cliente podrá darse de baja de la Plataforma comunicándolo mediante
        un correo electrónico a través del Formulario de Contacto.
      </Typography>
    </Box>
  );
};

export default Clause16;
