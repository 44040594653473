export const getProperty = (row, selector, format, rowIndex) => {
  if (!selector) {
    return null;
  }

  if (typeof selector !== 'string' && typeof selector !== 'function') {
    throw new Error(
      'selector must be a . delimited string eg (my.property) or function (e.g. row => row.field',
    );
  }

  if (format && typeof format === 'function') {
    return format(row, rowIndex);
  }

  if (selector && typeof selector === 'function') {
    return selector(row, rowIndex);
  }

  return selector.split('.').reduce((acc, part) => {
    // O(n2) when querying for an array (e.g. items[0].name)
    // Likely, the object depth will be reasonable enough that performance is not a concern
    const arr = part.match(/[^\]\\[.]+/g);
    if (arr.length > 1) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < arr.length; i++) {
        return acc[arr[i]][arr[i + 1]];
      }
    }

    return acc[part];
  }, row);
};
