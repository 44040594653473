export default (theme) => ({
  container: {
    borderBottom: `1px solid ${theme.palette.common.white}`,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 12,
    paddingBottom: 12,
    paddingLeft: 8,
  },
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    cursor: 'pointer',
    border: 0,
    background: 'transparent',
    textAlign: 'left',
  },
  headerTitleContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    height: 20,
    marginRight: 20,
    width: 20,
  },
  titleIconContainer: {
    alignItems: 'center',
    display: 'flex',
    height: 20,
    justifyContent: 'center',
    marginRight: 20,
    width: 20,

    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  titleText: {
    color: theme.palette.common.white,
    flex: 1,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    margin: 0,
  },
  headerArrowButton: {
    alignItems: 'center',
    color: theme.palette.common.white,
    display: 'flex',
    fontSize: theme.typography.pxToRem(14),
    height: 11,
    marginLeft: 8,
    padding: 0,
    transition: 'all .5s',
    width: 11,
  },
  headerArrowButtonOpen: {
    transform: 'rotate(90deg)',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  collapse: {
    height: 0,
    marginTop: 0,
    overflow: 'hidden',
    transition: 'height .5s ease, margin .5s ease',
  },
  collapseOpen: {
    marginTop: 14,
  },
});
