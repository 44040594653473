export const minHeightHeader = 56;
export const minHeightSubHeader = 52;
export const minHeightPagination = 56;

const defaultStyles = {
  header: {
    style: {
      minHeight: `${minHeightHeader}px`,
    },
  },
  subHeader: {
    style: {
      minHeight: `${minHeightSubHeader}px`,
    },
  },
  tableWrapper: {
    style: {
      height: '100%',
    },
  },
  pagination: {
    style: {
      justifyContent: 'flex-end',
      minHeight: `${minHeightPagination}px`,
      flexWrap: 'wrap',
    },
  },
  headCells: {
    style: {
      fontSize: 14,
    },
  },
};

export default defaultStyles;
