import { useRef, useEffect } from 'react';

const loadScript = (src, position, id) => {
  if (!position) return;

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

const API_KEY = 'AIzaSyAxqQ7cPNV125lvIrUKdVvpN0GSG_hGgNU';

export const useLoadGoogleApis = () => {
  const loaded = useRef(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`,
          document.querySelector('head'),
          'google-maps',
        );
      }

      loaded.current = true;
    }

    return () => {
      loaded.current = false;
    };
  }, []);
};
