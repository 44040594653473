import { isDevOrStagging } from './env';

export const getServiceUrl = () => {
  if (isDevOrStagging) {
    return 'https://staging.kriptonmarket.com';
  }

  return 'https://kriptonmarket.com';
};

export const getMapUrl = () => {
  if (isDevOrStagging) {
    return 'https://staging-map.kriptonmarket.com';
  }

  return 'https://map.kriptonmarket.com';
};

export const getLandingUrl = () => {
  if (isDevOrStagging) {
    return 'https://staging-info.kriptonmarket.com';
  }

  return 'https://info.kriptonmarket.com';
};
