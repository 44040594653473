import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { makeStyles, Paper, IconButton, InputBase } from '@material-ui/core';
import { Search as SearchIcon, Clear as ClearIcon } from '@material-ui/icons';
import cn from 'classnames';

import styles from './styles';

const useStyles = makeStyles(styles);

const Search = ({ handleSearch }) => {
  const classes = useStyles();

  const {
    handleSubmit,
    submitForm,
    handleReset,
    getFieldProps,
    values,
  } = useFormik({
    initialValues: { searchText: '' },
    onSubmit: ({ searchText }) => handleSearch(searchText),
  });

  const handleClear = useCallback(
    (e) => {
      handleReset(e);
      submitForm();
    },
    [handleReset, submitForm],
  );

  return (
    <Paper component="form" onSubmit={handleSubmit} className={classes.root}>
      <IconButton
        type="submit"
        aria-label="search"
        className={classes.iconButton}
      >
        <SearchIcon fontSize="inherit" color="inherit" />
      </IconButton>
      <InputBase
        inputProps={{ 'aria-label': 'search text' }}
        name="searchText"
        className={classes.input}
        autoComplete="off"
        {...getFieldProps('searchText')}
      />
      <IconButton
        onClick={handleClear}
        aria-label="menu"
        className={cn(classes.iconButton, {
          [classes.notVisible]: !values.searchText,
        })}
      >
        <ClearIcon fontSize="inherit" color="inherit" />
      </IconButton>
    </Paper>
  );
};

Search.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default Search;
