import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, FormControlLabel, Switch } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import ConfigSelectionSwitchLabel from '../ConfigSelectionSwitchLabel';

import { useLazyRequest } from '../../hooks';
import { updateAllowCurrenciesNetworksConfig } from '../../../services/merchantsService';
import { ERRORS_MESSAGE } from '../../../constants/errorMessage';

import styles from './styles';

const useStyles = makeStyles(styles);

const ConfigSelectionSwitch = ({
  selectionId,
  type,
  name,
  icon,
  allow,
  truncateText,
  handleChangeAllow,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [, , , executeAllowed] = useLazyRequest({
    request: updateAllowCurrenciesNetworksConfig,
    withPostSuccess: () => {
      const messageSuccess = ERRORS_MESSAGE.actionRequestSuccess(
        `${name} se ${allow ? 'desactivo' : 'activo'}`,
      );
      enqueueSnackbar(
        ERRORS_MESSAGE.actionRequestSuccess(`${name} se ${messageSuccess}`),
        { variant: 'success' },
      );
      setTimeout(() => {
        handleChangeAllow(selectionId, !allow);
      }, 100);
    },
    withPostFailure: () => {
      enqueueSnackbar(
        ERRORS_MESSAGE.actionRequestError(
          `${allow ? 'desactivar' : 'activar'} ${name}`,
        ),
        { variant: 'error' },
      );
    },
  });

  const handleChange = useCallback(
    (event) => {
      event.preventDefault();
      const currrentAllow = event.target.checked;

      executeAllowed({ type, id: selectionId, allow: currrentAllow });
    },
    [executeAllowed, selectionId, type],
  );

  return (
    <FormControlLabel
      classes={{
        root: classes.formLabelContainer,
      }}
      control={
        <Switch
          checked={allow}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
          size="small"
        />
      }
      label={
        <ConfigSelectionSwitchLabel
          name={name}
          icon={icon}
          allow={allow}
          truncateText={truncateText}
        />
      }
    />
  );
};

ConfigSelectionSwitch.propTypes = {
  selectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  allow: PropTypes.bool,
  truncateText: PropTypes.bool,
  handleChangeAllow: PropTypes.func.isRequired,
};

ConfigSelectionSwitch.defaultProps = {
  icon: null,
  allow: false,
  truncateText: false,
};

export default memo(ConfigSelectionSwitch);
