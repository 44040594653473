export default (theme) => ({
  root: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  containerItem: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateColumns: '100%',
    justifyItems: 'center',
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '50% 50%',
      textAlign: 'left',
    },
  },
  containerItemOrderInverted: {
    [theme.breakpoints.up('md')]: {
      '& > $coverContainer': {
        order: 1,
      },
      '& > $descriptionContainer': {
        order: 0,
        textAlign: 'right',
      },
    },
  },
  coverContainer: {
    alignContent: 'center',
    display: 'grid',
  },
  coverImg: {
    objectFit: 'contain',
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 342,
    },

    [theme.breakpoints.up('lg')]: {
      width: 474,
    },
  },
  descriptionContainer: {
    alignContent: 'center',
    display: 'grid',
    maxWidth: 342,
    width: '100%',

    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
  },
  descriptionTitle: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(21),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(26),
    },
  },
  descriptionInfoText: {
    fontSize: theme.typography.pxToRem(14),
    padding: 0,
  },
});
