export default (theme) => ({
  root: {
    position: 'relative',
  },
  coverMobile: {
    display: 'block',
    marginLeft: 'auto',
    width: '90%',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  coverDesktop: {
    display: 'none',
    height: 650,
    left: 0,
    position: 'absolute',
    top: theme.spacing(5),
    width: '100%',
    zIndex: 0,

    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  container: {
    alignItems: 'flex-end',
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridGap: theme.spacing(2),
    justifyItems: 'center',

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '40% auto',
    },
  },
  infoContainer: {
    display: 'grid',
    gridGap: theme.spacing(3),
    marginBottom: theme.spacing(4),
    maxWidth: 342,
    textAlign: 'center',
    zIndex: 1,

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
      maxWidth: '100%',
      textAlign: 'left',
    },
  },
  infoTitle: {
    color: theme.palette.secondary.light,
    fontSize: theme.typography.pxToRem(21),
    fontWeight: theme.typography.fontWeightBold,

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(26),
    },
  },
  infoDescription: {
    fontSize: theme.typography.pxToRem(14),
  },
  cardListContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridGap: theme.spacing(2),
    zIndex: 1,

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'auto auto',
    },
  },
});
