import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Container } from '@material-ui/core';
import cn from 'classnames';

import styles from './styles';

const useStyles = makeStyles(styles);

const Section = ({ children, className }) => {
  const classes = useStyles();

  return (
    <Container
      component="section"
      maxWidth={false}
      className={cn(classes.root, className)}
    >
      {children}
    </Container>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Section.defaultProps = {
  className: null,
};

export default Section;
