import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Hidden,
} from '@material-ui/core';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Search from '../Search';

import styles from './styles';

const useStyles = makeStyles(styles);

const TitleDashboard = ({
  title,
  search,
  handleSearch,
  gutterBottom,
  gutterTop,
  hiddenTitle,
  hiddenDivider,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <Box
      className={cn(classes.root, {
        [classes.gutterTop]: gutterTop,
        [classes.gutterBottom]: gutterBottom,
      })}
    >
      <Box className={classes.containerTitle}>
        <Hidden
          lgUp={hiddenTitle.lg}
          mdUp={hiddenTitle.md}
          smUp={hiddenTitle.sm}
          xsUp={hiddenTitle.xs}
        >
          <Typography
            component="h1"
            className={cn(classes.typography, classes.dividerGutterRight)}
          >
            {t(`menu.${title}`)}
          </Typography>
        </Hidden>
        <Hidden
          lgUp={hiddenDivider.lg}
          mdUp={hiddenDivider.md}
          smUp={hiddenDivider.sm}
          xsUp={hiddenDivider.xs}
        >
          <Divider
            variant="fullWidth"
            className={cn(classes.divider, {
              [classes.dividerGutterRight]: search,
            })}
          />
        </Hidden>
      </Box>
      {search && <Search handleSearch={handleSearch} />}
    </Box>
  );
};

TitleDashboard.propTypes = {
  title: PropTypes.string.isRequired,
  search: PropTypes.bool,
  handleSearch: PropTypes.func,
  gutterBottom: PropTypes.bool,
  gutterTop: PropTypes.bool,
  hiddenTitle: PropTypes.shape({
    lg: PropTypes.bool,
    md: PropTypes.bool,
    sm: PropTypes.bool,
    xs: PropTypes.bool,
  }),
  hiddenDivider: PropTypes.shape({
    lg: PropTypes.bool,
    md: PropTypes.bool,
    sm: PropTypes.bool,
    xs: PropTypes.bool,
  }),
};

TitleDashboard.defaultProps = {
  search: true,
  handleSearch: () => {},
  gutterBottom: false,
  gutterTop: false,
  hiddenTitle: {},
  hiddenDivider: {},
};

export default TitleDashboard;
