import React from 'react';
import { toCamel } from './formatter';
import { personalDataForm, documentPairs } from '../constants/formConstants';

// eslint-disable-next-line react/prop-types
const LinkTo = ({ url, label }) => (
  <a href={url} target="_blank" rel="noreferrer">
    {label || url}
  </a>
);

const elementExist = (element) =>
  personalDataForm.personalData.some(({ name }) => name === element);

const buildDocumentsFields = (documents = []) =>
  documents.forEach((doc) => {
    const { document_type: documentType, url } = doc;
    const name = toCamel(documentType);
    if (!elementExist(documentPairs[name])) {
      personalDataForm.personalData.push({
        name: documentPairs[name],
        selector: toCamel(documentType),
        format: () => <LinkTo url={url} />,
      });
    }
  });

const getBankData = (data = []) =>
  data.map((item) => ({
    name: item.label,
    selector: (row, rowIndex) => row[rowIndex].value,
  }));

export const getFormObject = (documents = [], information = {}) => {
  buildDocumentsFields(documents);
  return {
    ...personalDataForm,
    bankData: getBankData(information?.bankData?.jsonData),
  };
};

export const buildFiscalData = (data = {}) => {
  const fiscalData = {};
  if (data) {
    Object.keys(data).forEach((element) => {
      fiscalData[toCamel(element)] = data[element];
    });
  }

  return fiscalData;
};

export const buildCurrenciesSelect = (data = []) =>
  data.map((item) => {
    const { currency_id: currencyId, name } = item;

    return {
      name: `${currencyId}-${name}`,
    };
  });
