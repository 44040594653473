import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Card, CardContent } from '@material-ui/core';
import classNames from 'classnames';

import CircularProgress from '../CircularProgress';

import styles from './styles';

const useStyles = makeStyles(styles);

const CardWithProgressLoading = ({
  loading,
  header,
  fullHeight,
  children,
  disableShrinkProgress,
  className,
}) => {
  const classes = useStyles();

  return (
    <Card
      className={classNames(className, { [classes.fullHeight]: fullHeight })}
    >
      <CardContent
        className={classNames(classes.cardContent, {
          [classes.fullHeight]: fullHeight,
        })}
      >
        {header && header}
        {loading ? (
          <CircularProgress disableShrink={disableShrinkProgress} />
        ) : (
          children
        )}
      </CardContent>
    </Card>
  );
};

CardWithProgressLoading.propTypes = {
  loading: PropTypes.bool,
  header: PropTypes.node,
  fullHeight: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disableShrinkProgress: PropTypes.bool,
  className: PropTypes.string,
};

CardWithProgressLoading.defaultProps = {
  loading: true,
  header: null,
  fullHeight: true,
  disableShrinkProgress: false,
  className: null,
};

export default CardWithProgressLoading;
