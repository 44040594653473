import React from 'react';
import { makeStyles, Typography, Box, Grid } from '@material-ui/core';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

import Section from '../Section';
import { PARTNERS_LIST } from './data';

import styles from './styles';

const useStyles = makeStyles(styles);

const PartnersGallery = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const config = {
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Section>
      <Typography
        component="h2"
        className={classes.title}
        paragraph
        align="center"
      >
        {t('home.trustUs')}
      </Typography>
      <Grid container alignContent="center" justify="center">
        <Grid item md={8} xs={12}>
          <Slider {...config}>
            {PARTNERS_LIST.map(({ id, imageUrl, alt }) => (
              <Box key={id} className={classes.slickItemContainer}>
                <img
                  src={imageUrl}
                  alt={alt}
                  className={classes.slickItemImg}
                />
              </Box>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </Section>
  );
};

export default PartnersGallery;
