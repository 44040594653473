import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useRLoginContext } from '../../contexts/RLoginContext';
import { useRouteMatch } from '../../hooks';
import { MENU_OPTIONS_2 } from '../../../constants/menuOptions';
import { AVAILABLE_COUNTRIES } from '../../../constants/availablesCountries';
import { SUPPORTED_LANGUAGES } from '../../../constants/languaje';
import { PATHS } from '../../../constants/paths';
import { setLang, getLang } from '../../../utils/languaje';
import { actions as authActions } from '../../modules/Auth/_redux/authRedux';
import LinkRouter from '../LinkRouter';
import RLoginButton from '../RLoginButton';
import CollapseMenuSelector from '../CollapseMenuSelector';
import { toAbsoluteUrl } from '../../../utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const MenuMobile = () => {
  const { t } = useTranslation('common');
  const { matchPath } = useRouteMatch();
  const classes = useStyles();

  const dispatch = useDispatch();
  const {
    authToken,
    currentCountry,
    currentSubOptionMenuSelected,
  } = useSelector((state) => state.auth);
  const { walletConnected, handleDisconnectWallet } = useRLoginContext();

  const [openMenu, setOpenMenu] = useState(false);
  const toggleOpen = useCallback(
    () => setOpenMenu((prevOpen) => !prevOpen),
    [],
  );

  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflow = openMenu ? 'hidden' : 'auto';
    }, 300);
  }, [openMenu]);

  const resetCurrentSubOptionMenuSelected = useCallback(() => {
    dispatch(authActions.resetCurrentSubOptionMenuSelected());
  }, [dispatch]);

  const [expandedPanelSelectors, setExpandedPanelSelectors] = useState(null);

  const toggleExpandedPanelSubOptions = useCallback(
    (panel) => {
      const subObtionSelected =
        currentSubOptionMenuSelected === panel ? null : panel;

      dispatch(authActions.setCurrentSubOptionMenuSelected(subObtionSelected));

      setExpandedPanelSelectors(null);
    },
    [currentSubOptionMenuSelected, dispatch],
  );

  const toggleExpandedPanelSelectors = useCallback(
    (panel) => {
      setExpandedPanelSelectors((prevPanel) =>
        prevPanel === panel ? null : panel,
      );

      resetCurrentSubOptionMenuSelected();
    },
    [resetCurrentSubOptionMenuSelected],
  );

  const resetExpanded = useCallback(() => {
    resetCurrentSubOptionMenuSelected();
    setExpandedPanelSelectors();
  }, [resetCurrentSubOptionMenuSelected]);

  const [currentLanguage, setCurrentLanguage] = useState(getLang());

  const handleChangeLanguage = useCallback(
    async (newLang) => {
      await setLang(newLang);
      setCurrentLanguage(newLang);
      setTimeout(() => {
        setExpandedPanelSelectors(null);
        toggleOpen();
      }, 500);
    },
    [toggleOpen],
  );

  const handleChangeCountry = useCallback(
    (country) => {
      dispatch(authActions.setCurrentCountry(country));
      setTimeout(() => {
        setExpandedPanelSelectors(null);
        toggleOpen();
      }, 500);
    },
    [dispatch, toggleOpen],
  );

  const handleLogout = useCallback(() => {
    if (walletConnected) handleDisconnectWallet();
    dispatch(authActions.logout());
    toggleOpen();
  }, [walletConnected, handleDisconnectWallet, toggleOpen, dispatch]);

  return (
    <div className={classes.menuContainer}>
      <button
        type="button"
        className={cn(classes.menuButton, {
          // [classes.openMenu]: openMenu,
        })}
        onClick={toggleOpen}
      >
        <div className={classes.menuButtonLines} />
      </button>

      {openMenu && (
        <nav className={cn(classes.serviceMenu)}>
          <div className={classes.serviceMenuItems}>
            {authToken &&
              MENU_OPTIONS_2.map(({ key, image, path, subOptions }) => {
                if (subOptions && subOptions.length > 0) {
                  return (
                    <CollapseMenuSelector
                      open={currentSubOptionMenuSelected === key}
                      handleOpen={() => toggleExpandedPanelSubOptions(key)}
                      title={t(`menu.${key}`)}
                      Icon={<img src={image} alt={t(`menu.${key}`)} />}
                      key={`menu-link-element-${key}`}
                      classNameContent={classes.collapseContentContainer}
                    >
                      {subOptions.map((option) => (
                        <LinkRouter
                          key={`${key}-collapse-element-${option.key}`}
                          to={option.path}
                          underline="none"
                          className={cn(
                            classes.collapseItemContainer,
                            classes.menuElementContentSubOptions,
                            {
                              [classes.collapseItemSelected]: matchPath(
                                option.path,
                                option.isExact ?? false,
                              ),
                            },
                          )}
                        >
                          <div className={classes.menuElementText}>
                            {t(`menu.${option.key}`)}
                          </div>
                        </LinkRouter>
                      ))}
                    </CollapseMenuSelector>
                  );
                }

                return (
                  <LinkRouter
                    key={`menu-link-element-${key}`}
                    to={path}
                    className={classes.menuElementContent}
                    onClick={resetCurrentSubOptionMenuSelected}
                    underline="none"
                  >
                    <img
                      src={image}
                      alt={t(`menu.${key}`)}
                      className={classes.menuElementIcon}
                    />

                    <div className={classes.menuElementText}>
                      {t(`menu.${key}`)}
                    </div>
                  </LinkRouter>
                );
              })}

            {authToken && (
              <div className={classes.menuElementContent}>
                <RLoginButton
                  color="default"
                  onClick={resetExpanded}
                  onConnect={toggleOpen}
                  className={classes.rloginContainer}
                />
              </div>
            )}

            <div className={classes.collpasesContainer}>
              <CollapseMenuSelector
                open={expandedPanelSelectors === 'country'}
                handleOpen={() => toggleExpandedPanelSelectors('country')}
                title={t('profile.country')}
                Icon={
                  <img
                    src={toAbsoluteUrl('/assets/icons/world-white.svg')}
                    alt="Country"
                  />
                }
                classNameContent={cn(
                  classes.collapseContentContainer,
                  classes.countryCollapseContetContainer,
                )}
              >
                {AVAILABLE_COUNTRIES.map((countryItem) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                  <div
                    className={cn(classes.collapseItemContainer, {
                      [classes.collapseItemSelected]:
                        currentCountry === countryItem.value,
                    })}
                    key={`country-collapse-element-${countryItem.value}`}
                    onClick={() => handleChangeCountry(countryItem.value)}
                  >
                    <img
                      src={countryItem.urlImg}
                      alt={countryItem.label}
                      className={classes.collapseItemIcon}
                    />

                    <div className={classes.collapseItemText}>
                      {countryItem.label}
                    </div>
                  </div>
                ))}
              </CollapseMenuSelector>

              <CollapseMenuSelector
                open={expandedPanelSelectors === 'languaje'}
                handleOpen={() => toggleExpandedPanelSelectors('languaje')}
                title={t('general.languaje')}
                Icon={
                  <img
                    src={toAbsoluteUrl('/assets/icons/languaje-white.svg')}
                    alt="Languaje"
                  />
                }
                classNameContent={cn(
                  classes.collapseContentContainer,
                  classes.langCollapseContetContainer,
                )}
              >
                {SUPPORTED_LANGUAGES.map((langItem) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                  <div
                    className={cn(classes.collapseItemContainer, {
                      [classes.collapseItemSelected]:
                        currentLanguage === langItem.id,
                    })}
                    key={`language-collapse-element-${langItem.id}`}
                    onClick={() => handleChangeLanguage(langItem.id)}
                  >
                    <img
                      src={langItem.urlFlagImg}
                      alt={t(`general.${langItem.id}`)}
                      className={classes.collapseItemIcon}
                    />

                    <div className={classes.collapseItemText}>
                      {t(`general.${langItem.id}`)}
                    </div>
                  </div>
                ))}
              </CollapseMenuSelector>
            </div>
          </div>

          <div className={classes.authActionsContainer}>
            {!authToken && (
              <>
                <Button
                  color="default"
                  variant="text"
                  component={Link}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  to={PATHS.login.path}
                  className={classes.authButton}
                  onClick={toggleOpen}
                >
                  {t(`header.login`)}
                </Button>

                <Button
                  color="secondary"
                  variant="contained"
                  component={Link}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  to={PATHS.registration.path}
                  className={classes.authButton}
                  onClick={toggleOpen}
                >
                  {t(`header.createAccount`)}
                </Button>
              </>
            )}

            {authToken && (
              <div className={classes.authElementContent}>
                <img
                  src={toAbsoluteUrl('/assets/icons/exit-white.svg')}
                  alt="Logout"
                  className={classes.authElementIcon}
                />

                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div className={classes.authElementText} onClick={handleLogout}>
                  {t(`header.logOut`)}
                </div>
              </div>
            )}
          </div>
        </nav>
      )}
    </div>
  );
};

export default MenuMobile;
